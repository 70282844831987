import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import IconTypes from '../../../public/static/graphics/processed/enums';
import Button from '../../common/Button/Button';
import { ButtonVariant } from '../../common/Button/enums';
import { IconColor, IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import useTranslation from '../../useTranslation';
import localStyles from './PasswordField.module.pcss';

interface Props {
  /** @callback called when button is clicked */
  onClick: () => void;

  /** Whether the password is currently visible */
  showPassword: boolean;
}

/**
 * Toggle button to show or hide password, if the password is viewable..
 * This is a controlled component and should not maintain its own state
 * @author Stephen Mclaughry, Manish Shrestha, Rosalyn Rowe
 */
const ShowHidePasswordToggle: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  showPassword
}) => {
  const cx = useClassNameMapper(localStyles);

  const { formatMessage, loading } = useTranslation(SharedComponent.PASSWORD_FIELD);

  if (loading) {
    return null;
  }

  return (
    <Button
      variant={ButtonVariant.LINK}
      className={cx('lia-show-password lia-g-link-btn')}
      onClick={() => onClick()}
      aria-label={formatMessage('showPasswordButton')}
      type="button"
      aria-pressed={showPassword}
    >
      <Icon
        icon={showPassword ? IconTypes.ViewsIcon : IconTypes.ViewsHideIcon}
        size={IconSize.PX_16}
        color={IconColor.GRAY_600}
      />
    </Button>
  );
};

export default ShowHidePasswordToggle;
