import type { QueryResult } from '@apollo/client';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import ConversationStyleBehaviorHelper from '../../../../helpers/boards/ConversationStyleBehaviorHelper';
import type {
  ContextMessageByReplyQuery,
  ContextMessageByReplyQueryVariables,
  ContextMessageQuery,
  ContextMessageQueryVariables
} from '../../../../types/graphql-types';
import { useIsPreviewMode } from '../../../messages/useCurrentOrPreviewMessage';
import useContextObjectFromUrl, {
  UrlObject,
  useContextObjectRefFromUrl
} from '../../useContextObjectFromUrl';

/**
 * Returns a context message for the AppContextProvider.
 * If a reply id is provided in the URL, the contextMessageByReplyQuery is used, which uses a nested topic message
 *
 * // TODO just use the regular context message query in AppContextProvider and add include/skip as needed for reply pages
 *
 * @param module
 */
export default function useAppContextProviderMessage(
  module: NodeModule
): Pick<
  QueryResult<ContextMessageQuery, ContextMessageQueryVariables>,
  'data' | 'loading' | 'error'
> {
  const { router } = useEndUserRoutes();
  const currentPageName: EndUserPages = router.getCurrentPageName();
  const validReplyPageNames: Array<EndUserPages> =
    ConversationStyleBehaviorHelper.getPropertyValuesForAllConversationTypes('messageReplyPage');
  const isReplyPage: boolean = !!validReplyPageNames.includes(currentPageName);
  const { id: replyMessageId } = useContextObjectRefFromUrl(UrlObject.REPLY);

  const isPreviewMode: boolean = useIsPreviewMode();

  // the message query for all pages except reply pages
  const contextMessageQueryResult = useContextObjectFromUrl<
    ContextMessageQuery,
    ContextMessageQueryVariables
  >(module, UrlObject.MESSAGE, {
    fetchPolicy: isPreviewMode ? 'cache-and-network' : 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: isReplyPage
  });

  // the message query used specifically for reply pages
  const contextMessageByReplyQueryResult = useContextObjectFromUrl<
    ContextMessageByReplyQuery,
    ContextMessageByReplyQueryVariables
  >(module, UrlObject.REPLY, {
    variables: {
      id: replyMessageId,
      useTextBody: true,
      usePreviewMedia: true,
      useHoverCard: true,
      useRevision: true,
      useRepliesCount: true,
      useTimeToRead: true,
      useMessageStatus: true
    },
    fetchPolicy: isPreviewMode ? 'cache-and-network' : 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: !isReplyPage
  });
  const { data: contextMessageByReplyQueryData } = contextMessageByReplyQueryResult;

  if (isReplyPage) {
    const contextMessage = contextMessageByReplyQueryData?.message?.conversation?.topic;
    return {
      ...contextMessageByReplyQueryResult,
      ...(!!contextMessage && {
        data: { ...contextMessageByReplyQueryResult.data, message: contextMessage }
      })
    };
  }

  return contextMessageQueryResult;
}
