import type { PostMessageType, Scalars } from '@aurora/shared-generated/types/graphql-schema-types';
import type { RouteInfo } from '@aurora/shared-types/community';
import { DEFAULT_LANGUAGE } from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import type { RefObject } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

export enum GlobalStateType {
  MESSAGE_EDITING_STATE = 'messageEditingState',
  BREADCRUMB_PORTAL = 'breadcrumbPortal',
  TEXT_SELECTED_ITEM = 'textSelectedItem',
  SHOW_QUOTE_FOR_SELECTION = 'showQuoteForSelection',
  MESSAGE_DELETING_STATE = 'messageDeletingState',
  SHOW_USER_EMAIL_VERIFICATION_TOAST = 'showUserEmailVerificationToast',
  SHOW_LANGUAGE_PICKER_TOAST = 'showLanguagePickerToast',
  AI_SEARCH_CHAT_UNREAD_INFO = 'aiSearchChatUnreadInfo',
  PREVIOUS_ROUTE_INFO = 'previousRouteInfo'
}

export enum EditorLocation {
  INLINE = 'inline',
  CTA = 'cta'
}

export interface MessageEditingState {
  /**
   * The message being replied to or edited.
   */
  messageId?: Scalars['ID']['output'];
  /**
   * The post message type.
   */
  postMessageType: PostMessageType;
  /**
   * The editor location.
   */
  editorLocation: EditorLocation;
}

export interface MessageDeletingState {
  /**
   * The id of message that being deleted.
   */
  messageId?: Scalars['ID']['output'];
  /**
   * The Parent id of message that is being deleted.
   */
  parentId?: Scalars['ID']['output'];
}

export interface LanguagePickerToast {
  /**
   * The current language stored in the session.
   */
  language: string;
  /**
   * The current language stored in the session.
   */
  oldLanguage: string;
  /**
   * Whether to show the toast.
   */
  showToast: boolean;
}

export interface TextSelectedItem {
  /**
   * The node where the selection begins.
   */
  anchorElement?: HTMLElement;
  /**
   * The node where the selection ends.
   */
  focusElement?: HTMLElement;
  /**
   * The callback to be triggered on selection.
   */
  callback?: (content: string | boolean) => void;
}

interface State {
  /**
   * Used by the message editors to ensure only one editor is opened at once and
   * provide details about the current editor.
   */
  messageEditingState: MessageEditingState;
  /**
   * Element ref to Breadcrumb Widget that can be used with `ReactDOM.createPortal`
   * to place a nested child component into the Breadcrumb bar. This is useful for
   * deeply nested components that want to have a page or context specific Breadcrumb
   * action component.
   */
  breadcrumbPortal: RefObject<HTMLElement>;
  /**
   * Used by the RTE to check whether the selection falls under message body section
   * when there is a text selected on the page.
   */
  textSelectedItem: TextSelectedItem;
  /**
   * Used by the RTE to display Quote Button by checking whether the selection falls
   * under message body section, when there is a text selected on the page.
   */
  showQuoteForSelection: TextSelectedItem;
  /**
   * Used by the threaded Reply Lists to ensure the list of messages/replies
   * is updated with the messages that got deleted.
   */
  messageDeletingState: MessageDeletingState;
  /**
   * Show User Email verification toast - used to check whether unverified user was shown Email Verification toast
   * default value is kept as false
   */
  showUserEmailVerificationToast: boolean;
  /**
   * Show User Email verification toast - used to check whether unverified user was shown Email Verification toast
   * default value is kept as false
   */
  showLanguagePickerToast: LanguagePickerToast;
  /**
   * Used by the AI Search Chat to keep track of the unread count.
   */
  aiSearchChatUnreadInfo: {
    count: number;
    timestamp: number | null;
  };
  /**
   * Previous route info
   */
  previousRouteInfo: RouteInfo | null;
}

const { useGlobalState } = createGlobalState<State>({
  messageEditingState: null,
  breadcrumbPortal: null,
  textSelectedItem: null,
  showQuoteForSelection: null,
  messageDeletingState: null,
  showUserEmailVerificationToast: false,
  showLanguagePickerToast: {
    oldLanguage: DEFAULT_LANGUAGE,
    language: DEFAULT_LANGUAGE,
    showToast: false
  },
  aiSearchChatUnreadInfo: {
    count: 0,
    timestamp: null
  },
  previousRouteInfo: null
});

export default useGlobalState;
