import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class BlackItalicStyle implements FontStyleDefinition {
  name = FontStyleName.BLACKITALIC;

  style = FontStyle.Italic;

  weight = 900;
}
