import type { FormatMessage } from '@aurora/external-types/utils/I18n/i18n';
import { canUseDOM } from 'exenv';
import React, { useContext, useId, useEffect } from 'react';
import type { AddUpdateEmailToastProps } from './EmailVerificationContext';
import EmailVerificationContext from './EmailVerificationContext';
import { EmailVerificationToastType } from '../../common/EmailVerification/types';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import useTranslation from '../../useTranslation';

const UpdateEmailBanner = dynamic(
  () => import('../../common/EmailVerification/UpdateEmailBanner'),
  {
    ssr: false
  }
);

const ResendEmailBanner = dynamic(
  () => import('../../common/EmailVerification/ResendEmailBanner'),
  {
    ssr: false
  }
);

interface Props {
  /**
   * Type of toast to be shown
   */
  variant: EmailVerificationToastType;
  /**
   * optional callback when the toast is removed
   */
  onRemoveToast?: () => void;
}

/**
 * Display the toast alert for email verification.
 * This is not exported in order to enforce usage of the context.
 *
 * @param {FormatMessage} formatMessage - The function to translate messages
 * @returns {Function} - The function to add the email verification toast
 * @author Rishabh Modi, Rosalyn Rowe
 */
function useEmailVerification(formatMessage: FormatMessage): (props: Props) => void {
  const uid = useId();
  const {
    authUser: { email }
  } = useContext(AppContext);
  const { addToast } = useToasts();

  /**
   * Adds an email verification toast to the DOM
   */
  function addEmailVerificationToast({ variant, onRemoveToast = () => {} }: Props): void {
    const toastProps: ToastProps = {
      toastVariant:
        variant === EmailVerificationToastType.UPDATE_EMAIL
          ? ToastVariant.BANNER
          : ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.WARNING,
      title: formatMessage('email.verification.title'),
      message:
        variant === EmailVerificationToastType.UPDATE_EMAIL
          ? () => <UpdateEmailBanner email={email} />
          : () => <ResendEmailBanner email={email} />,
      onClose: () => onRemoveToast(),
      id: uid
    };
    addToast(toastProps);
  }

  return addEmailVerificationToast;
}

const EmailVerificationContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { isAnonymous, confirmEmailStatus, registrationStatus } = useRegistrationStatus();
  /** This is global so that the value is persisted across mounts. */
  const [showUserEmailVerificationToast, setShowUserEmailVerificationToast] =
    useGlobalState<GlobalStateType>(GlobalStateType.SHOW_USER_EMAIL_VERIFICATION_TOAST);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.EMAIL_VERIFICATION
  );

  const addEmailToast = useEmailVerification(formatMessage);
  const addUpdateEmailToast = (props: AddUpdateEmailToastProps) =>
    addEmailToast({ variant: EmailVerificationToastType.UPDATE_EMAIL, ...props });

  /** Wait for text to finish loading and render the toast if needed */
  useEffect(() => {
    /**
     * We want to render the toast if all of the following conditions are true:
     * 1. We're on the client
     * 2. The user is logged in
     * 3. The user has not verified their email
     * 4. The user was not logged in on the previous render/mount.
     * 5. The text is not loading
     */
    const shouldAddToast =
      canUseDOM &&
      !isAnonymous &&
      !confirmEmailStatus &&
      showUserEmailVerificationToast &&
      !textLoading;

    if (shouldAddToast) {
      addEmailToast({
        variant: EmailVerificationToastType.RESEND_EMAIL,
        onRemoveToast: () => {
          // When the X button is clicked, we'll disable the email toast until next time the user logs out.
          setShowUserEmailVerificationToast(false);
        }
      });
    }

    /** When the page is navigated away from, if we were showing the toast, disable it until next time we log out. */
    return () => {
      if (shouldAddToast) {
        setShowUserEmailVerificationToast(false);
      }
    };
  }, [
    textLoading,
    isAnonymous,
    confirmEmailStatus,
    showUserEmailVerificationToast,
    addEmailToast,
    setShowUserEmailVerificationToast
  ]);

  useEffect(() => {
    // Here, we enable the email toast to (possibly) render on next mount if the user is anonymous.
    if (registrationStatus === RegistrationStatus.Anonymous) {
      setShowUserEmailVerificationToast(true);
    }
  }, [registrationStatus, setShowUserEmailVerificationToast]);

  return (
    <EmailVerificationContext.Provider
      value={{
        addUpdateEmailToast
      }}
    >
      {children}
    </EmailVerificationContext.Provider>
  );
};

export default EmailVerificationContextProvider;
