import { useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { useDebounce } from 'react-use';
import type { DebounceProps } from '../types';

/**
 * Handles the logic to submit on change of field. Will debounce or immediate submits based on debounce props. Returns
 * an onChange callback that must be called with the value that changed for the field.
 * @param name name of the field
 * @param debounceProps submit on change spec for the form
 * @param submitHandler submit handler to be called when change happens.
 * @author Manish Shrestha
 */
export function useSubmitOnChange<NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  name: NameT,
  debounceProps: DebounceProps<DataT>,
  submitHandler: () => void
): (value: DataT[NameT]) => void {
  const [fieldValue, setFieldValue] = useState<DataT[NameT]>();
  const { watchFields, waitTime } = debounceProps || {};
  const isDebounceSubmit = watchFields === 'all' || watchFields?.includes(name);
  const immediateSubmit = debounceProps && !isDebounceSubmit;

  useDebounce(
    () => {
      if (isDebounceSubmit && fieldValue !== undefined) {
        submitHandler();
        // eslint-disable-next-line unicorn/no-useless-undefined
        setFieldValue(undefined);
      }
    },
    waitTime || 500,
    [fieldValue]
  );

  function onChange(value: DataT[NameT]): void {
    if (immediateSubmit) {
      submitHandler();
    } else if (isDebounceSubmit) {
      setFieldValue(value);
    }
  }
  return onChange;
}
