import { v4 as uuid } from 'uuid';
import type { MutationTuple, ReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client';
import type {
  EndCurrentParentFrameMutation,
  EndCurrentParentFrameMutationVariables
} from '@aurora/shared-generated/types/graphql-types';
import useMutationWithTracing from '../../useMutationWithTracing';
import frameEndMutation from './AnalyticsFrameEnd.mutation.graphql';

/**
 * `useFrameEnd` calls the FrameEnd Event for Parent frame UUID.
 *
 * @author Madhusudhana RK
 */

export const getParentFrameId: ReactiveVar<string> = makeVar(uuid());

export default function useFrameEnd(): MutationTuple<
  EndCurrentParentFrameMutation,
  EndCurrentParentFrameMutationVariables
> {
  const parentFrameId = getParentFrameId();
  return useMutationWithTracing<
    EndCurrentParentFrameMutation,
    EndCurrentParentFrameMutationVariables
  >(module, frameEndMutation, {
    variables: { uuid: parentFrameId },
    onCompleted: () => {
      getParentFrameId(uuid());
    }
  });
}
