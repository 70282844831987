/**
 * This is a generated file containing an enum for the Icons.
 * This is intended to be imported into components and used to refer to icons.
 * If you wish to update this file with any new icons, please run the following command in the shared/client workspace:
 *
 * npm run process:svgs
 *
 * See https://confluence.dev.lithium.com/display/KPM/Project+Aurora+-+Icon+Guide for a full guide
 *
 * @author Rosalyn Rowe
 */
enum IconTypes {
  AccessibilityIcon = 'accessibility',
  AddIcon = 'add',
  AddOutlineIcon = 'add-outline',
  AddSolidIcon = 'add-solid',
  AddSolidOutlineIcon = 'add-solid-outline',
  AiIcon = 'ai',
  AnalyticsDashboardsIcon = 'analytics-dashboards',
  AnalyticsReportsIcon = 'analytics-reports',
  AnalyticsThirdpartyIcon = 'analytics-thirdparty',
  AnchorIcon = 'anchor',
  AnnouncementIcon = 'announcement',
  ArchiveIcon = 'archive',
  ArchiveOutlineIcon = 'archive-outline',
  ArchivedIcon = 'archived',
  ArrowDownIcon = 'arrow-down',
  ArrowDownOutlineIcon = 'arrow-down-outline',
  ArrowLeftIcon = 'arrow-left',
  ArrowLeftOutlineIcon = 'arrow-left-outline',
  ArrowRightIcon = 'arrow-right',
  ArrowRightOutlineIcon = 'arrow-right-outline',
  ArrowUpIcon = 'arrow-up',
  ArrowUpOutlineIcon = 'arrow-up-outline',
  ArrowUpRightIcon = 'arrow-up-right',
  AttachmentIcon = 'attachment',
  AttachmentOutlineIcon = 'attachment-outline',
  AutosaveIcon = 'autosave',
  AutosaveOutlineIcon = 'autosave-outline',
  BadgeUnknownIcon = 'badge-unknown',
  BadgesIcon = 'badges',
  BasicsWidthofbrowserIcon = 'basics-widthofbrowser',
  BasicsWidthofpageIcon = 'basics-widthofpage',
  BellIcon = 'bell',
  BinocularsIcon = 'binoculars',
  BrowserBrowserWidthIcon = 'browser-browser-width',
  BrowserFluidWidthIcon = 'browser-fluid-width',
  BrowserPageContentWidthIcon = 'browser-page-content-width',
  ButtonHeroIcon = 'icon-button-hero',
  ButtonLargeIcon = 'icon-button-large',
  ButtonStandardIcon = 'icon-button-standard',
  ButtonsFilledIcon = 'buttons-filled',
  ButtonsOutlinedIcon = 'buttons-outlined',
  ButtonsPillIcon = 'buttons-pill',
  ButtonsSquareIcon = 'buttons-square',
  CategoryCommonIcon = 'category-common',
  CategoryContentIcon = 'category-content',
  CategoryCustomIcon = 'category-custom',
  CategoryPeopleIcon = 'category-people',
  CategoryPlacesIcon = 'category-places',
  CategoryTextHtmlIcon = 'category-text-html',
  ChapterIcon = 'chapter',
  CheckmarkAdminIcon = 'checkmark-admin',
  CheckmarkIcon = 'checkmark',
  CheckmarkInputIcon = 'checkmark-input',
  CheckmarkOutlineIcon = 'checkmark-outline',
  CheckmarkSolidIcon = 'checkmark-solid',
  CheckmarkSolidOutlineIcon = 'checkmark-solid-outline',
  ChevronDownIcon = 'chevron-down',
  ChevronDownOutlineIcon = 'chevron-down-outline',
  ChevronLeftIcon = 'chevron-left',
  ChevronLeftOutlineIcon = 'chevron-left-outline',
  ChevronRightIcon = 'chevron-right',
  ChevronRightOutlineIcon = 'chevron-right-outline',
  ChevronUpIcon = 'chevron-up',
  ChevronUpOutlineIcon = 'chevron-up-outline',
  CloseIcon = 'close',
  CloseOutlineIcon = 'close-outline',
  CommentIcon = 'comment',
  CommentOutlineIcon = 'comment-outline',
  CommunityIcon = 'community',
  ContentBlogIcon = 'content-blog',
  ContentCategoryIcon = 'content-category',
  ContentContestIcon = 'content-contest',
  ContentDiscussionIcon = 'content-discussion',
  ContentDiscussionQuestionIcon = 'content-discussion-question',
  ContentDiscussionSolvedIcon = 'content-discussion-solved',
  ContentGroupIcon = 'content-group',
  ContentIdeaIcon = 'content-idea',
  ContentKnowledgeIcon = 'content-knowledge',
  ContentLayoutCardIcon = 'content-layout-card',
  ContentLayoutDetailedListIcon = 'content-layout-detailed-list',
  ContentLayoutListIcon = 'content-layout-list',
  ContentOccasionIcon = 'content-occasion',
  ContentProductIcon = 'content-product',
  ContentTagIcon = 'content-tag',
  CoreRoleIcon = 'core-role',
  CreateIcon = 'create',
  CreateOutlineIcon = 'create-outline',
  DangerAdminIcon = 'danger-admin',
  DangerIcon = 'danger',
  DangerOutlineIcon = 'danger-outline',
  DesignerAssetsIcon = 'icon-designer-assets',
  DesignerChangelogIcon = 'icon-designer-changelog',
  DesignerEmailsIcon = 'icon-designer-emails',
  DesignerPagesIcon = 'icon-designer-pages',
  DesignerTextkeysIcon = 'icon-designer-textkeys',
  DesignerThemesIcon = 'icon-designer-themes',
  DeviceDesktopIcon = 'device-desktop',
  DevicePhoneIcon = 'device-phone',
  DeviceTabletIcon = 'device-tablet',
  DownloadIcon = 'download',
  DownloadOutlineIcon = 'download-outline',
  DragIcon = 'drag',
  DragLockIcon = 'drag-lock',
  DragMoveIcon = 'drag-move',
  EditIcon = 'edit',
  EditOutlineIcon = 'edit-outline',
  EditRedoIcon = 'edit-redo',
  EditUndoIcon = 'edit-undo',
  ElementsBordersDarkIcon = 'elements-borders-dark',
  ElementsBordersLightIcon = 'elements-borders-light',
  ElementsBordersNoneIcon = 'elements-borders-none',
  ElementsInputsPillIcon = 'elements-inputs-pill',
  ElementsInputsRoundedIcon = 'elements-inputs-rounded',
  ElementsInputsSquareIcon = 'elements-inputs-square',
  ElementsModalsDarkIcon = 'elements-modals-dark',
  ElementsModalsLightIcon = 'elements-modals-light',
  ElementsRadiusLargeIcon = 'elements-radius-large',
  ElementsRadiusSmallIcon = 'elements-radius-small',
  ElementsRadiusSquareIcon = 'elements-radius-square',
  ElementsShadowsDarkIcon = 'elements-shadows-dark',
  ElementsShadowsLightIcon = 'elements-shadows-light',
  ElementsShadowsNoneIcon = 'elements-shadows-none',
  EllipsisIcon = 'ellipsis',
  EllipsisOutlineIcon = 'ellipsis-outline',
  EmojiActivityIcon = 'emoji-activity',
  EmojiAnimalsAndNatureIcon = 'emoji-animals-and-nature',
  EmojiCustomIcon = 'emoji-custom',
  EmojiFlagsIcon = 'emoji-flags',
  EmojiFoodAndDrinkIcon = 'emoji-food-and-drink',
  EmojiObjectsIcon = 'emoji-objects',
  EmojiSmileysAndPeopleIcon = 'emoji-smileys-and-people',
  EmojiSymbolsIcon = 'emoji-symbols',
  EmojiTravelAndPlacesIcon = 'emoji-travel-and-places',
  EnvelopeIcon = 'envelope',
  ExternalLinkIcon = 'external-link',
  ExternalLinkOutlineIcon = 'external-link-outline',
  FaceHappyIcon = 'face-happy',
  FaceHappyOutlineIcon = 'face-happy-outline',
  FaceSadIcon = 'face-sad',
  FaceSadOutlineIcon = 'face-sad-outline',
  FeaturesFeaturesIcon = 'features-features',
  FeaturesInboxIcon = 'icon-features-inbox',
  FeaturesLocalizedCategoriesIcon = 'features-localized-categories',
  FeaturesModerationIcon = 'icon-features-moderation',
  FeaturesProductsIcon = 'features-products',
  FeaturesSalesforceIcon = 'features-salesforce',
  FeaturesSyndicationIcon = 'features-syndication',
  FilterIcon = 'filter',
  FlagAdminIcon = 'flag-admin',
  FlagIcon = 'flag',
  FlagOutlineIcon = 'flag-outline',
  FollowIcon = 'follow',
  FollowOutlineIcon = 'follow-outline',
  FollowingIcon = 'following',
  FollowingOutlineIcon = 'following-outline',
  FollowsEmptyIcon = 'follows-empty',
  GearIcon = 'gear',
  GearOutlineIcon = 'gear-outline',
  GuideIcon = 'guide',
  HamburgerMenuIcon = 'hamburger-menu',
  HeaderLargeIcon = 'header-large',
  HeaderMediumHomeIcon = 'header-medium-home',
  HeaderMediumPlaceIcon = 'header-medium-place',
  HeaderSmallIcon = 'header-small',
  HelpIcon = 'help',
  HomeIcon = 'home',
  InboxIcon = 'inbox',
  InboxOutlineIcon = 'inbox-outline',
  InfoAdminIcon = 'info-admin',
  InfoIcon = 'info',
  InfoOutlineIcon = 'info-outline',
  KeyIcon = 'key',
  KhorosCurlIcon = 'khoros-curl',
  KhorosFullLogoIcon = 'khoros-full-logo',
  KhorosLogoIcon = 'khoros-logo',
  LanguageIcon = 'icon-language',
  LayoutCardIcon = 'layout-card',
  LayoutClusterIconIcon = 'layout-cluster-icon',
  LayoutGridIcon = 'icon-layout-grid',
  LayoutListIcon = 'layout-list',
  LikeIcon = 'like',
  LikeOutlineIcon = 'like-outline',
  LikeRemoveIcon = 'like-remove',
  LikeRemoveOutlineIcon = 'like-remove-outline',
  LockIcon = 'lock',
  LockOutlineIcon = 'lock-outline',
  MembershipRoleIcon = 'membership-role',
  MentionIcon = 'mention',
  MenuIcon = 'menu',
  MessagesIcon = 'messages',
  MicrophoneIcon = 'microphone',
  MicrophoneOutlineIcon = 'microphone-outline',
  NavHighlightBackgroundIcon = 'nav-highlight-background',
  NavHighlightColorIcon = 'nav-highlight-color',
  NavHighlightUnderlineIcon = 'nav-highlight-underline',
  NotificationIcon = 'notification',
  NotificationOutlineIcon = 'notification-outline',
  OverviewStructureIcon = 'overview-structure',
  PanelDividedIcon = 'panel-divided',
  PanelRoundedIcon = 'panel-rounded',
  PanelSpacedIcon = 'panel-spaced',
  PinIcon = 'pin',
  PinOutlineIcon = 'pin-outline',
  PoweredByKhorosLogoIcon = 'powered-by-khoros-logo',
  QuoteIcon = 'quote',
  RankIcon = 'rank',
  RefreshIcon = 'refresh',
  RefreshOutlineIcon = 'refresh-outline',
  RepliesIcon = 'replies',
  RestrictedIcon = 'icon-restricted',
  SearchIcon = 'search',
  SearchOutlineIcon = 'search-outline',
  SectionOneColumnIcon = 'section-1column',
  SectionThreeColumnIcon = 'section-3column',
  SectionTwoColumnIcon = 'section-2column',
  SectionWideLeftIcon = 'section-wide-left',
  SectionWideRightIcon = 'section-wide-right',
  SelectArrowIcon = 'select-arrow',
  SendIcon = 'send',
  SendOutlineIcon = 'send-outline',
  SizeContractIcon = 'size-contract',
  SizeContractOutlineIcon = 'size-contract-outline',
  SizeExpandIcon = 'size-expand',
  SizeExpandOutlineIcon = 'size-expand-outline',
  StarOutlineIcon = 'icon-star-outline',
  StarSolidIcon = 'icon-star-solid',
  StopwatchIcon = 'stopwatch',
  SubtractSolidIcon = 'subtract-solid',
  SubtractSolidOutlineIcon = 'subtract-solid-outline',
  SystemAccountAndPrivacyIcon = 'system-account-and-privacy',
  SystemAdvancedIcon = 'system-advanced',
  SystemAnnouncementsIcon = 'system-announcements',
  SystemAuditIcon = 'system-audit',
  SystemAuthenticationIcon = 'system-authentication',
  SystemDisplayIcon = 'system-display',
  SystemRegistrationIcon = 'system-registration',
  SystemSearchIcon = 'system-search',
  SystemSeoIcon = 'system-seo',
  TemplateContentIcon = 'template-content',
  TemplateDashboardIcon = 'template-dashboard',
  TemplateGrouphubsIcon = 'template-grouphubs',
  TemplateHeaderFooterIcon = 'template-header-footer',
  TemplateHomeIcon = 'template-home',
  TemplateProfileIcon = 'template-profile',
  TextAlignCenterIcon = 'text-align-center',
  TextAlignLeftIcon = 'text-align-left',
  TextAlignRightIcon = 'text-align-right',
  ThemeAvatarsIcon = 'theme-avatars',
  ThemeBasicsIcon = 'theme-basics',
  ThemeButtonsIcon = 'theme-buttons',
  ThemeColorsIcon = 'theme-colors',
  ThemeElementsIcon = 'theme-elements',
  ThemeFontsIcon = 'theme-fonts',
  ThemeIconsIcon = 'theme-icons',
  TitleBottomIcon = 'title-bottom',
  TitleOverlayIcon = 'title-overlay',
  TitleTopIcon = 'title-top',
  TrashIcon = 'trash',
  TrashOutlineIcon = 'trash-outline',
  UnfollowIcon = 'unfollow',
  UnfollowOutlineIcon = 'unfollow-outline',
  UserIcon = 'user',
  UserLockedIcon = 'user-locked',
  UserOutlineIcon = 'user-outline',
  UsersAddIcon = 'users-add',
  UsersBadgesIcon = 'users-badges',
  UsersProfileIcon = 'users-profile',
  UsersRanksIcon = 'users-ranks',
  UsersRolesIcon = 'users-roles',
  VideoPlayActiveIcon = 'video-play-active',
  VideoPlayDisabledIcon = 'video-play-disabled',
  ViewsHideIcon = 'views-hide',
  ViewsHideOutlineIcon = 'views-hide-outline',
  ViewsIcon = 'views',
  ViewsOutlineIcon = 'views-outline',
  WidgetContentIcon = 'widget-content',
  WidgetCustomIcon = 'widget-custom',
  WidgetDynamicIcon = 'widget-dynamic',
  WidgetFpoIcon = 'widget-fpo',
  WidgetPeopleIcon = 'widget-people',
  WidgetPlacesIcon = 'widget-places',
  WidgetStaticIcon = 'widget-static',
  ZoomLogoIcon = 'zoom-logo'
}

export default IconTypes;
