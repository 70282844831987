import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';
export default class BoldItalicStyle implements FontStyleDefinition {
  name = FontStyleName.BOLDITALIC;

  style = FontStyle.Italic;

  weight = 700;
}
