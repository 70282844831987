import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Popover, useClassNameMapper } from 'react-bootstrap';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { SharedComponent } from '../../../enums';
import { formBypassOptions } from '../../../helpers/form/FormHelper/FormBypassHelper';
import { offsetPopperConfig } from '../../../helpers/ui/PopperJsHelper';
import CustomValidationChecklist from '../../common/CustomValidationChecklist/CustomValidationChecklist';
import useIsWide from '../../useIsWide';
import { FormFieldVariant } from '../enums';
import PasswordField from '../PasswordField/PasswordField';
import type { BasePasswordFieldSpec, PasswordFieldSpec } from '../PasswordField/types';
import type { FormFieldProps } from '../types';
import localStyles from './NewPasswordField.module.pcss';
import useNewPasswordCustomValidations from './useNewPasswordCustomValidations';
import useTranslation from '../../useTranslation';

export type NewPasswordFieldSpec<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
> = BasePasswordFieldSpec<NameT, DataT, FormFieldVariant.NEW_PASSWORD>;

/**
 * A wrapper around PasswordField that also handles rendering and validating password requirements
 * @author Rosalyn Rowe
 */
const NewPasswordField = <NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  props: FormFieldProps<NameT, DataT, NewPasswordFieldSpec<NameT, DataT>>
): React.ReactElement => {
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.NEW_PASSWORD_FIELD
  );
  const { bypassFormValidation } = formBypassOptions();
  const inputRef = useRef<HTMLInputElement>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const cx = useClassNameMapper(localStyles);
  const isWide = useIsWide();
  const { fieldSpec, formMethods } = props;

  const {
    formState: { isSubmitted },
    getValues,
    trigger
  } = formMethods;

  const { name, usernameName, username: specUsername } = fieldSpec;
  const username = usernameName ? getValues(usernameName) : specUsername;

  /** The value on the form seems to only update onBlur.. if we track it here we can know what it is after each keystroke, to help with real time validation */
  const [currentValue, setCurrentValue] = useState<string>(null);

  /**
   * react-hook-form tracks if a form/field is 'dirty' (is currently different than the default)
   * or 'touched' (has been interacted with at all, including a focus/blur cycle),
   * but we want to track if the user has ever actually *typed* in the field
   */
  const [enableTriggerOnBlur, setEnableTriggerOnBlur] = useState<boolean>(false);

  /** We don't want to trigger immediately, only after validations have already been run once */
  const [enableTriggerOnUsernameChanged, setEnableTriggerOnUsernameChanged] =
    useState<boolean>(false);

  /**
   * We need to manually trigger when the username changes, because it may change the validity of the password
   */
  useEffect(() => {
    if (enableTriggerOnUsernameChanged) {
      trigger(name);
    }
  }, [enableTriggerOnUsernameChanged, name, trigger, username]);

  const getCustomValidations = useNewPasswordCustomValidations<NameT, DataT>();

  if (textLoading) {
    return null;
  }

  const customValidations = getCustomValidations({
    currentPassword: currentValue,
    currentUsername: username,
    fieldSpec
  });

  const passwordFieldProps: FormFieldProps<NameT, DataT, PasswordFieldSpec<NameT, DataT>> = {
    ...props,
    fieldSpec: {
      ...props.fieldSpec,
      fieldVariant: FormFieldVariant.PASSWORD,
      onFocus: () => {
        if (!bypassFormValidation) {
          // LIA-95707 Added setTimeout as a hack to work around race condition between
          // focus being set and tooltip state showing causes infinite render loop in Chrome
          setTimeout(() => setShowTooltip(true), 0);
        }
      },
      onBlur: () => {
        if (!bypassFormValidation) {
          setShowTooltip(false);
        }
        // No point in wasting cycles, it will revalidate on every change
        if (isSubmitted) {
          return;
        }

        /**
         * Revalidates when this field is clicked away from -- it's an exception to the mode/reValidateMode on the form
         * Should only occur if user has attempted to enter a password.
         */
        if (enableTriggerOnBlur) {
          trigger(name);
        }
      },
      onChange: event => {
        setEnableTriggerOnBlur(true);
        setCurrentValue((event.target as HTMLInputElement).value);
      },
      validations: {
        ...props.fieldSpec.validations,
        validate: {
          ...props.fieldSpec.validations.validate,
          validPassword: value => {
            setEnableTriggerOnUsernameChanged(true);

            /*
             * This runs before onChange so it needs to get a fresh instance of customValidations
             * Does not seem to impact performance much but will need to re-evaluate if it gets slow
             */
            const freshValidations = getCustomValidations({
              currentPassword: value,
              currentUsername: username,
              fieldSpec
            });
            return freshValidations.every(validation => !validation.hasError());
          }
        }
      }
    }
  };

  return (
    <>
      <Overlay
        show={showTooltip}
        target={() => inputRef.current}
        placement={isWide ? 'left' : 'bottom'}
        popperConfig={offsetPopperConfig(0, 10, true)}
        container={() =>
          (inputRef.current?.closest('[role=dialog]') as HTMLElement) ?? document.body
        }
      >
        <Popover id="password-popover" className={cx('lia-popover')}>
          <Popover.Title className={cx('lia-popover-header')}>
            {formatMessage('popoverTitle')}
          </Popover.Title>
          <Popover.Content>
            <CustomValidationChecklist
              componentId={SharedComponent.NEW_PASSWORD_FIELD}
              customValidations={customValidations}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PasswordField overlayRef={inputRef} {...passwordFieldProps}></PasswordField>
    </>
  );
};

export default NewPasswordField;
