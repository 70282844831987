import React from 'react';

/**
 * Provides context for the text key toggle.
 */
interface ToggleTextKeysContextInterface {
  /**
   * Whether to show the text keys.
   */
  showTextKeys?: boolean;
}

const ToggleTextKeysContext = React.createContext<ToggleTextKeysContextInterface>({
  showTextKeys: false
});

export default ToggleTextKeysContext;
