import type { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Enum representing different font styles.
 * Note: Please don't change the order of this enum. if you need to change it, please confirm with the product team.
 * @enum {string}
 */
export enum FontStyleName {
  THIN = 'THIN',
  EXTRALIGHT = 'EXTRALIGHT',
  LIGHT = 'LIGHT',
  REGULAR = 'REGULAR',
  MEDIUM = 'MEDIUM',
  SEMIBOLD = 'SEMIBOLD',
  BOLD = 'BOLD',
  EXTRABOLD = 'EXTRABOLD',
  BLACK = 'BLACK',
  THINITALIC = 'THINITALIC',
  EXTRALIGHTITALIC = 'EXTRALIGHTITALIC',
  LIGHTITALIC = 'LIGHTITALIC',
  ITALIC = 'ITALIC',
  MEDIUMITALIC = 'MEDIUMITALIC',
  SEMIBOLDITALIC = 'SEMIBOLDITALIC',
  BOLDITALIC = 'BOLDITALIC',
  EXTRABOLDITALIC = 'EXTRABOLDITALIC',
  BLACKITALIC = 'BLACKITALIC'
}

export interface FontStyleDefinition {
  /** Whether the font-face is italic. This will affect an external API call */
  style: FontStyle;

  /**
   * The style associated with this definition.
   * This will determine the key that is sent to the server when that style is selected
   * And is also used for determining options to display in the dropdown
   */
  name: FontStyleName;

  /** The weight of the font-face. This will affect an external API call. */
  weight: number;
}
