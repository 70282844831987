import type { QueryResult } from '@apollo/client';
import { evictLocalOverrides } from '@aurora/shared-apollo/apolloCacheHelper';
import query from '@aurora/shared-apollo/resolvers/assets/CachedAsset.query.graphql';
import type {
  CachedAssetQuery,
  CachedAssetQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import type { CachedAssetType } from '@aurora/shared-types/overrides/enums';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import { isCustomizationBranch } from '@aurora/shared-utils/helpers/developer/SwitchBranchHelper';
import SwitchBranchContext from '../context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '../context/TenantContext';
import useQueryWithTracing from '../useQueryWithTracing';

const log = getLog(module);

/**
 * Returns a cached asset query result.
 * @param assetId the asset id
 * @param assetType the asset type
 * @param lastModified the last modified date
 * @param node a node to scope the asset to (optional)
 * @param locale the locale to scope the asset to (optional)
 * @param skip skip the query
 */
export default function useCachedAsset(
  assetId: string,
  assetType: CachedAssetType,
  lastModified: string,
  node?: string,
  locale?: string,
  skip = false
): QueryResult<CachedAssetQuery, CachedAssetQueryVariables> {
  const tenant = useContext(TenantContext);
  const { branchName } = useContext(SwitchBranchContext);
  const skipCache = isCustomizationBranch(tenant, branchName);
  const cachedAssetResult = useQueryWithTracing<CachedAssetQuery, CachedAssetQueryVariables>(
    module,
    query,
    {
      variables: {
        assetId,
        assetType: assetType,
        lastModified: skipCache
          ? LastModifiedKeys.BYPASS_OVERRIDE_CACHE
          : lastModified ?? LastModifiedKeys.DEFAULTS_ONLY,
        node,
        locale
      },
      skip,
      context: {
        tenant
      },
      fetchPolicy: skipCache ? 'no-cache' : undefined
    }
  );
  const { loading, data, error } = cachedAssetResult;

  if (error) {
    log.error(error, 'Error loading %s asset with id: %s', assetType, assetId);
  } else if (!loading && !skipCache && data) {
    evictLocalOverrides(cachedAssetResult.client, data.cachedAsset);
  }
  return cachedAssetResult;
}
