import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class ThinItalicStyle implements FontStyleDefinition {
  name = FontStyleName.THINITALIC;

  style = FontStyle.Italic;

  weight = 100;
}
