import { useContext } from 'react';
import endUserComponentRegistry from '../features/endUserComponentRegistry';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import type { PageDescriptor } from '@aurora/shared-types/pages';
import type { BannerWidgetProps } from './community/BannerWidget/types';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserPages } from '@aurora/shared-types/pages/enums';

/**
 * Get the page descriptor for the current end user page.
 */
export default function useEndUserPageDescriptor(): PageDescriptor<BannerWidgetProps> {
  const { pageId } = useContext(PageContext);
  const { router } = useEndUserRoutes();
  // TODO - should this load from LIA ?
  return endUserComponentRegistry.getPageDescriptor(
    pageId ?? router.getCurrentPageName() ?? EndUserPages.CommunityPage
  );
}
