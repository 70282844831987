import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  boardPageRedirectBehavior,
  editCWPageRedirectBehavior,
  messagePageRedirectBehavior,
  messageReplyPageRedirectBehavior,
  noRedirectBehavior,
  postPageRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for blogs that holds the discussion style specific attributes
 * @author Manish Shrestha
 */
export class TkbFeature implements CommunityFeature<Feature.TKB> {
  getFeature(): Feature.TKB {
    return Feature.TKB;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.TkbBoardPage,
        endUserModuleRegistry[EndUserComponent.TKB_BOARD_PAGE],
        pageDescriptorUrlPath[EndUserPages.TkbBoardPage],
        getEntityScopeForPage(EntityType.TKB),
        boardPageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      ),
      new PageDescriptor(
        EndUserPages.TkbMessagePage,
        endUserModuleRegistry[EndUserComponent.TKB_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.TkbMessagePage],
        getEntityScopeForPage(EntityType.TKB_ARTICLE),
        messagePageRedirectBehavior,
        true,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.TkbReplyPage,
        endUserModuleRegistry[EndUserComponent.TKB_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.TkbReplyPage],
        getEntityScopeForPage(EntityType.TKB_REPLY),
        messageReplyPageRedirectBehavior,
        false,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.TkbPostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.TkbPostPage],
        getEntityScopeForPage(EntityType.TKB),
        postPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.TkbEditPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.TkbEditPage],
        getEntityScopeForPage(EntityType.TKB),
        editCWPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.TkbViewAllArticlesPage,
        endUserModuleRegistry[EndUserComponent.TKB_VIEW_ALL_ARTICLES_PAGE],
        pageDescriptorUrlPath[EndUserPages.TkbViewAllArticlesPage],
        getEntityScopeForPage(EntityType.TKB),
        noRedirectBehavior,
        false,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.TKB_ARTICLE_WIDGET,
        component: dynamic(() => import('../components/tkbs/TkbArticleWidget/TkbArticleWidget')),
        editor: {
          form: dynamic(
            () => import('../components/tkbs/TkbArticleWidgetEditor/TkbArticleWidgetEditor')
          )
        },
        allowedPages: [EndUserPages.TkbMessagePage]
      }
    ];
  }
}
