import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

export class KudosFeature implements CommunityFeature<Feature.KUDOS> {
  getFeature(): Feature.KUDOS {
    return Feature.KUDOS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_USER_BY_KUDOS_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/MessageListForUserByKudosWidget/MessageListForUserByKudosWidget'
            )
        )
      },
      {
        id: EndUserComponent.USER_LIST_FOR_NODE_BY_TOP_CONTRIBUTORS_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/users/UserListForNodeByTopContributorsWidget/UserListForNodeByTopContributorsWidget'
            ),
          {
            ssr: false
          }
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/users/UserListForNodeByTopContributorsWidgetEditor/UserListForNodeByTopContributorsWidgetEditor'
              )
          )
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.BlogBoardPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.TkbBoardPage,
          EndUserPages.IdeaBoardPage
        ]
      }
    ];
  }
}
