import { useCallback } from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';
import type CustomValidation from '../../common/CustomValidation/CustomValidation';
import { validateHasLowercaseCharacters } from '../../common/CustomValidation/LowercaseValidator';
import { validateMinimumLength } from '../../common/CustomValidation/MinimumLengthValidator';
import { validateNoPartOfUsername } from '../../common/CustomValidation/NoPartOfUsernameValidator';
import { validateHasNumericalCharacters } from '../../common/CustomValidation/NumericalValidator';
import { validateHasNoRepeatingSequence } from '../../common/CustomValidation/RepeatingSequenceValidator';
import { validateHasUppercaseCharacters } from '../../common/CustomValidation/UppercaseValidator';
import type { NewPasswordFieldSpec } from './NewPasswordField';
import { NewPasswordFieldCustomValidationError } from './types';
import { getValidationRuleValue } from '../../../helpers/form/ValidationRule/ValidationRuleHelper';
import { shouldUseNumericalValidation } from '../../../helpers/form/Validation/ValidationHelper';
import { SpecialChecksValidation } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FieldValidationRules } from '../types';
import { formBypassOptions } from '../../../helpers/form/FormHelper/FormBypassHelper';

export interface NewPasswordCustomValidationProps<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
> {
  /** The current value of the username field as entered by user input */
  currentUsername: string;

  /** The current value of the password field as entered by user input */
  currentPassword: string;
  /** We pull information from this to determine which validations to actually use */
  fieldSpec: NewPasswordFieldSpec<NameT, DataT>;
}

/**
 * IsStrongPassword is a special validation that will behave as a specific combination of validations.
 * This is currently re-implemented on the client, so if the server side implementation changes, we will
 * need to either update the client implementation or figure out a way to share them.
 */
function isStrongPassword<NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  fieldSpec: NewPasswordFieldSpec<NameT, DataT>
) {
  const { specialChecks } = fieldSpec;
  return specialChecks && specialChecks.includes(SpecialChecksValidation.IsStrongPassword);
}

/**
 * If isStrongPassword is in the special checks list, the minLength must be 6 regardless of what the server returned.
 */
function getValidations<NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  fieldSpec: NewPasswordFieldSpec<NameT, DataT>
): FieldValidationRules<DataT, NameT> {
  const { validations } = fieldSpec;

  return isStrongPassword(fieldSpec)
    ? {
        ...validations,
        minLength: 6
      }
    : validations;
}

/**
 * If isStrongPassword is in the special checks list, we'll append all of the other specialChecks that it encompasses to the list.
 * This will keep the implementation neat and easy to modify vs. checking for isStrongPassword in each individual check in the main function
 */
function getSpecialChecks<NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  fieldSpec: NewPasswordFieldSpec<NameT, DataT>
): SpecialChecksValidation[] {
  const { specialChecks } = fieldSpec;

  if (isStrongPassword(fieldSpec)) {
    return [
      ...(specialChecks ?? []),
      SpecialChecksValidation.HasLower,
      SpecialChecksValidation.HasUpper,
      SpecialChecksValidation.HasDigit,
      SpecialChecksValidation.HasNoRepeat
    ];
  } else {
    return specialChecks ?? [];
  }
}

function getCustomValidations<NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  props: NewPasswordCustomValidationProps<NameT, DataT>
): CustomValidation[] {
  const { bypassFormValidation } = formBypassOptions();

  if (bypassFormValidation) {
    return [];
  }

  const { currentUsername, currentPassword, fieldSpec } = props;
  const validations = getValidations(fieldSpec);
  const specialChecks = getSpecialChecks(fieldSpec);

  const minimumLength = getValidationRuleValue(validations.minLength);
  const customValidations: CustomValidation[] = [];

  // No point in having a real validate function, we won't be using it for this case
  const validate = () => {};

  if (shouldUseNumericalValidation(minimumLength)) {
    customValidations.push({
      errorKey: NewPasswordFieldCustomValidationError.MINIMUM_LENGTH,
      translationKey: NewPasswordFieldCustomValidationError.MINIMUM_LENGTH,
      validate,
      hasError: () => !validateMinimumLength(currentPassword, minimumLength),
      values: { minimumLength }
    });
  }

  if (specialChecks.includes(SpecialChecksValidation.HasNoRepeat)) {
    customValidations.push({
      errorKey: NewPasswordFieldCustomValidationError.REPEATED_SEQUENCE,
      translationKey: NewPasswordFieldCustomValidationError.REPEATED_SEQUENCE,
      validate,
      hasError: () => !validateHasNoRepeatingSequence(currentPassword, 3)
    });
  }

  const shouldCheckUpper = specialChecks.includes(SpecialChecksValidation.HasUpper);
  const shouldCheckLower = specialChecks.includes(SpecialChecksValidation.HasLower);

  if (shouldCheckUpper && !shouldCheckLower) {
    customValidations.push({
      errorKey: NewPasswordFieldCustomValidationError.UPPER,
      translationKey: NewPasswordFieldCustomValidationError.UPPER,
      validate,
      hasError: () => !validateHasUppercaseCharacters(currentPassword)
    });
  }

  if (shouldCheckLower && !shouldCheckUpper) {
    customValidations.push({
      errorKey: NewPasswordFieldCustomValidationError.LOWER,
      translationKey: NewPasswordFieldCustomValidationError.LOWER,
      validate,
      hasError: () => !validateHasLowercaseCharacters(currentPassword)
    });
  }

  if (shouldCheckLower && shouldCheckUpper) {
    customValidations.push({
      errorKey: NewPasswordFieldCustomValidationError.UPPER_AND_LOWER,
      translationKey: NewPasswordFieldCustomValidationError.UPPER_AND_LOWER,
      validate,
      hasError: () =>
        !(
          validateHasUppercaseCharacters(currentPassword) &&
          validateHasLowercaseCharacters(currentPassword)
        )
    });
  }

  if (specialChecks.includes(SpecialChecksValidation.HasDigit)) {
    customValidations.push({
      errorKey: NewPasswordFieldCustomValidationError.HAS_DIGIT,
      translationKey: NewPasswordFieldCustomValidationError.HAS_DIGIT,
      validate,
      hasError: () => !validateHasNumericalCharacters(currentPassword)
    });
  }

  /*
   * This one is currently always applied because it is part of the standard check and not configurable,
   * but that may change in the future. If that happens, we'll need to add a new SpecialChecksValidation.
   */
  customValidations.push({
    errorKey: NewPasswordFieldCustomValidationError.NO_PART_OF_USERNAME,
    translationKey: NewPasswordFieldCustomValidationError.NO_PART_OF_USERNAME,
    validate,
    hasError: () => !validateNoPartOfUsername(currentUsername, currentPassword)
  });

  return customValidations;
}

/**
 * Supplies the custom validations necessary for a NewPasswordField
 * @author Rosalyn Rowe
 */
export default function useNewPasswordCustomValidations<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
>(): (props: NewPasswordCustomValidationProps<NameT, DataT>) => CustomValidation[] {
  const result = useCallback(props => {
    return getCustomValidations(props);
  }, []);

  return result;
}
