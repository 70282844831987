import React from 'react';

export interface SwitchUserContextInterface {
  /**
   * The id of the impersonated user
   */
  switchUserId: number | undefined;
  /**
   * Toggle the display of the switch user modal
   * @param show
   */
  showSwitchUserModal: (show: boolean) => void;
  /**
   * Callback to clear switchUserId
   */
  clearSwitchUserId: () => void;
}

/**
 * Switch user context provides the id of the user that is switched to.
 *
 * @author Adam Ayres
 */
const SwitchUserContext = React.createContext<SwitchUserContextInterface>({
  switchUserId: undefined,
  showSwitchUserModal: () => null,
  clearSwitchUserId: () => null
});

export default SwitchUserContext;
