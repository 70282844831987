import type { PageResource } from '@aurora/shared-generated/types/graphql-schema-types';
import { AppType } from '@aurora/shared-types/app';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { CachedAssetType } from '@aurora/shared-types/overrides/enums';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { useContext, useMemo } from 'react';
import useCachedAsset from '../components/assets/useCachedAsset';
import AppContext from '../components/context/AppContext/AppContext';
import TenantContext from '../components/context/TenantContext';
import buildCustomLink from './buildCustomLink';
import { getRoutesCacheInstance } from './RoutesCache';
import type { CustomRouter } from './useCustomRouter';
import useCustomRouter from './useCustomRouter';

export type EndUserRouter = CustomRouter<EndUserPages, EndUserQueryParams>;

/**
 * Gets a router and Link contextualized to the current tenant for the End User app.
 *
 * @author Adam Ayres
 */
export default function useEndUserRoutes() {
  const tenant = useContext(TenantContext);
  const appContext = useContext(AppContext);
  const { pagesLastModified } = appContext;
  const { data, loading } = useCachedAsset(
    'pages',
    CachedAssetType.PAGES,
    pagesLastModified ?? LastModifiedKeys.USE_CACHED
  );
  const routes = useMemo(
    () =>
      getRoutesCacheInstance().buildRoutes(tenant, {
        pages: (data?.cachedAsset?.value as PageResource[]) ?? [],
        lastModified: data?.cachedAsset?.id,
        localOverride: data?.cachedAsset?.localOverride ?? false
      }),
    [tenant, data]
  );
  const Link = useMemo(() => {
    if (!loading) {
      return buildCustomLink<EndUserPages>(routes, AppType.END_USER);
    } else {
      return function LoadingLink() {
        return null;
      };
    }
  }, [loading, routes]);
  const router = useCustomRouter<EndUserPages, EndUserQueryParams>(routes, AppType.END_USER);
  return { router, Link, loading };
}
