import Color from 'color';
import { getLog } from '@aurora/shared-utils/log';
import safeColor from './SafeColor';
import type { ColorCssVariableOrValue } from '@aurora/shared-types/styles';

const log = getLog(module);

/**
 * Creates a map where the keys are CSS variables and the values are the expanded parts of
 * a valid HSL color.
 *
 * @param color the color
 * @param varPrefix the CSS var prefix.
 *
 * @author Adam Ayres
 */
export function createCssVariablesForColor(
  color: string,
  varPrefix: string
): Record<string, string> | null {
  if (color == null) {
    return {};
  }
  if (color?.startsWith('var(')) {
    return {
      [varPrefix]: color
    };
  }

  let colorObject;
  try {
    colorObject = Color(color);
  } catch {
    log.error(`Unable to create CSS variables for color ${color}`);
    return null;
  }

  const [h, s, l, a] = colorObject.hsl().array();

  const hVar = `${varPrefix}-h`;
  const sVar = `${varPrefix}-s`;
  const lVar = `${varPrefix}-l`;

  const mapping = {
    [hVar]: h.toString(),
    [sVar]: `${s}%`,
    [lVar]: `${l}%`
  };

  if (a) {
    const aVar = `${varPrefix}-a`;
    return {
      ...mapping,
      [aVar]: a.toString(),
      [varPrefix]: `hsla(var(${hVar}), var(${sVar}), var(${lVar}), var(${aVar}))`
    };
  } else {
    return {
      ...mapping,
      [varPrefix]: `hsl(var(${hVar}), var(${sVar}), var(${lVar}))`
    };
  }
}

export type ValidColorCheck = (color: ColorCssVariableOrValue) => boolean;

/**
 * Attempts to parse the color from the specified border color.
 *
 * @param border the border color
 * @param fallback fallback used when color cannot be determined
 * @param validColorCheck callback function to check if the color is valid
 */
export function parseColorFromCssBorder(
  border: string,
  fallback = 'transparent',
  validColorCheck: ValidColorCheck = (color: string) => safeColor({}, color, null) !== null
): string {
  const items = border?.split(/(?!\(.*)\s(?![^(]*?\))/g) ?? [];
  const firstParen = items.findIndex(item => item.includes('('));
  const parsedValue = items.slice(firstParen).join(' ');

  const validColor = validColorCheck(parsedValue);
  if (validColor) {
    return parsedValue;
  }
  return fallback;
}
