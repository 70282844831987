import { useContext } from 'react';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import type { AppContextInterface } from '../context/AppContext/AppContext';
import AppContext from '../context/AppContext/AppContext';

interface ExtendedRegistrationStatus {
  registrationStatus: RegistrationStatus;
  isAnonymous: boolean;
  isPartiallyRegistered: boolean;
  isFullyRegistered: boolean;
  isRegistered: boolean;
  confirmEmailStatus: boolean;
}

export default function useRegistrationStatus(): ExtendedRegistrationStatus {
  const { authUser } = useContext<AppContextInterface>(AppContext);
  const status = authUser?.registrationData?.status ?? RegistrationStatus.Anonymous;
  const emailStatus = authUser?.registrationData?.confirmEmailStatus ?? false;

  return {
    registrationStatus: status,
    isAnonymous: status === RegistrationStatus.Anonymous,
    isPartiallyRegistered: status === RegistrationStatus.PartiallyRegistered,
    isFullyRegistered: status === RegistrationStatus.FullyRegistered,
    isRegistered:
      status === RegistrationStatus.FullyRegistered ||
      status === RegistrationStatus.PartiallyRegistered,
    confirmEmailStatus: emailStatus
  };
}
