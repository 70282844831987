import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  boardPageRedirectBehavior,
  editCWPageRedirectBehavior,
  messagePageRedirectBehavior,
  messageReplyPageRedirectBehavior,
  noRedirectBehavior,
  postPageRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for blogs that holds the discussion style specific attributes
 * @author Manish Shrestha
 */
export class BlogsFeature implements CommunityFeature<Feature.BLOGS> {
  getFeature(): Feature.BLOGS {
    return Feature.BLOGS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.BlogBoardPage,
        endUserModuleRegistry[EndUserComponent.BLOG_BOARD_PAGE],
        pageDescriptorUrlPath[EndUserPages.BlogBoardPage],
        getEntityScopeForPage(EntityType.BLOG),
        boardPageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      ),
      new PageDescriptor(
        EndUserPages.BlogMessagePage,
        endUserModuleRegistry[EndUserComponent.BLOG_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.BlogMessagePage],
        getEntityScopeForPage(EntityType.BLOG_ARTICLE),
        messagePageRedirectBehavior,
        true,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.BlogReplyPage,
        endUserModuleRegistry[EndUserComponent.BLOG_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.BlogReplyPage],
        getEntityScopeForPage(EntityType.BLOG_REPLY),
        messageReplyPageRedirectBehavior,
        false,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.BlogPostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.BlogPostPage],
        getEntityScopeForPage(EntityType.BLOG),
        postPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.BlogEditPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.BlogEditPage],
        getEntityScopeForPage(EntityType.BLOG),
        editCWPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.BlogViewAllPostsPage,
        endUserModuleRegistry[EndUserComponent.BLOG_VIEW_ALL_POSTS_PAGE],
        pageDescriptorUrlPath[EndUserPages.BlogViewAllPostsPage],
        getEntityScopeForPage(EntityType.BLOG),
        noRedirectBehavior,
        false,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.BLOG_ARTICLE_WIDGET,
        component: dynamic(() => import('../components/blogs/BlogArticleWidget/BlogArticleWidget'))
      }
    ];
  }
}
