import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  boardPageRedirectBehavior,
  editPageRedirectBehavior,
  messagePageRedirectBehavior,
  messageReplyPageRedirectBehavior,
  noRedirectBehavior,
  postPageRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for ideas.
 *
 * @author Be.Abhijith
 */
export class IdeasFeature implements CommunityFeature<Feature.IDEAS> {
  getFeature(): Feature.IDEAS {
    return Feature.IDEAS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.IdeaBoardPage,
        endUserModuleRegistry[EndUserComponent.IDEA_BOARD_PAGE],
        pageDescriptorUrlPath[EndUserPages.IdeaBoardPage],
        getEntityScopeForPage(EntityType.IDEA),
        boardPageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      ),
      new PageDescriptor(
        EndUserPages.IdeaMessagePage,
        endUserModuleRegistry[EndUserComponent.IDEA_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.IdeaMessagePage],
        getEntityScopeForPage(EntityType.IDEA_POST),
        messagePageRedirectBehavior,
        true,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.IdeaReplyPage,
        endUserModuleRegistry[EndUserComponent.IDEA_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.IdeaReplyPage],
        getEntityScopeForPage(EntityType.IDEA_REPLY),
        messageReplyPageRedirectBehavior,
        false,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.IdeaPostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.IdeaPostPage],
        getEntityScopeForPage(EntityType.IDEA),
        postPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.IdeaEditPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.IdeaEditPage],
        getEntityScopeForPage(EntityType.IDEA),
        editPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.IdeaViewAllIdeasPage,
        endUserModuleRegistry[EndUserComponent.IDEA_VIEW_ALL_IDEAS_PAGE],
        pageDescriptorUrlPath[EndUserPages.IdeaViewAllIdeasPage],
        getEntityScopeForPage(EntityType.IDEA),
        noRedirectBehavior,
        false,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.IDEA_MESSAGES_WIDGET,
        component: dynamic(
          () => import('../components/ideas/IdeaMessagesWidget/IdeaMessagesWidget')
        ),
        editor: {
          form: dynamic(
            () => import('../components/ideas/IdeaMessagesWidgetEditor/IdeaMessagesWidgetEditor')
          )
        },
        allowedPages: [EndUserPages.IdeaBoardPage, EndUserPages.IdeaMessagePage]
      },
      {
        id: EndUserComponent.IDEA_SEARCH_BEFORE_POST_WIDGET,
        component: dynamic(
          () => import('../components/ideas/IdeaSearchBeforePostWidget/IdeaSearchBeforePostWidget')
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/ideas/IdeaSearchBeforePostWidgetEditor/IdeaSearchBeforePostWidgetEditor'
              )
          )
        },
        allowedPages: [EndUserPages.IdeaBoardPage]
      },
      {
        id: EndUserComponent.IDEA_TOPIC_WIDGET,
        component: dynamic(() => import('../components/ideas/IdeaTopicWidget/IdeaTopicWidget'))
      },
      {
        id: EndUserComponent.ALL_IDEAS_WIDGET,
        component: dynamic(() => import('../components/ideas/AllIdeasWidget/AllIdeasWidget')),
        allowedPages: [EndUserPages.IdeaViewAllIdeasPage]
      }
    ];
  }
}
