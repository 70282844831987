import type { QueryHookOptions } from '@apollo/client/react/types/types';
import communitySsoPropertiesQuery from '@aurora/shared-apollo/queries/CommunitySsoProperties.query.graphql';
import type {
  CommunitySsoPropertiesQuery,
  CommunitySsoPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { ExtendedQueryResult } from '../useQueryWithTracing';
import useQueryWithTracing from '../useQueryWithTracing';

/**
 * Gets SSO properties (read-only settings) for the current community.
 *
 * @param module The module
 * @param options Optional options to pass to the query
 * @author Dolan Halbrook
 */
export default function useCommunitySsoProperties(
  module: NodeModule | string,
  options?: QueryHookOptions<CommunitySsoPropertiesQuery, CommunitySsoPropertiesQueryVariables>
): ExtendedQueryResult<CommunitySsoPropertiesQuery, CommunitySsoPropertiesQueryVariables> {
  return useQueryWithTracing<CommunitySsoPropertiesQuery, CommunitySsoPropertiesQueryVariables>(
    module,
    communitySsoPropertiesQuery,
    options
  );
}
