import { TextAlignment } from '@aurora/shared-types/texts/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type IconTypes from '../../../public/static/graphics/processed/enums';
import { IconSize } from '../Icon/enums';
import Icon from '../Icon/Icon';
import localStyles from './EmptyState.module.pcss';

export interface EmptyStateProps {
  /**
   * Reference to the icon of the empty state.
   */
  icon?: IconTypes;
  /**
   * Text to display as title of the empty state.
   */
  title?: string;
  /**
   * Text or React Element to display as description of the empty state.
   */
  description?: React.ReactNode;
  /**
   * Class name(s) to apply to the description element.
   */
  descriptionClassName?: string;
  /**
   * Sets the alignment for this component.
   */
  alignment?: TextAlignment;
  /**
   * Class name(s) to apply to the wrapper element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the icon.
   */
  iconClassname?: string;
}

/**
 * Empty State Component.
 *
 * @author Shalin Amin
 */

const EmptyState: React.FC<React.PropsWithChildren<EmptyStateProps>> = ({
  icon,
  title,
  description,
  descriptionClassName,
  alignment = TextAlignment.CENTER,
  className,
  children,
  iconClassname
}) => {
  const cx = useClassNameMapper(localStyles);
  return (
    <div className={cx(`lia-wrap lia-is-${alignment}`, className)} data-testid="EmptyState">
      {icon && (
        <Icon icon={icon} size={IconSize.FROM_CSS} className={cx('lia-svg', iconClassname)} />
      )}
      <div
        aria-live="polite"
        className={cx({ 'lia-text-center': alignment === TextAlignment.CENTER })}
      >
        {title && <span className={cx('lia-title')}>{title}</span>}
        {description && (
          <small className={cx('lia-sub-title', descriptionClassName)}>{description}</small>
        )}
        {children}
      </div>
    </div>
  );
};

export default EmptyState;
