import type { FontStyleDefinition } from './types';

/**
 * A {@link FontStyleDefinition} with all fields nulled.
 * Used to implement custom value support in Theme Editor.
 */
export default class NullStyle implements FontStyleDefinition {
  name = null;

  style = null;

  weight = null;
}
