import React from 'react';
import { Form, useClassNameMapper } from 'react-bootstrap';
import Icons from '../../../icons';
import { IconColor, IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';

interface Props {
  /**
   * form field id
   */
  id?: string;
  /**
   * Message to display for the form field or for the form
   */
  message: string;

  /**
   * Classes applied to the error element.
   */
  className?: string;

  /**
   *  Whether the user input is valid or invalid and accordingly showing the state as green or red.
   */
  valid: boolean;
}

/**
 * Renders form field feedback
 *
 * @author Neha Anandpara
 */
const FormFieldFeedback: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  message,
  className,
  valid
}) => {
  const cx = useClassNameMapper();

  return (
    <Form.Control.Feedback
      type={valid ? 'valid' : 'invalid'}
      className={cx(className, { 'lia-g-validation-error': !valid })}
      data-testid={`FormFieldFeedback.${valid ? 'Valid' : 'Invalid'}`}
      aria-live="polite"
      id={id}
    >
      {!valid && (
        <Icon
          icon={Icons.DangerIcon}
          color={IconColor.DANGER}
          size={IconSize.PX_16}
          className={cx('lia-g-validation-error-icon')}
        />
      )}
      {message}
    </Form.Control.Feedback>
  );
};

export default FormFieldFeedback;
