import React, {
  forwardRef,
  type ForwardRefExoticComponent,
  type PropsWithoutRef,
  type RefAttributes
} from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../../enums';
import { LoadingTheme } from '../../../../types/enums';
import useTranslation from '../../../useTranslation';
import type { LoadingLinearProps } from '../types';
import localStyles from './LoadingLinear.module.pcss';

/**
 * Linear determinate loading indicator. Uses react-bootstrap ProgressBar.
 *
 * @author Subhiksha Venkatesan, Vaibhav Chawla
 */

const LoadingLinear: ForwardRefExoticComponent<
  PropsWithoutRef<LoadingLinearProps> & RefAttributes<HTMLDivElement>
> = forwardRef(function LoadingLinearForward(
  { className, theme = LoadingTheme.LIGHT, progress = 0 },
  ref
) {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.LOADING_LINEAR);

  return (
    <div
      className={cx('lia-loading-bar-wrap', className)}
      title={textLoading ? '...' : formatMessage('title')}
      ref={ref}
    >
      <div className={cx(`lia-loading-bar lia-loading-bar-${theme}`)}>
        <div
          role="progressbar"
          style={{ width: `${progress}%` }}
          title={textLoading ? '...' : formatMessage('title')}
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={progress}
        />
      </div>
    </div>
  );
});

export default LoadingLinear;
