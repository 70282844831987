import { useApolloClient } from '@apollo/client';
import { getCachedFragment } from '@aurora/shared-apollo/apolloCacheHelper';
import cachedAssetFragment from '@aurora/shared-apollo/resolvers/assets/CachedAsset.fragment.graphql';
import type { CachedAssetFragment } from '@aurora/shared-generated/types/graphql-types';

/**
 * Hook for quickly & cleanly fetching any cachedAsset fragment
 * @param id The cache ID
 * @returns The cached item
 */
export default function useCachedFragment(id: string): CachedAssetFragment {
  const client = useApolloClient();

  return getCachedFragment<CachedAssetFragment>(client, cachedAssetFragment, id);
}
