import type { CustomFont } from '@aurora/shared-generated/types/graphql-schema-types';
import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontDefinition } from '../fonts/FontDefinitions';
import { FontClassification, getStyle } from '../fonts/FontDefinitions';
import FontFamilies from './FontFamilies';

/**
 * The renderer does not appreciate it when it is given invalid font-style values
 * and will produce unexpected behavior, so only give it italic or normal.
 *
 * @param value the font style
 */
function getFontStyleForCss(value: FontStyle): string {
  if (value === FontStyle.Italic) {
    return 'italic';
  }

  return 'normal';
}

/**
 * @param fontFamily the font family
 */
function parseFontFamily(fontFamily: string): string[] {
  const fontFamilyParts = fontFamily.split(',');
  return fontFamilyParts.map(fontName => fontName.trim().replaceAll(/^'|'$/g, ''));
}

/**
 * We give special consideration to the local fonts since they have complex names that are likely to change.
 * The rest of the fonts must be named identically to the name of the font on the source, so... not needed
 */
function toFontValue(fontString: string): string {
  if (fontString === 'Helvetica') {
    return FontFamilies.HELVETICA;
  }

  if (fontString === 'Times New Roman') {
    return FontFamilies.TIMES_NEW_ROMAN;
  }

  return fontString;
}

/**
 * Parses an array of custom fonts into a standardized format.
 **/
function parseCustomFonts(customFonts: CustomFont[] = []): FontDefinition[] {
  return customFonts.map(font => {
    return {
      classification: FontClassification.CUSTOM,
      source: font.source,
      family: font.name,
      styles: font.styles.map(style => getStyle(style.weight, style.style)),
      assetNames: font.assetNames
    };
  });
}

export { getFontStyleForCss, parseFontFamily, toFontValue, parseCustomFonts };
