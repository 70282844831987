import { useIntl } from 'react-intl';

/**
 * Fetches the locale, or throws an error if it is not able to
 */
export default function useLocale() {
  const intl = useIntl();
  const { locale } = intl;

  if (locale === null) {
    throw new Error('No locale found in intl');
  }

  return locale;
}
