import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

export class TagsFeature implements CommunityFeature<Feature.TAGS> {
  getFeature(): Feature.TAGS {
    return Feature.TAGS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.TagPage,
        endUserModuleRegistry[EndUserComponent.TAG_PAGE],
        pageDescriptorUrlPath[EndUserPages.TagPage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.TAGS_HEADER_WIDGET,
        component: dynamic(() => import('../components/tags/TagsHeaderWidget/TagsHeaderWidget'))
      },
      {
        id: EndUserComponent.TAG_WIDGET,
        component: dynamic(() => import('../components/tags/TagWidget/TagWidget')),
        editor: {
          form: dynamic(() => import('../components/tags/TagWidgetEditor/TagWidgetEditor'))
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.BlogBoardPage,
          EndUserPages.TkbBoardPage,
          EndUserPages.IdeaBoardPage,
          EndUserPages.EventBoardPage
        ]
      }
    ];
  }
}
