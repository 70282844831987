interface FormBypassOptions {
  /**
   * If this is true, form layout overrides that come from the server should not be applied, and the default layout, which is
   * imported from the JSON in the component and passed into the InputEditForm's `formSchema` prop, should be applied instead.
   *
   * At time of writing, this functionality currently relies on {@link bypassFormValidation} also being true.
   *
   * If this true and {@link bypassFormValidation} is false,
   * the form will enter a strange state where it attempts to use the default layout with a formSpec based on overrides,
   * which may cause undefined behavior.
   * This should be fixed, TODO
   */
  bypassFormLayout: boolean;

  /**
   * If this is true, validations that come from the server should not be merged.
   * Validations that are defined directly by the components should still apply.
   *
   * At time of writing, this functionality currently relies on {@link bypassFormLayout} also being true.
   *
   * If this is true and {@link bypassFormValidation} is false,
   * the server validations will still be merged.
   * This should be fixed, TODO
   */
  bypassFormValidation: boolean;
}

/**
 * Making this a function makes it much easier to test differences in components that use these variables, as
 * modifying env vars in beforeEach/beforeAll blocks will actually be picked up. Make sure you are calling
 * the function within the component or hook, rather than at the top of the file.
 */
export const formBypassOptions: () => FormBypassOptions = () => ({
  bypassFormLayout: process.env.NEXT_PUBLIC_BYPASS_FORM_LAYOUT === 'true',
  bypassFormValidation: process.env.NEXT_PUBLIC_BYPASS_FORM_VALIDATION === 'true'
});
