import {
  clearSwitchUserIdCookie,
  setSwitchUserIdToCookie
} from '@aurora/shared-apollo/helpers/SwitchUserHelper';
import { LoadingVariant } from '@aurora/shared-client/components/common/Loading/enums';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '@aurora/shared-client/components/common/Loading/types';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import type { SwitchUserContextInterface } from '@aurora/shared-client/components/context/SwitchUserContext/SwitchUserContext';
import SwitchUserContext from '@aurora/shared-client/components/context/SwitchUserContext/SwitchUserContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { isMainBranch } from '@aurora/shared-utils/helpers/developer/SwitchBranchHelper';
import { clearBranchCookie } from '@aurora/shared-utils/helpers/developer/SwitchBranchCookieHelper';
import usePageLoadingGlobalState from '@aurora/shared-client/helpers/ui/PageLoadingGlobalState';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useState } from 'react';

const SwitchUserFloatingBox = dynamic(
  () => import('../../users/SwitchUserFloatingBox/SwitchUserFloatingBox'),
  { ssr: false }
);

const SwitchUserModal = dynamic(() => import('../../users/SwitchUserModal/SwitchUserModal'), {
  ssr: false
});

const loadingVariant: LoadingVariantTypeAndProps = {
  type: LoadingVariant.DOT,
  props: {
    size: LoadingSize.LG,
    spacing: LoadingSpacing.XL
  }
};

interface Props {
  /**
   * The id of the switched user.
   */
  switchUserIdInit: number | null;
}

/**
 * Provides a context for switching users.
 *
 * @author Adam Ayres
 */
const SwitchUserContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  switchUserIdInit,
  children
}) => {
  const [showSwitchUserModal, setShowSwitchUserModal] = useState(false);
  const [pageLoading] = usePageLoadingGlobalState('pageLoading');
  const { branchName } = useContext(SwitchBranchContext);
  const tenant = useContext(TenantContext);
  const isEnabled = isMainBranch(tenant, branchName);
  const [switchUserId, setSwitchUserId] = useState<number>(switchUserIdInit);

  const context: SwitchUserContextInterface = {
    switchUserId: isEnabled ? switchUserId : null,
    showSwitchUserModal: setShowSwitchUserModal,
    clearSwitchUserId: () => setSwitchUserId(null)
  };

  useEffect(() => {
    if (switchUserIdInit !== switchUserId) {
      setSwitchUserId(switchUserIdInit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchUserIdInit]);

  if (pageLoading) {
    return <Loading variant={loadingVariant} />;
  }

  return (
    <SwitchUserContext.Provider value={context}>
      <SwitchUserModal
        show={showSwitchUserModal}
        onHide={() => setShowSwitchUserModal(false)}
        onSetSwitchUser={userId => {
          clearBranchCookie();
          setSwitchUserIdToCookie(userId);
          window.location.reload();
        }}
      />
      {isEnabled && switchUserId && (
        <SwitchUserFloatingBox
          onSwitchToAnotherUser={() => setShowSwitchUserModal(true)}
          onSwitchBackToMe={() => {
            clearSwitchUserIdCookie();
            window.location.reload();
          }}
        />
      )}
      {children}
    </SwitchUserContext.Provider>
  );
};

export default SwitchUserContextProvider;
