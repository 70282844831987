import type { ValidationRule } from 'react-hook-form';

/**
 * Extract the value out of a validation rule.
 * Prevents repetitive logic in FormFieldErrorFeedback component,
 * and allows access to validation rules for CustomValidation
 * Does not work with Regexp types
 */
export function getValidationRuleValue<TValue extends string | number | boolean>(
  validationRule: TValue | ValidationRule<TValue>
): TValue {
  return typeof validationRule === 'object' ? validationRule.value : validationRule;
}
