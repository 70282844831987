import type { I18n } from '@aurora/shared-types/texts';
import type { FormEvent } from 'react';
import React, { useMemo } from 'react';
import { Col, Form, useClassNameMapper } from 'react-bootstrap';
import type {
  ControllerRenderProps,
  FieldValues,
  UseControllerReturn,
  UseFormRegisterReturn,
  UseFormReturn
} from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { isFieldVisible } from '../../../helpers/form/FormHelper/FormHelper';
import InfoPopover from '../../common/InfoPopover/InfoPopover';
import type { FormFieldVariant } from '../enums';
import type {
  AllValidationRules,
  DebounceProps,
  FormFieldSpecDefinition,
  FormFieldType
} from '../types';
import { FieldType } from '../types';
import { useFormWatch } from '../useFormWatch';
import localStyles from './FormField.module.pcss';
import FormFieldErrorFeedback from './FormFieldErrorFeedback';
import { useSubmitOnChange } from './useSubmitOnChange';

interface FieldProps<NameT extends FieldPath<DataT>, DataT extends FieldValues> {
  /**
   * name of the field.
   */
  name: NameT;
  /**
   * validation rules for the field.
   */
  validations: AllValidationRules<DataT, NameT>;
  /**
   * form methods.
   */
  formMethods: UseFormReturn<DataT>;

  /**
   * form submit on change spec.
   */
  submitOnChange?: DebounceProps<DataT>;

  /**
   * @callback called when submit on change is triggered.
   */
  onFormSubmit: () => void;
}

/**
 * Controlled field props
 */
interface ControlledFieldProps<NameT extends FieldPath<DataT>, DataT extends FieldValues>
  extends FieldProps<NameT, DataT> {
  /**
   * FC that returns the controlled field content that is to be rendered.
   */
  children: React.FC<
    React.PropsWithChildren<{
      /**
       * render props for controlled field.
       */
      render: UseControllerReturn<DataT, FieldPath<DataT>>;
    }>
  >;
}

/**
 * Uncontrolled field props
 */
interface UncontrolledFieldProps<NameT extends FieldPath<DataT>, DataT extends FieldValues>
  extends FieldProps<NameT, DataT> {
  /**
   * FC that returns the uncontrolled field content that is to be rendered.
   */
  children: React.FC<
    React.PropsWithChildren<{
      /**
       * render props for the uncontrolled field.
       */
      render: {
        /**
         * callback to register the uncontrolled field using ref.
         */
        register: Pick<UseFormRegisterReturn<NameT>, 'ref' | 'onBlur'>;
        /**
         * on change event handler that has to be called everytime a value is changed.
         * @param event event that triggered the onChange.
         */
        onChange: (event: FormEvent<HTMLElement>) => void;
      };
    }>
  >;
}

/**
 * Renders controlled field for the form. {@see https://reactjs.org/docs/forms.html#controlled-components} for the
 * definition of controlled components. ControlledField uses react-hook-form Controller to render the field.
 * @param props controlled field props.
 */
const ControlledField = <NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  props: ControlledFieldProps<NameT, DataT>
): React.ReactElement => {
  const {
    name,
    validations,
    formMethods: { control },
    children,
    submitOnChange,
    onFormSubmit
  } = props;

  const onValueChange = useSubmitOnChange(name, submitOnChange, onFormSubmit);

  return (
    <Controller<DataT, NameT>
      control={control}
      name={name}
      rules={validations}
      render={({ field, fieldState, formState }) => {
        const adjustedField: ControllerRenderProps<DataT, NameT> = { ...field };
        if (submitOnChange) {
          adjustedField.onChange = value => {
            field.onChange(value);
            onValueChange(value);
          };
        }

        const adjustedRenderProps: UseControllerReturn<DataT, FieldPath<DataT>> = {
          field: adjustedField as unknown as ControllerRenderProps<DataT, FieldPath<DataT>>,
          fieldState,
          formState
        };

        return <>{children({ render: adjustedRenderProps })}</>;
      }}
    />
  );
};

/**
 * Renders uncontrolled field for the form. {@see https://reactjs.org/docs/uncontrolled-components.html#:~:text=In%20a%20controlled%20component%2C%20form,form%20values%20from%20the%20DOM.}
 * for the definition of uncontrolled components. UnControlled field uses react hook form register to register the field.
 * @param props uncontrolled field props
 */
const UncontrolledField = <NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  props: UncontrolledFieldProps<NameT, DataT>
): React.ReactElement => {
  const {
    validations,
    children,
    formMethods: { register, getValues },
    submitOnChange,
    onFormSubmit,
    name
  } = props;
  const registerReturn: UseFormRegisterReturn<NameT> = useMemo(() => {
    return register(name, validations);
  }, [name, register, validations]);
  const onValueChange = useSubmitOnChange<NameT, DataT>(name, submitOnChange, onFormSubmit);

  const { onChange, onBlur, ref } = registerReturn;
  return (
    <>
      {children({
        render: {
          register: { onBlur, ref },
          onChange: event => {
            onChange(event);
            if (submitOnChange) {
              onValueChange(getValues(name) as unknown as DataT[NameT]);
            }
          }
        }
      })}
    </>
  );
};

/**
 * Specifies field content type based on the field type.
 */
export type FieldContent<
  FieldT extends FieldType,
  DataT extends FieldValues,
  NameT extends FieldPath<DataT>
> = FieldT extends FieldType.CONTROLLED
  ? React.FC<React.PropsWithChildren<{ render: UseControllerReturn<DataT, FieldPath<DataT>> }>>
  : FieldT extends FieldType.UNCONTROLLED
  ? React.FC<
      React.PropsWithChildren<{
        render: {
          /**
           * callback to register the uncontrolled field using ref.
           */
          register: Pick<UseFormRegisterReturn<NameT>, 'ref' | 'onBlur'>;
          /**
           * on change event handler that has to be called everytime a value is changed.
           * @param event event that triggered the onChange.
           */
          onChange: (event: FormEvent<HTMLElement>) => void;
        };
      }>
    >
  : never;

/**
 * Renders the children for the form field. the children that is rendered is either a controlled
 * field or an uncontrolled field.
 * @param name name of the field.
 * @param validations validations for the field.
 * @param fieldType field type. Controlled or Uncontrolled.
 * @param formMethods react hook form methods.
 * @param field the field content to render.
 * @param onFormSubmit form submit handler.
 * @param submitOnChange submit on change handler.
 */
function renderChildren<
  FieldTypeT extends FieldType,
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
>(
  name: NameT,
  validations: AllValidationRules<DataT, NameT>,
  fieldType: FieldTypeT,
  formMethods: UseFormReturn<DataT>,
  field: FieldContent<FieldTypeT, DataT, NameT>,
  onFormSubmit: () => void,
  submitOnChange: DebounceProps<DataT>
): React.ReactElement {
  if (fieldType === FieldType.CONTROLLED) {
    return (
      <ControlledField
        name={name}
        validations={validations}
        formMethods={formMethods}
        submitOnChange={submitOnChange}
        onFormSubmit={onFormSubmit}
      >
        {field as FieldContent<FieldType.CONTROLLED, DataT, NameT>}
      </ControlledField>
    );
  } else if (fieldType === FieldType.UNCONTROLLED) {
    return (
      <UncontrolledField
        name={name}
        formMethods={formMethods}
        validations={validations}
        submitOnChange={submitOnChange}
        onFormSubmit={onFormSubmit}
      >
        {field as FieldContent<FieldType.UNCONTROLLED, DataT, NameT>}
      </UncontrolledField>
    );
  } else {
    throw new Error(`unsupported field type specified. Field Type: ${fieldType}`);
  }
}

interface Props<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues,
  FieldTypeT extends FieldType
> {
  /**
   * The element that has the control that renders in the form field.
   */
  children: FieldContent<FieldTypeT, DataT, NameT>;

  /**
   * Specifies details about the fields and how it should render.
   */
  fieldSpec: FormFieldType<NameT, DataT, FormFieldVariant>;

  /**
   * type of field.
   */
  fieldType: FieldTypeT;

  /**
   * Id of the form.
   */
  formId: string;

  /**
   * i18n to use for localized text.
   */
  i18n: I18n<unknown, unknown>;

  /**
   * Classname to apply to the form field.
   */
  className?: string;

  /**
   * Form methods provided by react hook form.
   */
  formMethods: UseFormReturn<DataT>;

  /**
   * Show error messages for the form field. Disable this only when the form field itself handles displaying errors
   */
  showErrorMessages?: boolean;

  /**
   * Overrides the validation specified in fieldSpec. fieldSpec validation will be ignored.
   */
  validationOverrides?: AllValidationRules<DataT, NameT>;

  /**
   * Form submit handler.
   */
  onFormSubmit: () => void;

  /**
   * Form submit on change spec.
   */
  submitOnChange: DebounceProps<DataT>;

  /**
   * All fields of the form
   */
  allFormFields: Array<FormFieldSpecDefinition<DataT>>;
}

/**
 * Renders the form field of a form
 *
 * @author Manish Shrestha
 */

const FormField = <
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues,
  FieldT extends FieldType
>({
  children,
  fieldSpec,
  formId,
  i18n,
  className,
  formMethods,
  fieldType,
  showErrorMessages = true,
  validationOverrides,
  submitOnChange,
  onFormSubmit,
  allFormFields
}: Props<NameT, DataT, FieldT>): React.ReactElement => {
  const { control } = formMethods;
  const { hasMessage, formatMessage, loading: textLoading } = i18n;
  const cx = useClassNameMapper(localStyles);

  const {
    validations,
    name,
    errorClassName,
    popoverClassName,
    popoverComponent,
    showInfo,
    isVisible
  } = fieldSpec;

  const { watchFields } = isVisible || {};

  const watchedValuesObject: Partial<DataT> = useFormWatch(watchFields, control);

  if (
    !isFieldVisible<DataT>(
      fieldSpec as unknown as FormFieldSpecDefinition<DataT>,
      watchedValuesObject,
      allFormFields
    )
  ) {
    return null;
  }

  const fieldName = name.toString();

  const controlId = `${formId}-${fieldName}`;
  const infoTextKey = `${formId}.${name}.info`;
  const usePopover = showInfo !== false && hasMessage(infoTextKey);

  const {
    as,
    label,
    description,
    controlCol,
    props,
    className: formGroupClassName,
    descriptionClassName
  } = fieldSpec?.formGroupSpec || {};

  function renderLabel(): React.ReactElement {
    if (label === false) {
      return null;
    }

    const Popover = popoverComponent || InfoPopover;
    const {
      as: labelAs,
      props: labelProps,
      className: labelClassName,
      labelTextOverride
    } = label || {};

    return (
      <>
        <Form.Label
          as={labelAs}
          className={cx(labelClassName, 'lia-label')}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...labelProps}
        >
          {labelTextOverride ?? formatMessage(`${formId}.${name}.label`)}
        </Form.Label>
        {usePopover && (
          <Popover className={cx(popoverClassName)} infoText={formatMessage(infoTextKey)} />
        )}
      </>
    );
  }

  function renderHelpText(): React.ReactElement {
    const helpTextValue =
      description &&
      (description === true ? formatMessage(`${formId}.${name}.description`) : description);
    return helpTextValue && <Form.Text className={descriptionClassName}>{helpTextValue}</Form.Text>;
  }

  if (textLoading) {
    return null;
  }

  return (
    <Form.Group
      as={as}
      className={cx(className, formGroupClassName)}
      controlId={controlId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {renderLabel()}
      {renderHelpText()}
      {controlCol ? (
        <Col
          as={controlCol?.as as React.ElementType}
          lg={controlCol?.lg}
          md={controlCol?.md}
          sm={controlCol?.sm}
          xs={controlCol?.xs}
          xl={controlCol?.xl}
        >
          {renderChildren(
            name,
            validationOverrides || validations,
            fieldType,
            formMethods,
            children,
            onFormSubmit,
            submitOnChange
          )}
          {showErrorMessages && (
            <FormFieldErrorFeedback
              errorClassName={errorClassName}
              fieldName={name}
              validations={validations}
              formMethods={formMethods}
              formId={formId}
              i18n={i18n}
            />
          )}
        </Col>
      ) : (
        <>
          {renderChildren(
            name,
            validationOverrides || validations,
            fieldType,
            formMethods,
            children,
            onFormSubmit,
            submitOnChange
          )}
          {showErrorMessages && (
            <FormFieldErrorFeedback
              errorClassName={errorClassName}
              fieldName={name}
              validations={validations}
              formMethods={formMethods}
              formId={formId}
              i18n={i18n}
            />
          )}
        </>
      )}
    </Form.Group>
  );
};

export default FormField;
