import Routes from '@aurora/shared-utils/helpers/urls/NextRoutes/Routes';
import type { RouterAndLink } from './useCustomRouter';
import useCustomRouter from './useCustomRouter';
import buildCustomLink from './buildCustomLink';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { AdminPages, AdminQueryParams } from './adminRoutes';

/**
 * Gets a router and Link outside the context of our end user or admin app.
 * This is likely only ever used in tests.
 *
 * @author Adam Ayres
 */
function useNoAppRoutes<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
>(): RouterAndLink<RouteType, UrlQueryParamType> {
  const routes = new Routes<RouteType>();
  const router = useCustomRouter(routes);
  return { router, Link: buildCustomLink(routes) };
}

export default useNoAppRoutes;
