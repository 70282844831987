import communityLanguagePropertiesQuery from '@aurora/shared-client/components/community/CommunityLanguageProperties.query.graphql';
import localizedCategoriesByLocaleQuery from '@aurora/shared-client/components/community/LocalizedCategoriesByLocale.query.graphql';
import useLocalizedCategoriesFeatureEnabled from '@aurora/shared-client/components/community/useLocalizedCategoriesFeatureEnabled';
import IntlWrapperContext from '@aurora/shared-client/components/context/IntlContext/IntlWrapperContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { RouteWithQuery } from '@aurora/shared-client/routes/useCustomRouter';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  CommunityLanguagePropertiesQuery,
  CommunityLanguagePropertiesQueryVariables,
  LocalizedCategoriesByLocaleQuery,
  LocalizedCategoriesByLocaleQueryVariables,
  LocalizedCategoryFragment
} from '@aurora/shared-generated/types/graphql-types';
import {
  EndUserPages,
  EndUserPathParams,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import { AccessFunctionType } from '@aurora/shared-types/redirects/pageRedirect';
import IntlHelper from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';

const log = getLog(module);

const routeDataMap: Record<
  | AccessFunctionType.LOCALIZED_CATEGORIES_PAGE
  | AccessFunctionType.GROUPS_PAGE
  | AccessFunctionType.CREATE_GROUP_HUB_PAGE,
  {
    route: EndUserPages.CategoryPage | EndUserPages.GroupHubsPage | EndUserPages.CreateGroupHubPage;
    useParams: boolean;
    useQuery: boolean;
  }
> = {
  [AccessFunctionType.LOCALIZED_CATEGORIES_PAGE]: {
    route: EndUserPages.CategoryPage,
    useParams: true,
    useQuery: false
  },
  [AccessFunctionType.GROUPS_PAGE]: {
    route: EndUserPages.GroupHubsPage,
    useParams: false,
    useQuery: true
  },
  [AccessFunctionType.CREATE_GROUP_HUB_PAGE]: {
    route: EndUserPages.CreateGroupHubPage,
    useParams: false,
    useQuery: true
  }
};

/**
 * Custom hook used to get the route data for category page where the user should be redirected from community page
 * based on the current browser language.
 *
 * Community page redirects to the associated category for browser language list or default community language
 * in case no categories are associated to another preferred language.
 *
 * @param accessFunctionType the access function type
 *
 */
export default function useLocalizedCategoriesAccess(accessFunctionType: AccessFunctionType): {
  routeData: RouteWithQuery<
    EndUserPages.CategoryPage | EndUserPages.GroupHubsPage | EndUserPages.CreateGroupHubPage,
    EndUserQueryParams
  >;
  isLocalizedCategoriesAccessReady: boolean;
  isLocalizedCategoriesFeatureEnabled: boolean;
} {
  const { locale, acceptLanguage } = useContext(IntlWrapperContext);
  const { router } = useEndUserRoutes();
  const categoryId = router.getUnwrappedQueryParam(EndUserQueryParams.CATEGORY_ID);
  const actionFeedback = router.getUnwrappedQueryParam(EndUserQueryParams.ACTION_FEEDBACK);
  const timestamp = router.getUnwrappedQueryParam(EndUserQueryParams.TIMESTAMP);

  const isLocalizedCategoriesPage: boolean =
    accessFunctionType === AccessFunctionType.LOCALIZED_CATEGORIES_PAGE ||
    accessFunctionType === AccessFunctionType.GROUPS_PAGE ||
    accessFunctionType === AccessFunctionType.CREATE_GROUP_HUB_PAGE;

  const { enabled: localizedCategoriesFeatureEnabled, loading: localizedCategoriesFeatureLoading } =
    useLocalizedCategoriesFeatureEnabled(module);

  const {
    data: communityLanguageData,
    loading: communityLanguageLoading,
    error: communityLanguageError
  } = useQueryWithTracing<
    CommunityLanguagePropertiesQuery,
    CommunityLanguagePropertiesQueryVariables
  >(module, communityLanguagePropertiesQuery, {
    fetchPolicy: 'cache-first',
    skip:
      !isLocalizedCategoriesPage ||
      localizedCategoriesFeatureLoading ||
      !localizedCategoriesFeatureEnabled
  });

  const {
    data: localizedCategoriesData,
    loading: localizedCategoriesLoading,
    error: localizedCategoriesError
  } = useQueryWithTracing<
    LocalizedCategoriesByLocaleQuery,
    LocalizedCategoriesByLocaleQueryVariables
  >(module, localizedCategoriesByLocaleQuery, {
    fetchPolicy: 'cache-first',
    skip:
      !isLocalizedCategoriesPage ||
      localizedCategoriesFeatureLoading ||
      !localizedCategoriesFeatureEnabled
  });

  if (communityLanguageLoading || localizedCategoriesLoading || localizedCategoriesFeatureLoading) {
    return {
      isLocalizedCategoriesAccessReady: false,
      isLocalizedCategoriesFeatureEnabled: false,
      routeData: null
    };
  }

  if (communityLanguageError) {
    log.error(communityLanguageError.message);
  }

  if (localizedCategoriesError) {
    log.error(localizedCategoriesError.message);
  }

  if (
    ((accessFunctionType === AccessFunctionType.CREATE_GROUP_HUB_PAGE ||
      accessFunctionType === AccessFunctionType.GROUPS_PAGE) &&
      !!categoryId) ||
    !localizedCategoriesFeatureEnabled
  ) {
    return {
      isLocalizedCategoriesFeatureEnabled: localizedCategoriesFeatureEnabled,
      isLocalizedCategoriesAccessReady: true,
      routeData: null
    };
  }

  function getRouteData(categoryDisplayId: string) {
    const routeData = routeDataMap[accessFunctionType];
    return {
      route: routeData.route,
      params: routeData.useParams
        ? {
            [EndUserPathParams.CATEGORY_ID]: categoryDisplayId
          }
        : {},
      ...((actionFeedback || timestamp || routeData.useQuery) && {
        query: {
          ...(routeData.useQuery && {
            [EndUserQueryParams.CATEGORY_ID]: categoryDisplayId
          }),
          ...(actionFeedback && { [EndUserQueryParams.ACTION_FEEDBACK]: actionFeedback }),
          ...(timestamp && { [EndUserQueryParams.TIMESTAMP]: timestamp })
        }
      })
    };
  }

  if (localizedCategoriesData) {
    const localeToCategoryMap: Record<string, LocalizedCategoryFragment['category']> = {};
    localizedCategoriesData.localizedCategoriesByLocale
      .filter(data => !!data.category)
      .forEach(({ locale: categoryLocale, category }) => {
        try {
          const localeIntl = new Intl.Locale(categoryLocale);
          localeToCategoryMap[localeIntl.toLocaleString()] = category;
          // Adds a record for generic locale language with the first match if it is not specified. If specified, it will
          // be overridden
          if (!localeToCategoryMap[localeIntl.language]) {
            localeToCategoryMap[localeIntl.language] = category;
          }
        } catch (error) {
          log.error('Invalid specified locale', error);
        }
      });

    if (locale) {
      const localeIntl = new Intl.Locale(locale);
      const localeMappedCategory = localeToCategoryMap[localeIntl.toLocaleString()];
      if (localeMappedCategory) {
        return {
          routeData: getRouteData(localeMappedCategory.category.displayId),
          isLocalizedCategoriesAccessReady: true,
          isLocalizedCategoriesFeatureEnabled: true
        };
      }
    }

    const finalLanguages: Intl.Locale[] = IntlHelper.getOrderedLanguages(acceptLanguage);

    let contextLocalizedCategory: LocalizedCategoryFragment['category'] =
      localeToCategoryMap[communityLanguageData?.community.languageProperties.language];
    for (const language of finalLanguages) {
      const completeLocaleMappedCategory: LocalizedCategoryFragment['category'] =
        localeToCategoryMap[language.toLocaleString()];
      if (completeLocaleMappedCategory) {
        contextLocalizedCategory = completeLocaleMappedCategory;
        break;
      } else {
        const languageMappedCategory = localeToCategoryMap[language.language];
        if (languageMappedCategory) {
          contextLocalizedCategory = languageMappedCategory;
          break;
        }
      }
    }

    if (contextLocalizedCategory) {
      return {
        routeData: getRouteData(contextLocalizedCategory.category.displayId),
        isLocalizedCategoriesAccessReady: true,
        isLocalizedCategoriesFeatureEnabled: true
      };
    }
  }

  return {
    routeData: null,
    isLocalizedCategoriesAccessReady: true,
    isLocalizedCategoriesFeatureEnabled: true
  };
}
