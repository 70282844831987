import { EventsFeature } from './EventsFeature';
import { IdeasFeature } from './IdeasFeature';
import type { CommunityFeature } from './index';
import type { Feature } from './enums';
import { BlogsFeature } from './BlogsFeature';
import { CategoriesFeature } from './CategoriesFeature';
import { CommunitiesFeature } from './CommunitiesFeature';
import { FeaturedWidgetFeature } from './FeaturedWidgetFeature';
import { ForumsFeature } from './ForumsFeature';
import { GroupHubsFeature } from './GroupHubsFeature';
import { KudosFeature } from './KudosFeature';
import { MembershipsFeature } from './MembershipsFeature';
import { MessagesFeature } from './MessagesFeature';
import { NotesFeature } from './NotesFeature';
import { NotificationsFeature } from './NotificationsFeature';
import { SearchFeature } from './SearchFeature';
import { TagsFeature } from './TagsFeature';
import { TkbFeature } from './TkbFeature';
import { UsersFeature } from './UsersFeature';
import { GuidesFeature } from './GuidesFeature';

/**
 * List that acts as a registry of features in our system
 */
const featureRegistry: CommunityFeature<Feature>[] = [
  new BlogsFeature(),
  new CategoriesFeature(),
  new CommunitiesFeature(),
  new FeaturedWidgetFeature(),
  new ForumsFeature(),
  new GroupHubsFeature(),
  new KudosFeature(),
  new MembershipsFeature(),
  new MessagesFeature(),
  new NotesFeature(),
  new NotificationsFeature(),
  new SearchFeature(),
  new TkbFeature(),
  new UsersFeature(),
  new TagsFeature(),
  new IdeasFeature(),
  new EventsFeature(),
  new GuidesFeature()
];

export default featureRegistry;
