import Head from 'next/head';
import React, { useContext } from 'react';
import { generateFontFaceCSS } from '../../../helpers/fonts/FontCssGenerator';
import AppContext from '../../context/AppContext/AppContext';
import SwitchBranchContext from '../../context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '../../context/TenantContext';
import type FontLoaderProps from './FontLoaderProps';

/**
 * The base FontLoader that accepts a prop.
 * This will load all the fonts
 * @author Rosalyn Rowe
 */
const FontLoader: React.FC<React.PropsWithChildren<FontLoaderProps>> = ({ fonts }) => {
  const tenant = useContext(TenantContext);
  const { themeInfo } = useContext(AppContext);
  const { branchName } = useContext(SwitchBranchContext);

  // if the theme fails to load, bail out so the error page can render
  if (!themeInfo) {
    return null;
  }
  return (
    <Head>
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaceCSS(fonts, tenant, themeInfo.id, branchName)
        }}
      />
    </Head>
  );
};

export default FontLoader;
