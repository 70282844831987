import { getMimeTypeForExtension } from './MimeTypeHelper';

async function getImagePreviewHref(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result.toString());
    });

    reader.addEventListener('error', event => {
      reject(event);
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  });
}

interface FileExtensionsWithMimeTypes {
  /**
   * Allowed file types for image upload.
   */
  allowedFileTypes: string[];
  /**
   * Allowed file types for image upload separated by a comma.
   */
  allowedFileTypesFormatted: string;
  /**
   * Allowed mime types for the legal extensions.
   */
  allowedMimeTypes: string[];
  /**
   * Allowed mime types for the legal extensions separated by a comma.
   */
  allowedMimeTypesFormatted: string;
  /**
   * Set of file extensions and mime types expected to be used in the
   * `accepts` attribute of a file input.
   */
  fileAccepts: string;
}

/**
 * Look for mime type directive and get mime types for file types from file extension result.
 * @param fileExtensions
 * @param fileMimeTypes
 * @param defaultFileExtensions
 * @param defaultMimeTypes
 */
function formatFileExtensions(
  fileExtensions: string[] | null | undefined,
  fileMimeTypes: string[] | null | undefined,
  defaultFileExtensions?: string[],
  defaultMimeTypes?: string[]
): FileExtensionsWithMimeTypes {
  const allowedFileTypes: string[] = fileExtensions ?? defaultFileExtensions ?? [];
  const allowedFileTypesFormatted = allowedFileTypes
    .map(fileType => fileType.replace('*.', ''))
    .join(', ');
  const allowedMimeTypes = fileMimeTypes ?? defaultMimeTypes ?? [];
  const fileAcceptsSet = new Set(allowedMimeTypes);

  allowedFileTypes
    .map(fileType => fileType.replace('*.', ''))
    .forEach(allowedFileType => {
      fileAcceptsSet.add(getMimeTypeForExtension(`*.${allowedFileType}`) ?? `.${allowedFileType}`);
    });

  const allowedMimeTypesFormatted = allowedMimeTypes.join(',');
  const fileAccepts = [...fileAcceptsSet].join(',');
  return {
    allowedFileTypes,
    allowedFileTypesFormatted,
    allowedMimeTypes,
    allowedMimeTypesFormatted,
    fileAccepts
  };
}

/** converts an array of file extensions into a readable format */
function transformFileExtensionsForDisplay(extensions: string[], locale: string): string {
  const set = new Set<string>();

  // Remove duplicates and get all capitalized versions
  extensions?.forEach(value => {
    let transformedValue = value.toUpperCase();

    // Remove wildcards
    if (transformedValue.startsWith('*.')) {
      transformedValue = transformedValue.slice(2);
    }

    set.add(transformedValue);
  });

  // Convert back to array
  const values = [...set.values()];

  const formatter = new Intl.ListFormat(locale ?? 'en', { type: 'disjunction' });

  return formatter.format(values);
}

/**
 * Extracts the file extension from a given URL.
 *
 * @param {string} url - The URL from which to extract the file extension.
 * @returns {string | null} The file extension in lowercase, or null if no extension is found.
 *
 */
function getFileExtension(url: string): string | null {
  const parts = url.split(/[#?]/);
  const filenameWithExtension = parts[0].split('/').pop();
  const extension = filenameWithExtension?.includes('.')
    ? filenameWithExtension.split('.').pop()
    : null;
  return extension ? extension.toLowerCase() : null;
}

export {
  getImagePreviewHref,
  formatFileExtensions,
  transformFileExtensionsForDisplay,
  getFileExtension
};
