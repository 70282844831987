import { ButtonVariant } from '../../components/common/Button/enums';

export enum ButtonShape {
  SQUARE = 'square',
  ROUNDED = 'rounded',
  PILL = 'pill'
}

export const ButtonShapeOptions = {
  SQUARE_BORDER_RADIUS: '0',
  PILL_BORDER_RADIUS: '100vw'
};

/* Returns the border radius style value based on button shape */
function renderBorderRadiusStyles(borderRadius: string, shape: ButtonShape): string {
  if (shape === ButtonShape.PILL) {
    return ButtonShapeOptions.PILL_BORDER_RADIUS;
  } else if (shape === ButtonShape.SQUARE) {
    return ButtonShapeOptions.SQUARE_BORDER_RADIUS;
  } else {
    return borderRadius;
  }
}

// Switches between filled and outline button style
function getButtonVariant(buttonVariant: ButtonVariant): string | null {
  if (buttonVariant === ButtonVariant.NO_VARIANT || buttonVariant === ButtonVariant.UNSTYLED) {
    return null;
  }
  return buttonVariant;
}

export { renderBorderRadiusStyles, getButtonVariant };
