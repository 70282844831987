import React from 'react';

export interface ProvisionalText {
  /**
   * Text key to provide the text provisionally
   */
  textKey: string;
  /**
   * Provisioned value for the text key
   */
  value: string;
  /**
   * The current value for the text key.
   */
  originalValue: string;
}

export enum ProvisionTextComponentContext {
  /**
   * text override is for page
   */
  PAGE,
  /**
   * text override is for widget
   */
  WIDGET,
  /**
   * text override is for hook
   */
  HOOK
}
export interface ProvisionalTextData {
  /**
   * instance id of the component
   */
  instanceId?: string;
  /**
   * id of the component
   */
  id: string;
  /**
   * Texts provisioned for the component
   */
  texts: ProvisionalText[];

  /**
   * Context in which the text is edited
   */
  context: ProvisionTextComponentContext;
}

/**
 * Provides context to set text provisionally for components in page.
 * Does not persist the text changes by itself and will lose text changes upon the destruction of the context.
 * Should be used for cases when on demand text changes are to be reflected in the UI.
 */
export interface ProvisionalTextContextInterface {
  /**
   * Collection of texts to set provisionally for components.
   */
  texts?: ProvisionalTextData[];
}

const ProvisionalTextContext = React.createContext<ProvisionalTextContextInterface>({
  texts: []
});

export default ProvisionalTextContext;
