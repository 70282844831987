import type { PrivateConversationType } from '@aurora/shared-types/notes/enums';
import React from 'react';
import type { Board, PostMessageType } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  MessageBasicFieldsFragment,
  NodeBasicFieldsFragment,
  UserRegistrationDataFragment
} from '@aurora/shared-generated/types/graphql-types';
import type { UserScope, WidgetPageContext } from '../../types/enums';

export enum TextVariantType {
  BOARD = 'board',
  NODE = 'node',
  MESSAGE = 'message',
  PRINCIPAL_USER = 'authUser',
  POST_MESSAGE_TYPE = 'postMessageType',
  PAGE_CONTEXT = 'pageContext',
  PRIVATE_CONVERSATION_TYPE = 'privateConversationType',
  CUSTOM_CONTENT_TYPE = 'customContentType',
  INSTANCE = 'instance',
  /**
   * Temporarily provisioned variant
   */
  PROVISIONAL = 'provisional',
  USER_SCOPE = 'userScope'
}

/**
 * Stores the specificity order for text variants. The smaller the number, the specific the variant is and will be
 * used. The numbers right are assigned based on following pattern:
 * The variant types that are in 100 range are more specific and will be prioritized before the one that are in 200 range.
 * The difference in between the value of the two different variant types exists so that any new variant type that has
 * a specificity in between can be introduced with ease.
 *
 */
export const textVariantSpecificityOrder: Record<TextVariantType, number> = {
  provisional: 0,
  instance: 100,
  postMessageType: 110,
  privateConversationType: 120,
  customContentType: 130,
  pageContext: 140,
  authUser: 200,
  message: 210,
  board: 220,
  node: 230,
  userScope: 240
};

export interface TextVariantsContextInterface {
  /**
   * The current board, should contain the `conversationStyle` property. Creates the
   * text variant: `board:${board.conversationStyle}.`
   */
  [TextVariantType.BOARD]?: Pick<Board, 'conversationStyle'>;
  /**
   * The current node, should contain the `nodeType` property. Creates the text variant:
   * `node:${contextNode.nodeType}`.
   */
  [TextVariantType.NODE]?: NodeBasicFieldsFragment;
  /**
   * The current message, should contain the `depth` property. Creates the text variant:
   * `message:root` or `message:reply`.
   */
  [TextVariantType.MESSAGE]?: MessageBasicFieldsFragment;
  /**
   * The principal user, should contain the `.registrationData.status` property. Creates
   * the text variant: `authUser:anonymous` or `authUser:registered`. Currently
   * partially-registered users are classified as `authUser:registered`.
   */
  [TextVariantType.PRINCIPAL_USER]?: UserRegistrationDataFragment;
  /**
   * The current post message type, this represents the type of message being edited in a
   * message editor. Creates the text variant: `postMessageType:post`, `postMessageType:edit`,
   * or `postMessageType:reply`. Please note the value of the `PostMessageType` enum is lowercased,
   * despite the enum having all uppercase values.
   */
  [TextVariantType.POST_MESSAGE_TYPE]?: PostMessageType;
  /**
   * The current page context, this represents the type of page on which the widget is being added.
   * Creates text variant: `pageContext:node`, `pageContext:user`.
   */
  [TextVariantType.PAGE_CONTEXT]?: WidgetPageContext;
  /**
   * The current private conversation type. Creates the text variant: `privateConversationType:one_to_one_thread`,
   * `privateConversationType:one_to_many_thread`, `privateConversationType:many_to_many_thread`.
   */
  [TextVariantType.PRIVATE_CONVERSATION_TYPE]?: PrivateConversationType;

  /**
   * The unique instance of the component
   */
  [TextVariantType.INSTANCE]?: string;

  /**
   * The current user scope.
   * Creates text variant: `userScope:other`, `userScope:self`.
   */
  [TextVariantType.USER_SCOPE]?: UserScope;
}

/**
 * Context for providing text variants. Text variants provide a way to augment the text that is
 * displayed based on the context of the rendering component without having to write conditional logic
 * inside of the component. Default text variants are provided by context from the page, such as the
 * current node, message, and principal user. Component scoped text variants can be added by wrapping
 * a component in a `TextVariantContext` provider, see `MessageView` and `NodeView` as examples.
 *
 * The text variant context is ultimately used by `useTranslation` to build up a text key that contains
 * the text variant key/value pairs. The text key with the most variants that match the context will be used,
 * or the base key if no text keys with variants are found or matched.
 *
 * @author Adam Ayres
 */
export default React.createContext<TextVariantsContextInterface>({});
