import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import type { TextVariantsContextInterface } from '@aurora/shared-client/components/context/TextVariantContext';
import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import { useI18n } from '@aurora/shared-client/components/useI18n';
import useText from '@aurora/shared-client/components/useText';
import type { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { I18n } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import { getModulePathForComponent } from '../features/endUserModuleRegistry';

const log = getLog(module);

export default function useTranslation<T, R>(
  componentId: string,
  ignoreProvisionedText?: boolean,
  /**
   * Override the text variant context
   */
  textVariantContextOverride?: TextVariantsContextInterface,
  customPageId?: string
): I18n<T, R> {
  const { pageId } = useContext(PageContext);
  const namespace = getModulePathForComponent(componentId, customPageId ?? pageId);
  const { textLastModified: lastModified } = useContext(AppContext);
  const { instanceId } = useContext(WidgetContext);

  const { loading, data, error: queryError, refetch } = useText(namespace, lastModified);

  const i18n = useI18n(
    namespace,
    loading ? {} : data,
    instanceId,
    ignoreProvisionedText,
    (id: EndUserComponent) => getModulePathForComponent(id),
    textVariantContextOverride,
    componentId
  );

  if (queryError) {
    log.error(queryError, 'Error loading text for namespaces: %s', namespace);
  }

  return {
    loading,
    refetch,
    ...i18n
  };
}
