import type { ComponentType, ErrorInfo, ReactNode } from 'react';
import React, { Component } from 'react';

export interface FallbackComponentProps {
  /**
   * The error
   */
  error?: Error;
}

interface Props {
  /**
   * Fallback component that will be rendered if an error has occurred
   */
  fallbackComponent: ComponentType<React.PropsWithChildren<FallbackComponentProps>>;
  /**
   * Children component
   */
  children: ReactNode;
}

interface State {
  /**
   * Boolean flag which indicates that an error was caught
   */
  hasError: boolean;

  /**
   * The error
   */
  error: Error;
}

/**
 * Component responsible to catch errors in the components below them in the tree
 * @author Andre Almeida
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const { fallbackComponent: FallbackComponent } = this.props;
      return <FallbackComponent error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
