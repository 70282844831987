enum FontFamilies {
  HELVETICA = "'Segoe UI', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif",
  TIMES_NEW_ROMAN = "'Times New Roman', Times, Baskerville, Georgia, serif",
  ATKINSON = 'Atkinson Hyperlegible',
  KARLA = 'Karla',
  INTER = 'Inter',
  KHAND = 'Khand',
  KRUB = 'Krub',
  MONTSERRAT = 'Montserrat',
  MONTSERRAT_ALTERNATES = 'Montserrat Alternates',
  NOTO_SANS = 'Noto Sans',
  OPEN_SANS = 'Open Sans',
  OSWALD = 'Oswald',
  PT_SANS = 'PT Sans',
  QUICKSAND = 'Quicksand',
  RALEWAY = 'Raleway',
  ROBOTO = 'Roboto',
  ROSARIO = 'Rosario',
  RUBIK = 'Rubik',
  WORK_SANS = 'Work Sans',
  BASKERVILLE = 'Baskerville',
  BITTER = 'Bitter',
  CORMORANT = 'Cormorant',
  CRIMSON_PRO = 'Crimson Pro',
  GENTIUM_BOOK_BASIC = 'Gentium Book Basic',
  NOTO_SERIF = 'Noto Serif',
  PLAYFAIR_DISPLAY = 'Playfair Display',
  QUATTROCENTO = 'Quattrocento',
  ROBOTO_SLAB = 'Roboto Slab',
  VOLLKORN = 'Vollkorn',
  ZILLA_SLAB = 'Zilla Slab'
}

export default FontFamilies;
