import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { isBranchSwitchingEnabled } from '@aurora/shared-utils/helpers/developer/SwitchBranchHelper';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';

export interface Props {
  branchName: string;
}

const SwitchBranchContextProviderMain = dynamic<React.PropsWithChildren<Props>>(
  () => import('./SwitchBranchContextProviderMain')
);

/**
 * Allows user to switch branches.
 *
 * @author Dolan Halbrook
 */
const SwitchBranchContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  branchName,
  children
}) => {
  const tenant = useContext(TenantContext);
  if (!isBranchSwitchingEnabled(tenant)) {
    return <>{children}</>;
  }

  return (
    <SwitchBranchContextProviderMain branchName={branchName}>
      {children}
    </SwitchBranchContextProviderMain>
  );
};

export default SwitchBranchContextProvider;
