import type { MutationHookOptions, MutationTuple, TypedDocumentNode } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import { getOperationName } from '@apollo/client/utilities';
import { filterNullInClauseVariables } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type { DocumentNode } from 'graphql';
import useTracing from './useTracing';
import type { ApolloQueryContext } from '@aurora/shared-types/apollo/tracing';
import { useContext } from 'react';
import SwitchUserContext from './context/SwitchUserContext/SwitchUserContext';
import { getParentFrameId } from './context/AnalyticsParentFrames/useFrameEnd';

/**
 * Wrapper around `useMutation` that adds tracking info to the `context` property.
 *
 * @param module the module of the component
 * @param mutation the mutation
 * @param options the query options
 * @param switchUserIdOverride allows for an override of the switched user id
 * @author Adam Ayres
 */
export default function useMutationWithTracing<TData = unknown, TVariables = OperationVariables>(
  module: NodeModule | string,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
  switchUserIdOverride?: number
): MutationTuple<TData, TVariables> {
  const operationDefinition: string = getOperationName(mutation);
  const tracingContext: ApolloQueryContext = useTracing<
    TVariables,
    MutationHookOptions<TData, TVariables>
  >(module, operationDefinition, options);
  const { switchUserId } = useContext(SwitchUserContext);
  const parentFrameId = getParentFrameId();
  const context: ApolloQueryContext = {
    ...tracingContext,
    parentFrameId,
    switchUserId: switchUserIdOverride ?? switchUserId
  };
  if (options?.variables) {
    filterNullInClauseVariables(options.variables as Record<string, unknown>);
  }
  return useMutation<TData, TVariables>(mutation, { ...options, context });
}
