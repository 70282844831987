import { createContext } from 'react';

interface RedirectContextInterface {
  /**
   * The path to redirect to
   *
   * @param redirectUrl the redirect URL
   * @param statusCode the redirect status code
   */
  setRedirect(redirectUrl: string | null, statusCode: number | null): void;
}

/**
 * A React context that can be used to set a redirect in a nested component via
 * the `useIsomorphicRedirect` hook.
 *
 * @author Adam Ayres
 */
const RedirectContext = createContext<RedirectContextInterface>({
  setRedirect() {}
});

export default RedirectContext;
