/**
 * Whether the value is a CSS variable.
 *
 * @param value the value to check
 */
import { canUseDOM } from 'exenv';

/**
 * Whether the value is a CSS variable.
 *
 * @param value the value to check
 */
export default function isCssVariable(value: string): boolean {
  return value.startsWith('--');
}

/**
 * Checks if CSS variable is wrapped.
 *
 * @param value
 * @returns
 */
export function isWrappedCssVariable(value: string): boolean {
  return value.startsWith('var(--');
}

/**
 * Unwraps CSS variable removing the 'var(...)'.
 *
 * @param value
 * @returns
 */
export function unwrapCssVariable(value: string): string {
  const match = value.match(/var\(([\d\s,a-z-]+)\)/);
  if (match) {
    return match[1];
  }
  return value;
}

export function cssVar(
  cssVariable: string,
  defaultValue?: string | number,
  element: Element = canUseDOM ? document.documentElement : null
): string | number | undefined {
  let variableValue: string;

  if (canUseDOM && element !== null) {
    variableValue = getComputedStyle(element).getPropertyValue(cssVariable);
  }

  return variableValue ? variableValue.trim() : defaultValue;
}

const cssRegex = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/;

/**
 * Returns a given CSS value minus its unit of measure.
 *
 * @example
 * // Styles as object usage
 * const styles = {
 *   '--dimension': stripUnit('100px')
 * }
 *
 * // styled-components usage
 * const div = styled.div`
 *   --dimension: ${stripUnit('100px')};
 * `
 *
 * // CSS in JS Output
 *
 * element {
 *   '--dimension': 100
 * }
 */
export function stripUnit(value: string | number): string | number {
  if (typeof value !== 'string') {
    return value;
  }
  const matchedValue = value.match(cssRegex);
  return matchedValue ? Number.parseFloat(value) : value;
}

/**
 * Extracts the CSS variable name from the value.
 *
 * @param cssVariable
 * @param defaultValue
 * @param element
 * @returns
 */
export function extractCssVariable(
  cssVariable: string,
  defaultValue: string | number,
  element: Element = canUseDOM ? document.documentElement : null
): string | number {
  let cssVariableToUse = cssVariable;

  if (isWrappedCssVariable(cssVariable)) {
    cssVariableToUse = cssVariableToUse.slice(4, cssVariable.length - 1);
  }

  return cssVar(cssVariableToUse, defaultValue, element);
}
