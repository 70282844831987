import { getLog } from '@aurora/shared-utils/log';
import React from 'react';
import { LoadingVariant } from '../../../common/Loading/enums';
import Loading from '../../../common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '../../../common/Loading/types';
import type { ThemeContextInterface } from '../ThemeContext';
import ThemeContext from '../ThemeContext';
import { LoadingSize, LoadingSpacing } from '../../../../types/enums';
import useCachedTheme from '../../../themes/useCachedTheme';
import PageError from '../../../error/PageError/PageError';

const log = getLog(module);

interface Props {
  /**
   * The children to pass through for display.
   */
  children?: React.ReactNode;

  /**
   * The id of the theme to load from cache, or load the default theme if missing or null.
   */
  id?: string | null;
}

/**
 * Puts a cached theme into the Theme context.
 *
 * @constructor
 *
 * @author Dolan Halbrook
 */
const CachedThemeContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  id = null,
  children
}) => {
  const { loading: themeLoading, data: themeData, error: themeError } = useCachedTheme(id);

  const loadingVariant: LoadingVariantTypeAndProps = {
    type: LoadingVariant.DOT,
    props: {
      size: LoadingSize.LG,
      spacing: LoadingSpacing.XL
    }
  };

  // if in loading state, bail out
  if (!themeData && themeLoading) {
    return <Loading variant={loadingVariant} />;
  }

  if (themeError) {
    log.error(themeError, 'Error loading theme.');
    return <PageError statusCode={500} />;
  }

  const context: ThemeContextInterface = {
    theme: themeData ?? null
  };

  log.trace('ThemeContext is %O', context);

  return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
};

export default CachedThemeContextProvider;
