import type {
  AuthProvidersQuery,
  AuthProvidersQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import {
  getMultiAuthRedirectUrl,
  getSsoRedirectUrl,
  redirectToMultiAuthClient,
  redirectToSsoClient
} from '@aurora/shared-utils/helpers/anonymousUserActions/AnonymousUserActionsHelper';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type { RouteWithOptions } from '../routes/useCustomRouter';
import { AuthFlow } from '../types/enums';
import AuthFlowContext from './context/AuthFlowContext/AuthFlowContext';
import TenantContext from './context/TenantContext';
import type { AuthProvider } from '@aurora/shared-generated/types/graphql-schema-types';
import { AuthMechanism } from '@aurora/shared-generated/types/graphql-schema-types';
import authProvidersQuery from './authentication/AuthProviders.query.graphql';
import useQueryWithTracing from './useQueryWithTracing';
import type { BannerFormAlertProps } from './common/BannerFormAlert/BannerFormAlert';
import useCommunitySsoProperties from './community/useCommunitySsoProperties';
import useEndUserRoutes from '../routes/useEndUserRoutes';
import type { PageAndEmptyParams } from '../routes/endUserRoutes';

const log = getLog(module);

export interface AuthProviderInfo {
  /**
   * Local/Native auth provider
   */
  localAuthProvider?: AuthProvider;
  /**
   * All the enabled auth provider for a community
   */
  enabledAuthProvider?: AuthProvider[];
  /**
   * All avaialble auth provider for a community
   */
  availableAuthProvider?: AuthProvider[];
}

/**
 * Hook for managing authentication flows.
 * @author Nicolas Pascual
 */
export default function useAuthFlow() {
  const { showAuthenticationModal } = useContext(AuthFlowContext);
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;
  const ssoPropertiesQueryResult = useCommunitySsoProperties(module);
  const authProvidersQueryResult = useQueryWithTracing<
    AuthProvidersQuery,
    AuthProvidersQueryVariables
  >(module, authProvidersQuery, {
    skip: !multiAuthEnabled,
    ssr: false
  });
  const { router } = useEndUserRoutes();

  /**
   * Initiates the authentication flow based on SSO and SAML properties.
   *
   */
  async function triggerAuthFlow(
    callback: (ssoEnabled: boolean, samlEnabled: boolean) => void,
    type: AuthFlow,
    routeAndParams: RouteWithOptions<EndUserPages, EndUserQueryParams> = null,
    refererPath?: string,
    bannerFormAlertProps?: BannerFormAlertProps
  ): Promise<void> {
    const { data, loading: ssoPropertiesLoading } = ssoPropertiesQueryResult;
    const { data: authProvidersData, loading: authProvidersLoading } = authProvidersQueryResult;
    if (ssoPropertiesLoading || authProvidersLoading) {
      return null;
    }
    const { samlProperties, ssoProperties } = data?.community ?? {};
    const ssoEnabled = checkPolicy(ssoProperties.ssoEnabled);
    const samlEnabled = checkPolicy(samlProperties.samlEnabled);
    const authProviders = authProvidersData?.authProviders.filter(
      authProvider => authProvider.enabled
    );
    const [localAuthProvider, ...rest] = authProvidersData?.authProviders ?? [];
    const authProviderInfo = {
      localAuthProvider: localAuthProvider ?? null,
      availableAuthProvider: [...rest] ?? [],
      enabledAuthProvider: authProviders ?? []
    };
    if (
      multiAuthEnabled &&
      authProviders.length === 1 &&
      authProviders[0].authMechanism !== AuthMechanism.Local
    ) {
      redirectToMultiAuthClient(authProviders[0], tenant, refererPath, ssoProperties?.ssoQsParam);
    } else if (!multiAuthEnabled && ssoEnabled) {
      redirectToSsoClient(data.community, tenant, refererPath, type === AuthFlow.REGISTRATION);
    } else {
      if (routeAndParams) {
        showAuthenticationModal(type, routeAndParams, authProviderInfo, bannerFormAlertProps);
      } else {
        showAuthenticationModal(type, null, authProviderInfo, bannerFormAlertProps);
      }
    }
    callback(ssoEnabled, samlEnabled);
  }

  /**
   * Fetches the SSO authentication URL based on the provided path.
   * @param {string} path - The path to redirect after authentication.
   * @param {boolean} useRegisterUrl - Flag indicating whether to use the registration URL.
   **/
  function getSsoAuthUrl(path: string, useRegisterUrl: boolean = false): string {
    const {
      data: ssoPropertiesData,
      loading: ssoPropertiesLoading,
      error: ssoPropertiesError
    } = ssoPropertiesQueryResult;
    const {
      data: authProvidersData,
      loading: authProvidersLoading,
      error: authProvidersError
    } = authProvidersQueryResult;

    if (ssoPropertiesError) {
      log.error(ssoPropertiesError, 'Error fetching SSO properties');
      return '';
    }

    if (authProvidersError) {
      log.error(authProvidersError, 'Error fetching auth providers');
      return '';
    }

    if (ssoPropertiesLoading || authProvidersLoading) {
      return '';
    }

    const authProviders = authProvidersData?.authProviders.filter(
      authProvider => authProvider.enabled
    );

    if (multiAuthEnabled) {
      if (authProviders.length === 1 && authProviders[0].authMechanism !== AuthMechanism.Local) {
        return getMultiAuthRedirectUrl(
          authProviders[0],
          tenant,
          path,
          ssoPropertiesData.community?.ssoProperties?.ssoQsParam
        );
      } else {
        return router.getRelativeUrlForRoute<PageAndEmptyParams>(
          EndUserPages.LoginPage,
          {},
          {
            [EndUserQueryParams.NEXT_PAGE]: path
          }
        );
      }
    }

    if (!multiAuthEnabled && checkPolicy(ssoPropertiesData.community?.ssoProperties.ssoEnabled)) {
      return getSsoRedirectUrl(ssoPropertiesData.community, tenant, path, useRegisterUrl);
    }

    return '';
  }

  return { triggerAuthFlow, getSsoAuthUrl };
}
