import type {
  Board,
  CoreNode,
  Message,
  PostMessageType,
  User
} from '@aurora/shared-generated/types/graphql-schema-types';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import type { PrivateConversationType } from '@aurora/shared-types/notes/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { useContext } from 'react';
import type { MessageDescriptor } from 'react-intl';
import AppContext from './context/AppContext/AppContext';
import ProvisionalTextContext from './context/ProvisionalTextContext/ProvisionalTextContext';
import TenantContext from './context/TenantContext';
import type { TextVariantsContextInterface } from './context/TextVariantContext';
import TextVariantContext, {
  textVariantSpecificityOrder,
  TextVariantType
} from './context/TextVariantContext';
import type { FormatMessageKey } from '@aurora/shared-types/texts';
import type { CustomContentType } from '../types/enums';
import { UserScope, WidgetPageContext } from '../types/enums';

export default function useMessageDescriptor(
  namespace: string,
  instanceId?: string,
  ignoreProvisionedText?: boolean,
  /**
   * Override the text variant context
   */
  textVariantContextOverride?: TextVariantsContextInterface
): (key: string | MessageDescriptor, bundle: Record<string, string>) => MessageDescriptor {
  const tenant = useContext(TenantContext);
  const { contextNode, contextMessage, authUser, contextUser } = useContext(AppContext);
  const textVariantFromContext = useContext(TextVariantContext);
  const textVariantContext = textVariantContextOverride ?? textVariantFromContext;
  const { texts } = useContext(ProvisionalTextContext) || {};

  function getBaseKeyWithoutVariants(key: string): string {
    return key.split('@')[0];
  }

  /**
   * Builds the set of text variants from the `AuroraContext` (node, message, and user),
   * and any scoped context set using the `TextVariantContext`. Text variants set on the
   * `TextVariantContext` will take precedent.
   */
  function buildContextVariants(): Set<string> {
    // Use a map so that writes to the same key will override existing values
    // We then convert back to a set of string variants by encoding the key:value
    // into a single string.
    const variants: Map<string, string> = new Map();
    const contextBoard = contextNode as Board;

    function addNodeVariant(node: Pick<CoreNode, 'nodeType'>): void {
      if (node?.nodeType) {
        variants.set(TextVariantType.NODE, node.nodeType);
      }
    }

    function addBoardVariant(board: Pick<Board, 'conversationStyle'>): void {
      if (board?.conversationStyle) {
        variants.set(TextVariantType.BOARD, board.conversationStyle);
      }
    }

    function addMessageVariant(message: Pick<Message, 'depth'>): void {
      if (message?.depth != null) {
        const messageType = message?.depth === 0 ? 'root' : 'reply';
        variants.set(TextVariantType.MESSAGE, messageType);
      }
    }

    function addPrincipalUserVariant(user: Pick<User, 'registrationData'>): void {
      const status = user?.registrationData?.status ?? RegistrationStatus.Anonymous;
      const isAnonymous = status === RegistrationStatus.Anonymous;
      variants.set(TextVariantType.PRINCIPAL_USER, isAnonymous ? 'anonymous' : 'registered');
    }

    function addPostMessageTypeVariant(postMessageType: PostMessageType): void {
      if (postMessageType) {
        variants.set(TextVariantType.POST_MESSAGE_TYPE, postMessageType.toLowerCase());
      }
    }

    function addWidgetPageContextVariant(widgetPageContext: WidgetPageContext): void {
      variants.set(TextVariantType.PAGE_CONTEXT, widgetPageContext);
    }

    function addPrivateConversationTypeVariant(conversationType: PrivateConversationType): void {
      variants.set(TextVariantType.PRIVATE_CONVERSATION_TYPE, conversationType.toLowerCase());
    }

    function addUserScopeTypeVariant(userScope: UserScope): void {
      variants.set(TextVariantType.USER_SCOPE, userScope.toLowerCase());
    }

    function addCustomContentTypeVariant(customContentType: CustomContentType): void {
      variants.set(TextVariantType.CUSTOM_CONTENT_TYPE, customContentType.toLowerCase());
    }

    function addInstanceVariant(componentInstanceId: string): void {
      if (componentInstanceId) {
        variants.set(TextVariantType.INSTANCE, componentInstanceId);
      }
    }

    function addProvisionalVariant(id: string): void {
      if (id) {
        variants.set(TextVariantType.PROVISIONAL, id);
      }
    }

    // Add variants from the global context
    addNodeVariant(contextNode);
    addBoardVariant(contextBoard);
    addMessageVariant(contextMessage);
    addPrincipalUserVariant(authUser as Pick<User, 'registrationData'>);
    addWidgetPageContextVariant(!contextUser ? WidgetPageContext.NODE : WidgetPageContext.USER);
    addUserScopeTypeVariant(!contextUser ? UserScope.OTHER : UserScope.SELF);
    addInstanceVariant(instanceId);
    if (!ignoreProvisionedText) {
      addProvisionalVariant(texts ? namespace : null);
    }

    // Add variants from a scoped context added via <TextVariantContext.Provider />
    // These will take precedence over the ones set globally
    Object.keys(textVariantContext).forEach(textVariantKey => {
      const textVariantType = getAsEnum(textVariantKey, TextVariantType);

      const value = textVariantContext[textVariantKey];
      switch (textVariantType) {
        case TextVariantType.INSTANCE: {
          addInstanceVariant(value);
          break;
        }
        case TextVariantType.BOARD: {
          addBoardVariant(value as Board);
          break;
        }
        case TextVariantType.NODE: {
          addNodeVariant(value as Pick<CoreNode, 'nodeType'>);
          break;
        }
        case TextVariantType.MESSAGE: {
          addMessageVariant(value as Pick<Message, 'depth'>);
          break;
        }
        case TextVariantType.PRINCIPAL_USER: {
          addPrincipalUserVariant(value as Pick<User, 'registrationData'>);
          break;
        }
        case TextVariantType.POST_MESSAGE_TYPE: {
          addPostMessageTypeVariant(value as PostMessageType);
          break;
        }
        case TextVariantType.PRIVATE_CONVERSATION_TYPE: {
          addPrivateConversationTypeVariant(value);
          break;
        }
        case TextVariantType.CUSTOM_CONTENT_TYPE: {
          addCustomContentTypeVariant(value);
          break;
        }
        case TextVariantType.USER_SCOPE: {
          addUserScopeTypeVariant(value);
          break;
        }
        default:
      }
    });

    return new Set([...variants.entries()].map(([key, value]) => `${key}:${value}`));
  }

  function getVariantsFromKey(key: string): Array<string> {
    return key
      .split('@')
      .splice(1)
      .sort((variantA, variantB) => {
        const [keyAVariantBase] = variantA.split(':');
        const [keyBVariantBase] = variantB.split(':');
        if (
          textVariantSpecificityOrder[keyAVariantBase] <
          textVariantSpecificityOrder[keyBVariantBase]
        ) {
          return -1;
        } else if (
          textVariantSpecificityOrder[keyAVariantBase] >
          textVariantSpecificityOrder[keyBVariantBase]
        ) {
          return 1;
        }
        return 0;
      });
  }

  /**
   * Orders the text key variants based on following rule:
   * 1. The variants of each key are sorted based on the specificity order.
   * 2. If the keys have equal number of variants, then the key having first variant that is more specific than another for the same index will be ahead of the other one.
   * 3. If the keys do not have equal number of variants, then rule 2 is still followed comparing variant upto the smaller sized variant. If the keys are not sorted by rule 2 then the key with higher number of variant is ordered before the lower number of variant.
   * @param keyA
   * @param keyB
   */
  function textKeyVariantSpecificityComparator(keyA: string, keyB: string): number {
    const keyBVariants: string[] = getVariantsFromKey(keyB);
    const keyAVariants: string[] = getVariantsFromKey(keyA);

    if (keyAVariants.length > keyBVariants.length) {
      for (const [idx, keyBVariant] of keyBVariants.entries()) {
        const [keyAVariantBase] = keyAVariants[idx].split(':');
        const [keyBVariantBase] = keyBVariant.split(':');
        const keyASpecificityAtIdx = textVariantSpecificityOrder[keyAVariantBase];
        const keyBSpecificityAtIdx = textVariantSpecificityOrder[keyBVariantBase];

        if (keyASpecificityAtIdx !== keyBSpecificityAtIdx) {
          return keyASpecificityAtIdx - keyBSpecificityAtIdx;
        }
      }
      return -1;
    } else if (keyAVariants.length < keyBVariants.length) {
      for (const [idx, keyAVariant] of keyAVariants.entries()) {
        const [keyBVariantBase] = keyBVariants[idx].split(':');
        const [keyAVariantBase] = keyAVariant.split(':');
        const keyASpecificityAtIdx = textVariantSpecificityOrder[keyAVariantBase];
        const keyBSpecificityAtIdx = textVariantSpecificityOrder[keyBVariantBase];
        if (keyASpecificityAtIdx !== keyBSpecificityAtIdx) {
          return keyASpecificityAtIdx - keyBSpecificityAtIdx;
        }
      }
      return 1;
    } else {
      for (const [idx, keyBVariant] of keyBVariants.entries()) {
        const [keyAVariantBase] = keyAVariants[idx].split(':');
        const [keyBVariantBase] = keyBVariant.split(':');
        const keyASpecificityAtIdx = textVariantSpecificityOrder[keyAVariantBase];
        const keyBSpecificityAtIdx = textVariantSpecificityOrder[keyBVariantBase];

        if (keyASpecificityAtIdx !== keyBSpecificityAtIdx) {
          return keyASpecificityAtIdx - keyBSpecificityAtIdx;
        }
      }
      return 0;
    }
  }

  /**
   * For a given text key matches the most specific text variant. If not
   * text variants are found then returns the value for the original text key.
   *
   * The text variants for a given key are found using the following approach:
   *
   * - For the specific text key, find all possible variants in the current message bundle
   * - For the text key variants found, sort by the ones that have the most specific variants at the top based on {@link textKeyVariantSpecificityComparator}
   * - Iterate through the text keys and find the first one that has all of it's text variant parts satisfied by
   * the current context
   *
   * @param key
   * @param bundle
   */
  function findTextVariant(key: FormatMessageKey, bundle: Record<string, string>): string {
    const keyId = (key as MessageDescriptor)?.id ?? `${key}`;
    const textKeyVariants = Object.keys(bundle).filter(currentKey => {
      return getBaseKeyWithoutVariants(currentKey) === keyId;
    });

    if (textKeyVariants.length > 0) {
      const contextVariants = buildContextVariants();
      const sortedVariants = textKeyVariants.sort(textKeyVariantSpecificityComparator);

      return (
        sortedVariants.find(textKey => {
          return getVariantsFromKey(textKey).every(variant => contextVariants.has(variant));
        }) ?? keyId
      );
    }
    return keyId as string;
  }

  function createMessageDescriptor(
    key: string | MessageDescriptor,
    bundle: Record<string, string>
  ): MessageDescriptor {
    if (typeof key === 'string') {
      const variantKey = findTextVariant(key, bundle);

      const globalKey = `${tenant?.id}:${namespace}/${variantKey}`;
      const defaultMessage = bundle?.[variantKey] ?? variantKey;
      return {
        id: globalKey,
        defaultMessage
      };
    } else {
      const variantKey = findTextVariant(`${key?.id}`, bundle);
      return {
        ...key,
        id: `${tenant?.id}:${namespace}/${variantKey}`
      };
    }
  }

  return createMessageDescriptor;
}
