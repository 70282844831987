import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  boardPageRedirectBehavior,
  editPageRedirectBehavior,
  messagePageRedirectBehavior,
  messageReplyPageRedirectBehavior,
  noRedirectBehavior,
  postPageRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for forums that holds the discussion style specific attributes
 * @author Manish Shrestha
 */
export class ForumsFeature implements CommunityFeature<Feature.FORUMS> {
  getFeature(): Feature.FORUMS {
    return Feature.FORUMS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.ForumBoardPage,
        endUserModuleRegistry[EndUserComponent.FORUM_BOARD_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForumBoardPage],
        getEntityScopeForPage(EntityType.FORUM),
        boardPageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      ),
      new PageDescriptor(
        EndUserPages.ForumMessagePage,
        endUserModuleRegistry[EndUserComponent.FORUM_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForumMessagePage],
        getEntityScopeForPage(EntityType.FORUM_TOPIC),
        messagePageRedirectBehavior,
        true,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.ForumReplyPage,
        endUserModuleRegistry[EndUserComponent.FORUM_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForumReplyPage],
        getEntityScopeForPage(EntityType.FORUM_REPLY),
        messageReplyPageRedirectBehavior,
        false,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.ForumPostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForumPostPage],
        getEntityScopeForPage(EntityType.FORUM),
        postPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.ForumEditPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForumEditPage],
        getEntityScopeForPage(EntityType.FORUM),
        editPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.ForumViewAllTopicsPage,
        endUserModuleRegistry[EndUserComponent.FORUM_VIEW_ALL_TOPICS_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForumViewAllTopicsPage],
        getEntityScopeForPage(EntityType.FORUM),
        noRedirectBehavior,
        false,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [];
  }
}
