/**
 * Features available in community
 */
export enum Feature {
  COMMUNITIES = 'communities',
  CATEGORIES = 'categories',
  FEATURED_WIDGET = 'featured-widget',
  FORUMS = 'forums',
  BLOGS = 'blogs',
  TKB = 'tkb',
  IDEAS = 'ideas',
  GROUP_HUBS = 'grouphubs',
  USERS = 'users',
  NOTES = 'notes',
  NOTIFICATIONS = 'notifications',
  CUSTOM_CONTENT_WIDGET = 'custom-content-widget',
  KUDOS = 'kudos',
  MEMBERSHIPS = 'memberships',
  MESSAGES = 'messages',
  SEARCH = 'search',
  TAGS = 'tagging',
  EVENTS = 'events',
  GUIDES = 'guides'
}
