import type { ApolloError } from '@apollo/client';
import React, { useId } from 'react';
import { SharedComponent } from '../../../enums';
import useTranslation from '../../useTranslation';
import SystemAlert from '../SystemAlert/SystemAlert';

interface Props {
  /**
   * The `ApolloError` to alert
   */
  error: ApolloError;
  /**
   * Force the alert to be shown, even in production
   */
  forceShow?: boolean;
}

/**
 * Display an Alert box for GraphQL errors.
 *
 * @constructor
 *
 * @author Dolan Halbrook
 */
const ApolloErrorAlert: React.FC<React.PropsWithChildren<Props>> = ({
  error,
  forceShow = false
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.APOLLO_ERROR_ALERT
  );
  const uid = useId();

  if (textLoading) {
    return null;
  }

  const show =
    error.graphQLErrors.length > 0 && (forceShow || process.env.NODE_ENV !== 'production');

  return (
    show && (
      <SystemAlert
        id={uid}
        title={formatMessage('title')}
        message={() => (
          <>
            <ul>
              {error.graphQLErrors.map((graphQLError, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${graphQLError.message}__${idx}`}>
                  {JSON.stringify(graphQLError, null, 2)}
                </li>
              ))}
            </ul>
            {error.extraInfo && <span>{error.extraInfo}</span>}
          </>
        )}
      />
    )
  );
};

export default ApolloErrorAlert;
