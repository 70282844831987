import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  casePortalPageRedirectBehavior,
  userRequiredRedirectBehavior,
  localizedCategoriesRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import type { NodeBannerProps } from '../components/community/NodeBannerWidget/useNodeBannerWidgetFinalProps';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for community that holds the node style specific attributes
 * @author Manish Shrestha
 */
export class CommunitiesFeature implements CommunityFeature<Feature.COMMUNITIES> {
  getFeature(): Feature.COMMUNITIES {
    return Feature.COMMUNITIES;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    const communityScope = getEntityScopeForPage(EntityType.COMMUNITY);
    return [
      new PageDescriptor<NodeBannerProps>(
        EndUserPages.CommunityPage,
        endUserModuleRegistry[EndUserComponent.COMMUNITY_PAGE],
        pageDescriptorUrlPath[EndUserPages.CommunityPage],
        communityScope,
        localizedCategoriesRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      ),
      new PageDescriptor(
        EndUserPages.PostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.PostPage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.TermsOfServicePage,
        endUserModuleRegistry[EndUserComponent.TERMS_OF_SERVICE_PAGE],
        pageDescriptorUrlPath[EndUserPages.TermsOfServicePage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.SearchResultsPage,
        endUserModuleRegistry[EndUserComponent.SEARCH_RESULTS_PAGE],
        pageDescriptorUrlPath[EndUserPages.SearchResultsPage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.ManageContentPage,
        endUserModuleRegistry[EndUserComponent.MANAGE_CONTENT_PAGE],
        pageDescriptorUrlPath[EndUserPages.ManageContentPage],
        communityScope,
        userRequiredRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.PageEditorPage,
        endUserModuleRegistry[EndUserComponent.PAGE_EDITOR_PAGE],
        pageDescriptorUrlPath[EndUserPages.PageEditorPage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.ThemeEditorPage,
        endUserModuleRegistry[EndUserComponent.THEME_EDITOR_PAGE],
        pageDescriptorUrlPath[EndUserPages.ThemeEditorPage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.HelpFAQPage,
        endUserModuleRegistry[EndUserComponent.HELP_FAQ_PAGE],
        pageDescriptorUrlPath[EndUserPages.HelpFAQPage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.CustomPage,
        endUserModuleRegistry[EndUserComponent.CUSTOM_PAGE],
        pageDescriptorUrlPath[EndUserPages.CustomPage],
        communityScope
      ),
      new PageDescriptor(
        EndUserPages.CasePortalPage,
        endUserModuleRegistry[EndUserComponent.CASE_PORTAL_PAGE],
        pageDescriptorUrlPath[EndUserPages.CasePortalPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        casePortalPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.CaseCreatePage,
        endUserModuleRegistry[EndUserComponent.CREATE_CASE_PAGE],
        pageDescriptorUrlPath[EndUserPages.CaseCreatePage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      ),
      new PageDescriptor(
        EndUserPages.CaseViewPage,
        endUserModuleRegistry[EndUserComponent.CASE_VIEW_PAGE],
        pageDescriptorUrlPath[EndUserPages.CaseViewPage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      ),
      new PageDescriptor(
        EndUserPages.CommunityGuideLinesPage,
        endUserModuleRegistry[EndUserComponent.COMMUNITY_GUIDELINES_PAGE],
        pageDescriptorUrlPath[EndUserPages.CommunityGuideLinesPage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      ),
      new PageDescriptor(
        EndUserPages.PrivacyPolicyPage,
        endUserModuleRegistry[EndUserComponent.PRIVACY_POLICY_PAGE],
        pageDescriptorUrlPath[EndUserPages.PrivacyPolicyPage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.TERMS_OF_SERVICE_WIDGET,
        component: dynamic(
          () => import('../components/termsofservice/TermsOfServiceWidget/TermsOfServiceWidget')
        )
      },
      {
        id: EndUserComponent.PLACEHOLDER_WIDGET,
        component: dynamic(() => import('../components/common/PlaceholderWidget/PlaceholderWidget'))
      },
      {
        id: EndUserComponent.FOOTER_WIDGET,
        component: dynamic(() => import('../components/community/FooterWidget/FooterWidget'))
      },
      {
        id: EndUserComponent.NODE_INFORMATION_WIDGET,
        component: dynamic(
          () => import('../components/nodes/NodeInformationWidget/NodeInformationWidget')
        ),
        editor: {
          form: dynamic(
            () =>
              import('../components/nodes/NodeInformationWidgetEditor/NodeInformationWidgetEditor')
          )
        },
        allowedPages: [
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.BlogBoardPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.TkbBoardPage,
          EndUserPages.BlogMessagePage,
          EndUserPages.ForumMessagePage,
          EndUserPages.TkbMessagePage,
          EndUserPages.IdeaBoardPage,
          EndUserPages.IdeaMessagePage,
          EndUserPages.EventBoardPage
        ]
      },
      {
        id: EndUserComponent.NODE_PAGE_HEADER_WIDGET,
        component: dynamic(
          () => import('../components/nodes/NodePageHeaderWidget/NodePageHeaderWidget')
        )
      },
      {
        id: EndUserComponent.PLACES_WIDGET,
        component: dynamic(() => import('../components/places/PlacesWidget/PlacesWidget')),
        editor: {
          form: dynamic(() => import('../components/places/PlacesWidgetEditor/PlacesWidgetEditor'))
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.UserPage,
          EndUserPages.GroupHubPage
        ]
      },
      {
        id: EndUserComponent.MANAGE_CONTENT_DASHBOARD_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/managecontent/ManageContentDashboardWidget/ManageContentDashboardWidget'
            )
        )
      },
      {
        id: EndUserComponent.HELP_FAQ_WIDGET,
        component: dynamic(() => import('../components/help/HelpFAQWidget/HelpFAQWidget'))
      },
      {
        id: EndUserComponent.HEADER_WIDGET,
        component: dynamic(() => import('../components/common/HeaderWidget/HeaderWidget'))
      },
      {
        id: EndUserComponent.CUSTOM_COMPONENT_WIDGET,
        component: dynamic(
          () => import('../components/customComponent/CustomComponent/CustomComponent')
        ),
        editor: {
          form: dynamic(
            () =>
              import('../components/customComponent/CustomComponentEditor/CustomComponentEditor')
          ),
          quiltWrapperForm: dynamic(
            () =>
              import(
                '../components/customComponent/QuiltWrapperCustomComponentEditor/QuiltWrapperCustomComponentEditor'
              )
          )
        }
      },
      {
        id: EndUserComponent.NAVBAR_WIDGET,
        component: dynamic(() => import('../components/community/Navbar/NavbarWidget')),
        editor: {
          form: null,
          quiltWrapperForm: dynamic(
            () =>
              import(
                '../components/pageeditor/QuiltWrapperNavbarWidgetEditor/QuiltWrapperNavbarWidgetEditor'
              )
          )
        }
      },
      {
        id: EndUserComponent.BANNER_WIDGET,
        component: dynamic(() => import('../components/community/BannerWidget/BannerWidget')),
        widgetLocation: 'wrapper',
        editor: {
          form: dynamic(
            () =>
              import('../components/community/NodeBannerWidgetEditor/NodeBannerWidgetEditorForm')
          ),
          quiltWrapperForm: dynamic(
            () => import('../components/pageeditor/BannerWidgetEditorForm/BannerWidgetEditorForm')
          )
        }
      },
      {
        id: EndUserComponent.BREADCRUMB_WIDGET,
        component: dynamic(() => import('../components/community/Breadcrumb/BreadcrumbWidget')),
        editor: {
          form: null,
          quiltWrapperForm: dynamic(
            () =>
              import(
                '../components/pageeditor/BreadcrumbWidgetEditorForm/BreadcrumbWidgetEditorForm'
              )
          )
        }
      },
      {
        id: EndUserComponent.CASE_PORTAL_DASHBOARD_WIDGET,
        component: dynamic(
          () =>
            import('../components/salesforce/CasePortalDashboardWidget/CasePortalDashboardWidget')
        )
      },
      {
        id: EndUserComponent.CREATE_CASE_FORM,
        component: dynamic(() => import('../components/salesforce/CreateCaseForm/CreateCaseForm'))
      },
      {
        id: EndUserComponent.CASE_VIEW_WIDGET,
        component: dynamic(() => import('../components/salesforce/CaseViewWidget/CaseViewWidget'))
      },
      {
        id: EndUserComponent.NODE_ACTION_BUTTON_WIDGET,
        component: dynamic(
          () => import('../components/nodes/NodeActionButtonWidget/NodeActionButtonWidget')
        ),
        editor: {
          form: dynamic(
            () => import('../components/nodes/NodeActionButtonWidgetEditor/NodeActionButtonEditor')
          )
        },
        allowedPages: [
          EndUserPages.GroupHubPage,
          EndUserPages.BlogBoardPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.TkbBoardPage,
          EndUserPages.BlogMessagePage,
          EndUserPages.ForumMessagePage,
          EndUserPages.TkbMessagePage,
          EndUserPages.IdeaBoardPage,
          EndUserPages.IdeaMessagePage,
          EndUserPages.EventBoardPage
        ]
      }
    ];
  }
}
