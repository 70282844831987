import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class MediumStyle implements FontStyleDefinition {
  name = FontStyleName.MEDIUM;

  style = FontStyle.Normal;

  weight = 500;
}
