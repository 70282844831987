import type { BaseQueryOptions, Context } from '@apollo/client/react/types/types';
import type { ApiOriginOperation, ApiOriginWidget } from '@aurora/shared-types/apollo/tracing';
import { isRequestOriginEnabled } from '@aurora/shared-apollo/apolloTracingHelper';
import ModuleHelper from '@aurora/shared-utils/helpers/ModuleHelper/ModuleHelper';
import { useContext } from 'react';
import QuiltContext from './context/QuiltContext';
import SectionWidgetContext from './context/SectionWidgetContext';
import WidgetContext from './context/WidgetContext';

/**
 * Creates a context object to be used in the `useQuery` and `useMutation` Apollo
 * client hooks that adds information to the context about the current request.
 *
 * @param module the module
 * @param operationName Definition of the operation being made.
 * @param options the options for the request.
 */
export default function useTracing<TVariables, OptionsType extends BaseQueryOptions<TVariables>>(
  module: NodeModule | string,
  operationName: string,
  options?: OptionsType
): Context {
  const { context = {} } = options ?? {};
  const widgetContext = useContext(WidgetContext);
  const sectionWidgetContext = useContext(SectionWidgetContext);
  const { id } = widgetContext || {};
  const { location } = sectionWidgetContext || {};
  const quilt = useContext(QuiltContext);
  const componentPath = ModuleHelper.getNamespaceFromModule(module);
  let nestedPath;
  if (id !== componentPath) {
    nestedPath = componentPath;
  }

  const widget: ApiOriginWidget = widgetContext
    ? {
        path: id ?? null,
        section: location?.sectionId ?? null,
        column: location?.columnId ?? null,
        index: location?.widgetIdx ?? null
      }
    : null;

  const apiOriginOperation: ApiOriginOperation = {
    name: operationName ?? 'not-set (Aurora)',
    widget,
    nestedPath: nestedPath ?? null
  };

  return {
    ...context,
    // The `tracing` context property is consumed in the `apolloTrackingLink`.
    ...(isRequestOriginEnabled() && { apiOriginOperation }),
    quilt
  };
}
