import type {
  ConversationStyle,
  CoreNode,
  MessageConstraints
} from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import type { NodePageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { ContextNodeFragment } from '../../../types/graphql-types';
import ConversationStyleBehaviorHelper from '../../boards/ConversationStyleBehaviorHelper';
import type { NodeParentFragment } from '@aurora/shared-generated/types/graphql-types';

const log = getLog(module);

/**
 * Creates node-scoped constraints, intended for a message query, based on the specified node.
 *
 * @param node - The node to build the constraints for.*
 * @return The message constraints for the specified node.
 */
function buildMessagesConstraintsForNode(node: ContextNodeFragment): MessageConstraints {
  const constraints: MessageConstraints = {};

  if (node?.id) {
    switch (node.nodeType) {
      case NodeType.BOARD: {
        constraints.boardId = {
          eq: node.id
        };

        break;
      }
      case NodeType.CATEGORY: {
        constraints.categoryId = {
          eq: node.id
        };

        break;
      }
      case NodeType.GROUPHUB: {
        constraints.groupHubId = {
          eq: node.id
        };

        break;
      }
    }
  }
  return constraints;
}

/**
 * Parameters of a node required to build a node route
 */
export type NodeForRouteParams = Pick<CoreNode, 'displayId' | 'nodeType'> & {
  /**
   * The conversation style, this is only present on boards
   */
  conversationStyle?: ConversationStyle;
  /**
   * The parent node, this is required for building all node links except Community
   */
  parent?: NodeParentFragment['parent'];
};

/**
 * Get the route and params for the supplied node.
 *
 * @param node the node.
 */
function getNodeRouteAndParams(node: NodeForRouteParams): NodePageAndParams {
  if (!node) {
    log.error('No node specified, unable to create NodeLink');
    return { route: null, params: null };
  }

  const { displayId, nodeType } = node;

  const nodeTypeAsEnum = getAsEnum<typeof NodeType>(nodeType, NodeType);

  switch (nodeTypeAsEnum) {
    case NodeType.COMMUNITY: {
      return { route: EndUserPages.CommunityPage, params: null };
    }
    case NodeType.CATEGORY: {
      return {
        route: EndUserPages.CategoryPage,
        params: { categoryId: displayId }
      };
    }
    case NodeType.GROUPHUB: {
      return {
        route: EndUserPages.GroupHubPage,
        params: { groupHubId: displayId }
      };
    }
    case NodeType.BOARD: {
      const { parent } = node;

      if (parent && node.conversationStyle != null) {
        return {
          route: ConversationStyleBehaviorHelper.getInstance(node as Required<NodeForRouteParams>)
            .boardRoute,
          params: {
            categoryId: parent.displayId,
            boardId: node.displayId
          }
        };

        // TODO: figure out how to handle top level boards
      } else {
        log.error('Board entity has no parent needed to create a link: %O', node);
      }
      break;
    }
    default: {
      log.error('No node type specified for node, unable to create NodeLink: %O', node);
    }
  }
  return { route: null, params: null };
}

/**
 * A function that returns the Node types that can be created by a user depending on the permissions
 * @param data NodeDataObject
 */
function getSupportedNodeTypes(data: CoreNode) {
  const [nodeTypeKey] = Object.keys(data);
  const { supportedNodeTypes } = data[nodeTypeKey];

  const allowedNodeTypes = supportedNodeTypes
    .filter(supportedNodeType => checkPolicy(supportedNodeType.nodeTypePolicies.canCreate))
    .map(supportedNodeType => supportedNodeType.nodeType);

  return { allowedNodeTypes };
}

export { buildMessagesConstraintsForNode, getNodeRouteAndParams, getSupportedNodeTypes };
