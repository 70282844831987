import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

export class MembershipsFeature implements CommunityFeature<Feature.MEMBERSHIPS> {
  getFeature(): Feature.MEMBERSHIPS {
    return Feature.MEMBERSHIPS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.MEMBER_LIST_FOR_NODE_WIDGET,
        component: dynamic(
          () => import('../components/memberships/MemberListForNodeWidget/MemberListForNodeWidget')
        )
      },
      {
        id: EndUserComponent.MEMBERSHIP_REQUEST_TO_JOIN_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/memberships/MembershipRequestToJoinWidget/MembershipRequestToJoinWidget'
            )
        )
      },
      {
        id: EndUserComponent.USER_LIST_FOR_NODE_BY_JOIN_DATE_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/users/UserListForNodeByJoinDateWidget/UserListForNodeByJoinDateWidget'
            )
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/users/UserListForNodeByJoinDateWidgetEditor/UserListForNodeByJoinDateWidgetEditor'
              )
          )
        },
        allowedPages: [EndUserPages.GroupHubPage]
      },
      {
        id: EndUserComponent.MANAGE_MEMBERSHIP_FOR_NODE_TABBABLE_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/memberships/ManageMembershipForNodeTabbableWidget/ManageMembershipForNodeTabbableWidget'
            )
        )
      }
    ];
  }
}
