import type { FieldPath as DefaultFieldPath, FieldValues } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import type { FieldPathValues } from 'react-hook-form/dist/types';
import type { Control } from 'react-hook-form/dist/types/form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { normalizeWatchedValues } from '../../helpers/form/FormHelper/FormHelper';
import type { WatchFields } from './types';

/**
 * watches the value of the fields using `useWatch` from react-hook-form and normalizes the values returned from the
 * `useWatch` hook to return as Partial of form data. If no watch fields  is specified, an empty object is returned.
 * For the case when `useWatch` returns undefined values for watched values, the default values will be returned for the watched values.
 * `useWatch` will return undefined values if `shouldUnregister` is set to true in `useForm` and the form re-renders
 * before the watched field has mounted.
 * @param watchedFields watchedFields
 * @param control form control
 */
export function useFormWatch<FormDataT extends FieldValues>(
  watchedFields: WatchFields<FormDataT>,
  control: Control<FormDataT>
): Partial<FormDataT> {
  const watchedValues: FieldPathValues<FormDataT, FieldPath<FormDataT>[]> = useWatch<
    FormDataT,
    DefaultFieldPath<FormDataT>[]
  >({
    name:
      watchedFields === 'all'
        ? undefined
        : (watchedFields as unknown as DefaultFieldPath<FormDataT>[]),
    control,
    disabled: !watchedFields || watchedFields.length === 0
  }) as FieldPathValues<FormDataT, FieldPath<FormDataT>[]>;

  if (watchedFields) {
    const normalizedWatchedValues = normalizeWatchedValues<FormDataT>(watchedFields, watchedValues);

    if (watchedFields === 'all') {
      Object.entries(control._defaultValues).forEach(([key, value]) => {
        if (normalizedWatchedValues[key] === undefined) {
          normalizedWatchedValues[key as FieldPath<FormDataT>] = value;
        }
      });
    } else {
      watchedFields.forEach(field => {
        if (normalizedWatchedValues[field] === undefined) {
          normalizedWatchedValues[field] = control._defaultValues[
            field
          ] as FormDataT[FieldPath<FormDataT>];
        }
      });
    }
    return normalizedWatchedValues;
  }
  return {};
}
