import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class ExtraLightStyle implements FontStyleDefinition {
  name = FontStyleName.EXTRALIGHT;

  style = FontStyle.Normal;

  weight = 200;
}
