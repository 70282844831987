import type { SsoRegistrationContextInterface } from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';

import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '@aurora/shared-client/components/common/Loading/types';
import { LoadingVariant } from '@aurora/shared-client/components/common/Loading/enums';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';

const loadingVariant: LoadingVariantTypeAndProps = {
  type: LoadingVariant.DOT,
  props: {
    size: LoadingSize.LG,
    spacing: LoadingSpacing.XL
  }
};

const SsoRegistrationModal = dynamic(() => import('../SsoRegistrationModal/SsoRegistrationModal'));

/**
 * Provides a context for managing SSO registration modal.
 *
 * @author Nicolas Pascual
 */
const SsoRegistrationContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authUser } = useContext(AppContext);
  const [showSsoRegistrationModal, setShowSsoRegistrationModal] = useState(false);
  const { data: ssoPropsData, loading: ssoPropsLoading } = useCommunitySsoProperties(module);
  const [hasSsoModalBeenClosed, setHasSsoModalBeenClosed] = useLocalStorage<boolean>(
    `${LocalStorageKeys.SSO_REGISTRATION_MODAL}.${authUser.uid}`,
    false
  );
  const ssoEnabled = checkPolicy(ssoPropsData?.community?.ssoProperties?.ssoEnabled);
  const { registrationStatus } = useRegistrationStatus();
  const { router, loading: routesLoading } = useEndUserRoutes();

  // We auto-open the complete registration modal if sso is enabled, the user is
  // partially registered, is not the terms of service page, and if the user has not manually closed the modal in the past,
  // which is tracked using localStorage.
  useEffect(() => {
    const excludedPages = new Set([EndUserPages.TermsOfServicePage]);
    if (
      ssoEnabled &&
      registrationStatus === RegistrationStatus.PartiallyRegistered &&
      !hasSsoModalBeenClosed &&
      !excludedPages.has(router.getCurrentPageName())
    ) {
      setShowSsoRegistrationModal(true);
    }
  }, [authUser, registrationStatus, ssoEnabled, hasSsoModalBeenClosed, router]);

  const [onCompleteRegistrationCallback, setOnCompleteRegistrationCallback] =
    useState<() => void>(null);

  const context: SsoRegistrationContextInterface = {
    showSsoRegistrationModal: (show, onCompleteRegistration) => {
      setShowSsoRegistrationModal(show);
      if (onCompleteRegistration) {
        setOnCompleteRegistrationCallback(() => onCompleteRegistration);
      }
    }
  };

  if (ssoPropsLoading || routesLoading) {
    return <Loading variant={loadingVariant} />;
  }

  return (
    <SsoRegistrationContext.Provider value={context}>
      {showSsoRegistrationModal && (
        <SsoRegistrationModal
          show={showSsoRegistrationModal}
          onHide={() => {
            setShowSsoRegistrationModal(false);
            setHasSsoModalBeenClosed(true);
          }}
          onRegistration={onCompleteRegistrationCallback}
        />
      )}
      {children}
    </SsoRegistrationContext.Provider>
  );
};

export default SsoRegistrationContextProvider;
