import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class RegularStyle implements FontStyleDefinition {
  name = FontStyleName.REGULAR;

  style = FontStyle.Normal;

  weight = 400;
}
