import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { notificationsPageRedirectBehavior } from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for notifications that holds the notification specific attributes
 * @author Martin Sandoval
 */
export class NotificationsFeature implements CommunityFeature<Feature.NOTIFICATIONS> {
  getFeature(): Feature.NOTIFICATIONS {
    return Feature.NOTIFICATIONS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.NotificationPage,
        endUserModuleRegistry[EndUserComponent.NOTIFICATION_PAGE],
        pageDescriptorUrlPath[EndUserPages.NotificationPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        notificationsPageRedirectBehavior
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.NOTIFICATION_WIDGET,
        component: dynamic(
          () => import('../components/notifications/NotificationWidget/NotificationWidget')
        )
      }
    ];
  }
}
