import { AppType } from '@aurora/shared-types/app';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { FormatMessageKey, FormatMessageValues, I18n } from '@aurora/shared-types/texts';
import IntlHelper from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import { enumFromValue } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import TextHelper from '../i18n/TextHelper/TextHelper';
import AppTypeContext from './context/AppTypeContext';
import ProvisionalTextContext, {
  ProvisionTextComponentContext
} from './context/ProvisionalTextContext/ProvisionalTextContext';
import TenantContext from './context/TenantContext';
import type { TextVariantsContextInterface } from './context/TextVariantContext';
import ToggleTextKeysContext from './context/ToggleTextKeysContext/ToggleTextKeysContext';
import useMessageDescriptor from './useMessageDescriptor';

interface FormattedMessageProps {
  /**
   * The local text key to translate
   */
  id: string;
  /**
   * Key/values to be passed to the text key for translation
   */
  values: Record<string, React.ReactNode>;
  /**
   * Tag Name
   */
  tagName?: React.ElementType;

  /**
   *
   * @param nodes - child nodes to render
   */
  children?(...nodes: React.ReactNode[]): React.ReactElement | null;
}

const log = getLog(module);

const TOGGLE_TEXT_KEYS_FLOATING_BOX_MODULE_PATH = 'components/i18n/ToggleTextKeysFloatingBox';
/**
 * A set that contains the list of namespaces that ignore the showTextKeys state.
 */
const OVERRIDE_SHOW_TEXT_KEYS_SET = new Set([TOGGLE_TEXT_KEYS_FLOATING_BOX_MODULE_PATH]);

export function useI18n<T, R>(
  namespace: string,
  bundle: Record<string, string>,
  componentInstanceId?: string,
  ignoreProvisionedText?: boolean,
  componentIdToNamespaceResolver?: (component: EndUserComponent) => string,
  /**
   * Override the text variant context
   */
  textVariantContextOverride?: TextVariantsContextInterface,
  componentId?: string
): Omit<I18n<T, R>, 'loading' | 'refetch'> {
  const intl = useIntl();
  const tenant = useContext(TenantContext);
  const { texts } = useContext(ProvisionalTextContext) || {};
  const { showTextKeys } = useContext(ToggleTextKeysContext);
  const appType = useContext(AppTypeContext);
  const overrideShowTextKeys = OVERRIDE_SHOW_TEXT_KEYS_SET.has(namespace);
  const shouldDisplayTextKeys =
    showTextKeys && appType === AppType.END_USER && !overrideShowTextKeys;

  /**
   * Returns the provisioned text bundle for the current component based on {@link ProvisionalTextContext}
   */
  function getProvisionedBundle(): Record<string, string> {
    const provisionedTextBundle: Record<string, string> = {};
    if (!ignoreProvisionedText && texts) {
      const provisionalDataVariant = texts?.find(text => {
        const { instanceId, context, id } = text;
        const component: EndUserComponent = enumFromValue(EndUserComponent, id);
        if (component && componentIdToNamespaceResolver) {
          const componentNamespace = componentIdToNamespaceResolver(component);
          if (context === ProvisionTextComponentContext.PAGE) {
            return componentNamespace === namespace;
          }

          return componentInstanceId
            ? componentNamespace === namespace && componentInstanceId === instanceId
            : componentNamespace === namespace;
        }
        return false;
      });

      if (provisionalDataVariant) {
        const { instanceId, texts: provisionedTexts } = provisionalDataVariant;

        provisionedTexts.forEach(({ textKey, value }) => {
          if (instanceId) {
            provisionedTextBundle[`${textKey}@provisional:${namespace}@instance:${instanceId}`] =
              value;
          } else {
            provisionedTextBundle[`${textKey}@provisional:${namespace}`] = value;
          }
        });
      }
    }
    return provisionedTextBundle;
  }

  const provisionedTextBundle: Record<string, string> = getProvisionedBundle();

  const createMessageDescriptor = useMessageDescriptor(
    namespace,
    componentInstanceId,
    ignoreProvisionedText,
    textVariantContextOverride
  );

  function createI18nResponse(): Omit<I18n<T, R>, 'loading'> {
    const { messages } = intl;
    // assign namespaced and merged bundle to current intl object's messages
    Object.assign(messages, {
      ...TextHelper.namespaceTextBundleKeys(`${tenant?.id}:${namespace}`, {
        ...bundle,
        ...provisionedTextBundle
      })
    });

    const bundleAdjustedWithProvisionedText = { ...bundle, ...provisionedTextBundle };
    const FormattedMessageWrapper: React.FC<FormattedMessageProps> = ({
      id: key,
      values,
      tagName,
      children
    }) => {
      const {
        id: messageDescriptorId,
        defaultMessage,
        description
      }: MessageDescriptor = createMessageDescriptor(key, bundleAdjustedWithProvisionedText);

      const toggleKeyToDisplay = IntlHelper.getTextKeyToDisplay(
        {
          id: messageDescriptorId
        },
        componentId
      );

      return (
        <FormattedMessage
          id={shouldDisplayTextKeys ? 'displayingTextKey' : messageDescriptorId}
          defaultMessage={shouldDisplayTextKeys ? toggleKeyToDisplay : defaultMessage}
          description={description}
          values={values}
          tagName={tagName}
        >
          {children}
        </FormattedMessage>
      );
    };

    function hasMessage(key: string | MessageDescriptor): boolean {
      const messageDescriptor = createMessageDescriptor(key, bundleAdjustedWithProvisionedText);
      return messageDescriptor?.id in messages;
    }

    function formatMessage(
      key: FormatMessageKey,
      values?: FormatMessageValues,
      bundleOverride?: Record<string, string>
    ): string {
      if (bundleOverride) {
        // assign namespaced and merged bundle to current intl object's messages
        Object.assign(messages, {
          ...TextHelper.namespaceTextBundleKeys(`${tenant?.id}:${namespace}`, {
            ...bundleOverride
          })
        });
      }

      try {
        const messageDescriptor: MessageDescriptor = createMessageDescriptor(
          key,
          bundleOverride ?? bundleAdjustedWithProvisionedText
        );
        if (shouldDisplayTextKeys) {
          return IntlHelper.getTextKeyToDisplay(messageDescriptor, componentId);
        }
        return messageDescriptor.defaultMessage !== ''
          ? intl.formatMessage(messageDescriptor, values)
          : '';
      } catch (error) {
        log.error(error, 'Error formatting message with key %s and values %O', key, values);
        return '';
      }
    }

    function formatMessageWithFallback(
      key: FormatMessageKey,
      fallbackKey: FormatMessageKey,
      values?: FormatMessageValues
    ): string {
      const finalKey = hasMessage(key) ? key : fallbackKey;
      return formatMessage(finalKey, values);
    }

    return {
      intl,
      hasMessage,
      formatMessage,
      // TODO: fix the TS issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formatMessageAsNode: formatMessage,
      formatMessageWithFallback,
      FormattedMessage: FormattedMessageWrapper
    };
  }

  return createI18nResponse();
}
