export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  DARK = 'dark',
  LIGHT = 'light',
  LINK = 'link',
  UNSTYLED = 'unstyled',
  NO_VARIANT = 'noVariant'
}

export enum LoadingButtonVariant {
  LOADING_BUTTON = 'loadingButton',
  STANDARD = 'standard'
}

export enum ActionButtonVariant {
  TOOLBAR_BUTTON = 'toolbarButton',
  DROP_DOWN_BUTTON = 'dropDownButton',
  SUBJECT_MODAL_BUTTON = 'subjectModalButton'
}
