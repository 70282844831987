import versions from '@aurora/shared-generated/static-hashes.json';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { prefixStaticAsset } from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import IconTypes from './public/static/graphics/processed/enums';

const { graphics } = versions;
const hash = process.env.NODE_ENV === 'test' ? 'h4x' : graphics;

const basePath = prefixStaticAsset(`/static/graphics/processed/${hash}`);

function getIconPath(icon: IconTypes | string) {
  // If this icon is actually a provided icon (Khoros controls), we can go ahead and build the url for it...
  const isOurIcon = getAsEnum(icon, IconTypes, null) !== null;

  if (isOurIcon) {
    return `${basePath}/${icon}.svg#${icon}`;
  } else {
    // Assume the icon is a third-party link and return it as-is
    return icon;
  }
}

// Re-export prevents needing to go thru every file and import the enum directly...
export { default } from './public/static/graphics/processed/enums';

export { getIconPath };
