import type { CommunityFeature } from './index';
import type { Feature } from './enums';
import type { EntityType } from '@aurora/shared-types/nodes/enums';
import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserPages, EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import { enumFromValue } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import featureRegistry from './featureRegistry';
import type {
  CommunityFeature as ExternalCommunityFeature,
  WidgetDescriptor as ExternalWidgetDescriptor
} from '@aurora/external-types/feature';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';

function isEndUserFeature(
  feature: ExternalCommunityFeature<string>
): feature is CommunityFeature<Feature> {
  return (feature as CommunityFeature<Feature>)['getPageDescriptors'] !== undefined;
}

/**
 * Component Registry that holds the records of the components for enduser application and its attributes.
 * @author Manish Shrestha
 */
class EndUserComponentRegistry {
  private readonly pageRecord: Record<EntityType, PageDescriptor<BannerWidgetProps>> = {} as Record<
    EntityType,
    PageDescriptor<BannerWidgetProps>
  >;

  private readonly allPageDescriptor: Record<Feature, PageDescriptor[]> = {
    blogs: [],
    categories: [],
    communities: [],
    'custom-content-widget': [],
    'featured-widget': [],
    forums: [],
    grouphubs: [],
    kudos: [],
    memberships: [],
    messages: [],
    notes: [],
    notifications: [],
    search: [],
    tkb: [],
    users: [],
    ideas: [],
    tagging: [],
    events: [],
    guides: []
  };

  private readonly widgetRecord: Partial<Record<EndUserComponent, WidgetDescriptor>> = {};

  private readonly allWidgetDescriptor: Record<Feature, WidgetDescriptor[]> = {
    blogs: [],
    categories: [],
    communities: [],
    'custom-content-widget': [],
    'featured-widget': [],
    forums: [],
    grouphubs: [],
    kudos: [],
    memberships: [],
    messages: [],
    notes: [],
    notifications: [],
    search: [],
    tkb: [],
    users: [],
    ideas: [],
    tagging: [],
    events: [],
    guides: []
  };

  constructor(communityFeatureRegistry: CommunityFeature<Feature>[]) {
    communityFeatureRegistry.forEach(feature => this.addFeature(feature));
  }

  addFeature(feature: ExternalCommunityFeature<string>): void {
    const featureName = feature.getFeature();
    this.allWidgetDescriptor[featureName] = feature.getWidgetDescriptors();
    feature.getWidgetDescriptors().forEach(descriptor => {
      if (this.widgetRecord[descriptor.id]) {
        throw new Error(
          `Widget with id ${descriptor.id} is already registered to ${JSON.stringify(
            this.widgetRecord[descriptor.id]
          )}`
        );
      }
      this.widgetRecord[descriptor.id] = { ...descriptor, feature: featureName };
    });
    if (isEndUserFeature(feature)) {
      this.allPageDescriptor[feature.getFeature()] = feature.getPageDescriptors();
      feature.getPageDescriptors().forEach(descriptor => {
        if (this.pageRecord[descriptor.page]) {
          throw new Error(
            `Page with id ${descriptor.page} is already registered to ${JSON.stringify(
              this.pageRecord[descriptor.page]
            )}`
          );
        }
        this.pageRecord[descriptor.page] = descriptor;
      });
    }
  }

  isFeatureLoaded(feature: ExternalCommunityFeature<string>) {
    return this.allWidgetDescriptor[feature.getFeature()] != null;
  }

  getAllWidgetDescriptorsForFeature(feature: string): WidgetDescriptor[] {
    return this.allWidgetDescriptor[feature];
  }

  getPageDescriptorsForFeature(feature: Feature): PageDescriptor[] {
    return this.allPageDescriptor[feature];
  }

  getAllPageDescriptors(): Record<Feature, PageDescriptor[]> {
    return this.allPageDescriptor;
  }

  getPageRecords(): Record<EntityType, PageDescriptor> {
    return this.pageRecord;
  }

  getModulePathForPage(page: EndUserPages): string {
    const descriptor = this.getPageDescriptor(page);
    const pagePath = descriptor.path;
    return pagePath.slice(0, pagePath.lastIndexOf('/'));
  }

  getComponentIdForPage(page: EndUserPages): EndUserComponent {
    const descriptor = this.getPageDescriptor(page);
    const modulePath = descriptor.path;
    return Object.entries(endUserModuleRegistry)
      .find(([, value]) => {
        return value === modulePath;
      })
      .map(key => {
        return enumFromValue(EndUserComponent, key);
      })[0];
  }

  getPageDescriptor(page: EndUserPages): PageDescriptor<BannerWidgetProps> {
    return this.pageRecord[page] ?? this.pageRecord[EndUserPages.CustomPage];
  }

  getWidgetDescriptor(id: string): ExternalWidgetDescriptor {
    return this.widgetRecord[id];
  }
}

const endUserComponentRegistry = new EndUserComponentRegistry(featureRegistry);
export default endUserComponentRegistry;
