/* eslint-disable class-methods-use-this */
import { clearSwitchUserIdCookie } from '@aurora/shared-apollo/helpers/SwitchUserHelper';
import { clearToggleTextKeysEnabledUserIdCookie } from '@aurora/shared-apollo/helpers/ToggleTextKeysHelper';
import type {
  AuthenticationHelper,
  LoginResult,
  LogoutResult,
  TokenResult
} from '@aurora/shared-types/authentication';
import { AuthErrorMessage } from '@aurora/shared-types/authentication';
import LogoutType from '@aurora/shared-types/authentication/enums';
import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { getLog } from '@aurora/shared-utils/log';
import { postToInternalApi } from '../ApiHelper';

const log = getLog(module);

/**
 * provides client authentication helpers
 * @author Manish Shrestha
 */
class ClientAuthenticationHelper implements AuthenticationHelper {
  /**
   * Gets the user's auth token.
   * @return a token result or null if unable to fetch token.
   */
  async getTokenResult(): Promise<TokenResult | null> {
    const tokenResponse = await postToInternalApi('/api/auth/token', null);
    if (tokenResponse.ok) {
      return (await tokenResponse.json()) as Promise<TokenResult>;
    } else {
      log.error('Error getting token: %s', tokenResponse.statusText ?? tokenResponse.status);
      return null;
    }
  }

  /**
   * Log in the user after registration
   * @param formData the registration form data
   */
  async loginFromRegistrationForm({
    login,
    password,
    rememberPassword
  }: {
    login: string;
    password: string;
    rememberPassword: boolean;
  }): Promise<LoginResult> | null {
    // only authenticate if the form has credentials
    if (login && password) {
      const result = await this.login(login, password, rememberPassword);
      if (result?.message) {
        log.error('Unable to log user in after registration: %O', result.message);
      }
      return result;
    }

    return null;
  }

  /**
   * Log in the user
   * @param username
   * @param password
   * @param keepMeSignedIn
   */
  async login(username: string, password: string, keepMeSignedIn: boolean): Promise<LoginResult> {
    try {
      const response = await postToInternalApi('/api/auth/authenticate', {
        username,
        password,
        keepMeSignedIn: `${keepMeSignedIn}`
      });
      if (!response.ok) {
        return {
          success: false,
          message: AuthErrorMessage.unknown,
          messageArgs: {}
        };
      }
      const { success, message, messageArgs } = await response.json();

      if (success) {
        localStorage.setItem(LocalStorageKeys.AUTH_INVALIDATION_KEY, 'true');
      }

      return {
        success,
        message,
        messageArgs
      };
    } finally {
      localStorage.removeItem(LocalStorageKeys.PAGE_EDITOR_SESSION_KEY);
    }
  }

  async logout(type?: LogoutType | null): Promise<LogoutResult> {
    try {
      const payload = type ? { type: `${type}` } : null;
      localStorage.setItem(LocalStorageKeys.AUTH_INVALIDATING_KEY, 'true');
      const response = await postToInternalApi('/api/auth/logout', payload);
      if (response.ok) {
        const result = await response.json();
        if (result?.success) {
          // set flag on client to clear out cached token
          localStorage.setItem(LocalStorageKeys.AUTH_INVALIDATION_KEY, 'true');
          if (type != LogoutType.ALL_BUT_CURRENT) {
            clearSwitchUserIdCookie();
            clearToggleTextKeysEnabledUserIdCookie();
          }
        }
        return result;
      } else {
        log.error('Unable to log out user: %s', response.statusText ?? response.status);
        return {
          success: false,
          cancelled: false
        };
      }
    } finally {
      localStorage.removeItem(LocalStorageKeys.AUTH_INVALIDATING_KEY);
      localStorage.removeItem(LocalStorageKeys.INBOX_CONVERSATIONS_FILTER);
      localStorage.removeItem(LocalStorageKeys.PAGE_EDITOR_SESSION_KEY);
    }
  }
}

const clientAuthenticationHelper = new ClientAuthenticationHelper();
export default clientAuthenticationHelper;
