import type { ApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';
import type {
  FormCol,
  FormField,
  FormFieldset,
  FormFieldValidationRule,
  FormLayoutItemRow,
  FormRow,
  Maybe,
  Scalars,
  FormFieldsetProps,
  FormRowProps,
  StringPossibleValue,
  FloatPossibleValue,
  IntPossibleValue
} from '@aurora/shared-generated/types/graphql-schema-types';
import { FieldInputControl } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  createObjectByPath,
  getPropertyByPath
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { DocumentNode } from 'graphql';
import type React from 'react';
import type { ColProps, RowProps } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import type { FieldValues } from 'react-hook-form';
import type { FieldPathValues } from 'react-hook-form/dist/types';
import type { FieldPath, Path } from 'react-hook-form/dist/types/path';
import type { FormGroupAsElement } from '../../../components/form/enums';
import { FormFieldVariant, FormGroupFieldType } from '../../../components/form/enums';
import type {
  CategorizedPossibleValueType,
  FieldsetProps,
  FieldValidationRules,
  FormFieldColumnItem,
  FormFieldColumnSpec,
  FormFieldGroupSpecDefinition,
  FormFieldRef,
  FormFieldRowSpec,
  FormFieldsetItem,
  FormFieldsetType,
  FormFieldSpecDefinition,
  FormFieldType,
  FormGroup,
  FormGroupFieldTypeBehavior,
  FormRowDefinition,
  LayoutFormField,
  PossibleValue,
  PossibleValuesField,
  PossibleValueType,
  PossibleValueTypes,
  WatchFields
} from '../../../components/form/types';
import type { ComponentProp } from '../../components/CustomComponentsHelper';
import { getCustomPropFormField } from '../../components/CustomComponentsHelper';
import { shouldUseNumericalValidation } from '../Validation/ValidationHelper';
import {
  getPossibleValuesForCustomField,
  isCustomFieldPossibleValueType,
  isPossibleValueTypes
} from '../../custom/CustomFieldHelper';
import type { CustomFieldSpec } from '../../../components/form/CustomField/CustomField';

const log = getLog(module);

declare type RowColWidth =
  | number
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';
declare type RowColumns =
  | RowColWidth
  | {
      cols?: RowColWidth;
    };
declare type NumberAttr =
  | number
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';
declare type ColOrder = 'first' | 'last' | NumberAttr;
declare type ColSize = boolean | 'auto' | NumberAttr;
declare type ColSpec =
  | ColSize
  | {
      span?: ColSize;
      offset?: NumberAttr;
      order?: ColOrder;
    };

function toRowColumns(value: Maybe<Scalars['String']['output']>): RowColumns | null {
  return (value as RowColumns) ?? null;
}

function toColSpec(value: Maybe<Scalars['String']['output']>): ColSpec {
  return (value as ColSpec) ?? null;
}

function toFieldsetProps(value: Maybe<FormFieldsetProps>): FieldsetProps {
  if (value) {
    const { disabled, form } = value;
    return {
      disabled,
      form
    };
  }
  return null;
}

function toRowProps(value: Maybe<FormRowProps>): RowProps {
  if (value) {
    const { noGutters, xs, sm, md, lg, xl } = value;
    return {
      noGutters,
      xs: toRowColumns(xs),
      sm: toRowColumns(sm),
      md: toRowColumns(md),
      lg: toRowColumns(lg),
      xl: toRowColumns(xl)
    };
  }
  return null;
}

/**
 * Returns the form field as {@link FormFieldType} if form field is of type form field.
 * @param object object to verify.
 */
export function isFormFieldType<FormDataT extends FieldValues>(
  object: FormFieldSpecDefinition<FormDataT>
): object is FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant> {
  return 'fieldVariant' in object;
}

function asFormField<FormDataT extends FieldValues>(
  object: FormFieldSpecDefinition<FormDataT>
): FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant> {
  return object as FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>;
}

function isLayoutFormField(object: FormRowDefinition): object is LayoutFormField {
  return 'formGroup' in object;
}

function isGraphQLType(object: Object, typeName: string): boolean {
  return '__typename' in object && object['__typename'] === typeName;
}

function isFormItemRowFieldRef(object: FormLayoutItemRow): object is FormFieldRef {
  return isGraphQLType(object, 'FormFieldRef');
}

function isFormItemRowFieldset(object: FormLayoutItemRow): object is FormFieldset {
  return isGraphQLType(object, 'FormFieldset');
}

function isFormItemRowRow(object: FormLayoutItemRow): object is FormRow {
  return isGraphQLType(object, 'FormRow');
}

function isFormFieldRef(object: FormRowDefinition): object is FormFieldRef {
  return isGraphQLType(object, 'FormFieldRef');
}

export function isFormFieldReference(object: FormRowDefinition): boolean {
  return isFormFieldRef(object) || isLayoutFormField(object);
}

function isFormFieldFieldSet<FormDataT extends FieldValues>(
  object: FormFieldGroupSpecDefinition<FormDataT>
): object is FormFieldsetType<FormDataT> {
  return 'type' in object && object['type'] === FormGroupFieldType.FIELDSET;
}

function isFormFieldRowSpec<FormDataT extends FieldValues>(
  object: FormFieldGroupSpecDefinition<FormDataT>
): object is FormFieldRowSpec<FormDataT> {
  return 'type' in object && object['type'] === FormGroupFieldType.ROW;
}

export function isFormGroupField(
  object: FormRowDefinition
): object is FormGroupFieldTypeBehavior<unknown, unknown, FormGroupFieldType> {
  return 'items' in object;
}

export function isPossibleValuesField<
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues
>(
  object: FormFieldType<NameT, FormDataT, FormFieldVariant>
): object is PossibleValuesField<NameT, FormDataT, FormFieldVariant> {
  return 'values' in object;
}

export function isCategorizedPossibleValuesField<
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues
>(
  object:
    | PossibleValueType<FormFieldVariant, NameT, FormDataT>
    | CategorizedPossibleValueType<FormFieldVariant, NameT, FormDataT>
): object is CategorizedPossibleValueType<FormFieldVariant, NameT, FormDataT> {
  return object.length > 0 && object.filter(value => 'category' in value).length === object.length;
}

/**
 * Performs the action specified by actionCallback if the specified field definition is form field. If, the field
 * definition is a field group then, iterates through its fields recursively and calls the specified form group action
 * callback.
 *
 * @param formFieldDefinition formFieldDefinition specified for the form.
 * @callback
 * @param onFormFieldCallback callback to execute when formFieldDefinition is a field and not a group.
 * @param onFieldGroupCallback callback to execute when formFieldDefinition is a group and not a field.
 */
export function performActionOnFormFieldSpec<FormDataT extends FieldValues>(
  formFieldDefinition: FormFieldSpecDefinition<FormDataT>,
  onFormFieldCallback: (
    formFieldSpec: FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  ) => void,
  onFieldGroupCallback?: (formFieldGroupSpec: FormFieldGroupSpecDefinition<FormDataT>) => void
): void {
  if (isFormFieldType<FormDataT>(formFieldDefinition)) {
    onFormFieldCallback(asFormField(formFieldDefinition));
  } else {
    if (onFieldGroupCallback) {
      onFieldGroupCallback(formFieldDefinition);
    }
    formFieldDefinition.items.forEach(spec => {
      if (spec) {
        performActionOnFormFieldSpec(spec, onFormFieldCallback, onFieldGroupCallback);
      }
    });
  }
}

/**
 *
 * @param value the value to be searched for
 * @param possibleValues the list where the scan will take place
 * @returns the selected value or array of value or undefined in case not found
 */
function findSelectedValue<ValueT, DataT extends FieldValues>(
  value: unknown,
  possibleValues:
    | PossibleValueType<FormFieldVariant, Path<DataT>, DataT>
    | FloatPossibleValue[]
    | StringPossibleValue[]
    | IntPossibleValue[]
): PossibleValueTypes | Array<ValueT[keyof ValueT]> | undefined {
  if (Array.isArray(value)) {
    return possibleValues
      .filter(option => {
        const { key, value: optionValue } = option;
        return value.includes(optionValue) || value.includes(key);
      })
      .map(({ value: optionValue }) => optionValue) as Array<ValueT[keyof ValueT]>;
  }
  return possibleValues.find(({ key: keyAttr, value: optionValue }) => {
    return (
      keyAttr === value ||
      (typeof value === 'object' ? isEqual(optionValue, value) : optionValue === value)
    );
  }) as PossibleValueTypes | undefined;
}

function isCustomFieldFormFieldType<
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues
>(
  fieldSpec: FormFieldType<NameT, FormDataT, FormFieldVariant>
): fieldSpec is CustomFieldSpec<NameT, FormDataT> {
  return FormFieldVariant.CUSTOM_FIELD === fieldSpec.fieldVariant;
}

function transformCustomFieldValue<
  DataT extends FieldValues,
  NameT extends FieldPath<DataT>,
  ValueT extends Record<string, unknown>
>(formFieldSpec: CustomFieldSpec<NameT, DataT>, value: ValueT): ValueT {
  const tempAdjustedValue = {};
  const customFormFields = formFieldSpec.fields;
  for (const customField of customFormFields) {
    const { name } = customField;
    if (isCustomFieldPossibleValueType(customField)) {
      const possibleValues: PossibleValueTypes[] = getPossibleValuesForCustomField(customField);
      const selectedValues = findSelectedValue(value[name], possibleValues ?? []);
      if (!Array.isArray(selectedValues) && isPossibleValueTypes(selectedValues)) {
        tempAdjustedValue[name] = selectedValues?.value ?? null;
      }
    }
  }
  return Object.assign({}, value, tempAdjustedValue);
}

/**
 * Transforms value of the form field.
 * @param data the current data.
 * @param formFields the form fields.
 */
export function transformValue<FormDataT extends FieldValues>(
  data: Partial<FormDataT>,
  formFields: Array<FormFieldSpecDefinition<FormDataT>>
): FormDataT {
  const adjustedData: FormDataT = {} as FormDataT;
  formFields.forEach(formFieldDefinition => {
    performActionOnFormFieldSpec(formFieldDefinition, formFieldSpec => {
      const { name } = formFieldSpec;
      const value = getPropertyByPath(data, name);
      if (value !== undefined) {
        let adjustedValue = value;
        if (isCustomFieldFormFieldType(formFieldSpec)) {
          adjustedValue = transformCustomFieldValue(
            formFieldSpec,
            value as Record<string, unknown>
          );
        } else if (isPossibleValuesField(formFieldSpec)) {
          const values: PossibleValueType<
            FormFieldVariant,
            Path<FormDataT>,
            FormDataT
          > = isCategorizedPossibleValuesField(formFieldSpec.values)
            ? formFieldSpec.values.flatMap<
                PossibleValue<
                  | FormDataT[Path<FormDataT>]
                  | (FormDataT[Path<FormDataT>] extends (infer TypeT)[] ? TypeT : never),
                  FormDataT
                >
              >(({ values: possibleValues }) => possibleValues)
            : formFieldSpec.values;

          const selectedValues = findSelectedValue(value, values);
          adjustedValue = !Array.isArray(selectedValues)
            ? selectedValues?.value ?? null
            : selectedValues;
        }
        createObjectByPath(adjustedData, name, adjustedValue);
      }
    });
  });
  return adjustedData;
}

/**
 * Returns true if the field is visible else false.
 *
 * @param field field to check.
 * @param watchFormData formData that is being watched for changes.
 * @param formFields form fields of the form
 */

export function isFieldVisible<FormDataT extends FieldValues>(
  field: FormFieldSpecDefinition<FormDataT>,
  watchFormData: Partial<FormDataT>,
  formFields: Array<FormFieldSpecDefinition<FormDataT>>
): boolean {
  if (isFormFieldType(field)) {
    const { isVisible } = field;
    return (
      !isVisible ||
      isVisible.callback(Object.freeze(transformValue<FormDataT>(watchFormData, formFields)))
    );
  } else {
    return field.items.some(fieldItem => isFieldVisible(fieldItem, watchFormData, formFields));
  }
}

/**
 * normalizes watched values to partial form data based on fields watched
 * @param watchedFields watched fields
 * @param watchedValues values returned from useWatch or watch when suing the watchFields
 */
export function normalizeWatchedValues<DataT extends FieldValues>(
  watchedFields: WatchFields<DataT>,
  watchedValues: FieldPathValues<DataT, FieldPath<DataT>[]>
): Partial<DataT> {
  let watchedValuesObject: Partial<DataT> = {};
  if (watchedFields === 'all') {
    watchedValuesObject = watchedValues as unknown as Partial<DataT>;
  } else {
    watchedFields.forEach((field, index) => {
      watchedValuesObject[field] = watchedValues[index];
    });
  }
  return watchedValuesObject;
}

/**
 * Merges the default validation rules with the validation rules sent from server
 * @param source - the validation rules sent from server
 * @param defaultValidation - the default validation rule
 * @param client - apollo client
 */
export function mergeValidations(
  source: FormFieldValidationRule,
  defaultValidation: FieldValidationRules<FieldValues, FieldPath<FieldValues>>,
  client: ApolloClient<object>
): FieldValidationRules<FieldValues, FieldPath<FieldValues>> {
  if (!source) {
    return defaultValidation;
  }

  const { minLength, maxLength, min, max, possibleValues, validationQuery } = source;
  const mergedValidation: FieldValidationRules<FieldValues, FieldPath<FieldValues>> = {
    ...defaultValidation
  };
  if (shouldUseNumericalValidation(min)) {
    mergedValidation.min = min;
  }

  if (shouldUseNumericalValidation(max)) {
    mergedValidation.max = max;
  }

  if (shouldUseNumericalValidation(minLength)) {
    mergedValidation.minLength = minLength;
  }

  if (shouldUseNumericalValidation(maxLength)) {
    mergedValidation.maxLength = maxLength;
  }

  if (validationQuery !== null || possibleValues?.length) {
    mergedValidation.validate = {
      validateServer: validationQuery
        ? async value => {
            const { name, argumentName } = validationQuery;
            const query: DocumentNode = gql`
              ${name}
            `;

            const response = await client.query({
              query,
              variables: {
                [argumentName]: value
              }
            });

            return response?.errors.length === 0;
          }
        : undefined,
      possibleValues: possibleValues
        ? value => {
            return possibleValues.includes(value);
          }
        : undefined
    };
  }

  return mergedValidation;
}

function customFormFieldDefinition<FormDataT extends FieldValues>(
  customField: FormField,
  prop?: ComponentProp,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant> {
  return getCustomPropFormField(customField, prop, formGroupSpec) as unknown as FormFieldType<
    FieldPath<FormDataT>,
    FormDataT,
    FormFieldVariant
  >;
}

function getFieldDefinition<FormDataT extends FieldValues>(
  fieldDefinitionParam?: FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>,
  field?: FormField,
  prop?: ComponentProp,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant> | null {
  if (fieldDefinitionParam) {
    return fieldDefinitionParam;
  }
  if (field) {
    return customFormFieldDefinition(field, prop, formGroupSpec);
  }
  return null;
}

function getFormFieldVariantForControl(control: FieldInputControl): FormFieldVariant {
  switch (control) {
    case FieldInputControl.Input: {
      return FormFieldVariant.INPUT;
    }
    case FieldInputControl.Select: {
      return FormFieldVariant.SELECT;
    }
    case FieldInputControl.Radio: {
      return FormFieldVariant.RADIO;
    }
    case FieldInputControl.Check: {
      return FormFieldVariant.CHECK;
    }
    case FieldInputControl.Datetime: {
      return FormFieldVariant.DATETIME;
    }
  }
}

function getFormFieldCallback<NameT extends FieldPath<FormDataT>, FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formField: FormFieldType<NameT, FormDataT, FormFieldVariant>,
  formFieldProvider: (name: NameT) => FormField | null | undefined
): void {
  const field = formFieldProvider(formField.name);
  const mergedValidation = mergeValidations(field?.validation, formField.validations, client);
  if (mergedValidation) {
    log.debug(
      'Merged validation for form with id: %s for %s is %O',
      formId,
      formField.name,
      mergedValidation
    );
  }
  formField.validations = mergedValidation;
  formField.specialChecks = field?.validation?.specialChecks ?? [];
  formField.fieldVariant = field?.control
    ? getFormFieldVariantForControl(field.control)
    : formField.fieldVariant;
}

export function createOrMergeCustomFormFieldType<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  field?: FormField,
  fieldDefinitionParam?: FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>,
  prop?: ComponentProp,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant> {
  const fieldDefinition: FormFieldType<
    FieldPath<FormDataT>,
    FormDataT,
    FormFieldVariant
  > = getFieldDefinition(fieldDefinitionParam, field, prop, formGroupSpec);
  if (fieldDefinition) {
    performActionOnFormFieldSpec(fieldDefinition, formField =>
      getFormFieldCallback(client, formId, formField, () => field)
    );
  }
  return fieldDefinition;
}

function toFormFieldsetItem<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  item: FormLayoutItemRow,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldsetItem<FormDataT> {
  let formFieldSetItem: FormFieldsetItem<FormDataT> = null;
  if (isFormItemRowFieldRef(item)) {
    const field = formFields[item.id];
    const customProp = customProps?.find(p => {
      return p.name === item.id;
    });
    formFieldSetItem = createOrMergeCustomFormFieldType(
      client,
      formId,
      field,
      null,
      customProp,
      formGroupSpec
    );
  } else if (isFormItemRowRow(item)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formFieldSetItem = createOrMergeFormRowSpec(
      client,
      formId,
      formFields,
      customProps,
      item,
      null,
      formGroupSpec
    ) as FormFieldRowSpec<FormDataT>;
  }
  return formFieldSetItem;
}

function toFormFieldColumnItem<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  item: FormLayoutItemRow,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldColumnItem<FormDataT> {
  let formFieldColumnItem: FormFieldColumnItem<FormDataT> = null;
  if (isFormItemRowFieldRef(item)) {
    const field = formFields[item.id];
    const customProp = customProps?.find(p => {
      return p.name === item.id;
    });
    formFieldColumnItem = createOrMergeCustomFormFieldType(
      client,
      formId,
      field,
      null,
      customProp,
      formGroupSpec
    );
  } else if (isFormItemRowRow(item)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formFieldColumnItem = createOrMergeFormRowSpec(
      client,
      formId,
      formFields,
      customProps,
      item,
      null,
      formGroupSpec
    ) as FormFieldRowSpec<FormDataT>;
  } else if (isFormItemRowFieldset(item)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formFieldColumnItem = createOrMergeFormFieldSet(
      client,
      formId,
      formFields,
      customProps,
      item,
      null,
      formGroupSpec
    ) as FormFieldsetType<FormDataT>;
  }
  return formFieldColumnItem;
}

function createOrOverrideFormFieldRowCol<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  formCol: FormCol,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldColumnSpec<FormDataT> {
  const { className, id, items: colItems, props: colProps } = formCol;
  const type = FormGroupFieldType.COL;
  const items: Array<FormFieldColumnItem<FormDataT>> = colItems.map(item =>
    toFormFieldColumnItem(client, formId, formFields, customProps, item, formGroupSpec)
  );
  const props: ColProps = colProps
    ? {
        xs: toColSpec(colProps.xs),
        sm: toColSpec(colProps.sm),
        md: toColSpec(colProps.md),
        lg: toColSpec(colProps.lg),
        xl: toColSpec(colProps.xl)
      }
    : undefined;
  return {
    className,
    id,
    items,
    props,
    type
  };
}

function customFormRowSpec<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  formRow: FormRow,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldRowSpec<FormDataT> {
  const { id, className, props: groupProps, items: groupItems } = formRow;
  const type = FormGroupFieldType.ROW;
  const items: Array<FormFieldColumnSpec<FormDataT>> = groupItems.map(item =>
    createOrOverrideFormFieldRowCol(client, formId, formFields, customProps, item, formGroupSpec)
  );
  const props: RowProps = toRowProps(groupProps);
  return {
    className,
    id,
    items,
    props,
    type
  };
}

function createOrMergeFormRowSpec<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  formRow: FormRow,
  formRowSpec?: FormFieldRowSpec<FormDataT>,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldRowSpec<FormDataT> {
  if (formRowSpec) {
    performActionOnFormFieldSpec(
      formRowSpec,
      formField => getFormFieldCallback(client, formId, formField, name => formFields[name]),
      formFieldGroupSpec => {
        const { className, props, viewVariant } = formRow;
        if (className) {
          formFieldGroupSpec.className = formRow.className;
        }
        if (viewVariant) {
          formFieldGroupSpec.viewVariant = formRow.viewVariant;
        }
        if (props) {
          formFieldGroupSpec.props = toRowProps(props);
        }
      }
    );
    return formRowSpec;
  }
  return customFormRowSpec(client, formId, formFields, customProps, formRow, formGroupSpec);
}

function customFormFieldSet<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  formFieldset: FormFieldset,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldsetType<FormDataT> {
  const { id, className, props, items: groupItems } = formFieldset;
  const type = FormGroupFieldType.FIELDSET;
  const items: Array<FormFieldsetItem<FormDataT>> = groupItems.map(item => {
    return toFormFieldsetItem(client, formId, formFields, customProps, item, formGroupSpec);
  });
  return {
    id,
    className,
    items,
    props,
    type
  };
}

function createOrMergeFormFieldSet<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  formFieldset: FormFieldset,
  groupDefinition?: FormFieldsetType<FormDataT>,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldsetType<FormDataT> {
  if (groupDefinition) {
    performActionOnFormFieldSpec(
      groupDefinition,
      formField => getFormFieldCallback(client, formId, formField, name => formFields[name]),
      formFieldGroupSpec => {
        if (formFieldset.className) {
          formFieldGroupSpec.className = formFieldset.className;
        }
        if (formFieldset.viewVariant) {
          formFieldGroupSpec.viewVariant = formFieldset.viewVariant;
        }
        const { props } = formFieldset;
        if (props) {
          formFieldGroupSpec.props = toFieldsetProps(props);
        }
      }
    );
    return groupDefinition;
  }
  return customFormFieldSet(client, formId, formFields, customProps, formFieldset, formGroupSpec);
}

export function createOrOverrideCustomFormFieldGroupType<FormDataT extends FieldValues>(
  client: ApolloClient<object>,
  formId: string,
  formFields: Record<string, FormField>,
  customProps: Array<ComponentProp>,
  group?: FormLayoutItemRow,
  groupDefinition?: FormFieldGroupSpecDefinition<FormDataT>,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldGroupSpecDefinition<FormDataT> {
  if (group) {
    if (isFormItemRowFieldset(group)) {
      return createOrMergeFormFieldSet(
        client,
        formId,
        formFields,
        customProps,
        group,
        groupDefinition && isFormFieldFieldSet(groupDefinition)
          ? (groupDefinition as FormFieldsetType<FormDataT>)
          : null,
        formGroupSpec
      );
    } else if (isFormItemRowRow(group)) {
      return createOrMergeFormRowSpec(
        client,
        formId,
        formFields,
        customProps,
        group,
        groupDefinition && isFormFieldRowSpec(groupDefinition)
          ? (groupDefinition as FormFieldRowSpec<FormDataT>)
          : null,
        formGroupSpec
      );
    }
  }
  return groupDefinition;
}
