import React, {
  forwardRef,
  type ForwardRefExoticComponent,
  type PropsWithoutRef,
  type RefAttributes
} from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../../enums';
import { LoadingSize, LoadingSpacing, LoadingTheme } from '../../../../types/enums';

import useTranslation from '../../../useTranslation';
import type { LoadingDotProps } from '../types';
import localStyles from './LoadingDot.module.pcss';

/**
 * Dot Loading indicator
 *
 * @constructor
 *
 * @author Dolan Halbrook, Adam Ayres, Willi Hyde
 */
const LoadingDot: ForwardRefExoticComponent<
  PropsWithoutRef<LoadingDotProps> & RefAttributes<HTMLDivElement>
> = forwardRef(function LoadingDotForward(
  {
    size = LoadingSize.LG,
    spacing = LoadingSpacing.MD,
    theme = LoadingTheme.DARK,
    useFullHeight = true,
    dotClassName,
    className
  },
  ref
) {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.LOADING_DOT);

  const title = textLoading ? '...' : formatMessage('title');
  const loaderClassName = cx('d-flex justify-content-center align-items-center', {
    'h-100': useFullHeight
  });
  const titleClassName = cx(
    `lia-dot-wrap lia-dot-size-${size} lia-dot-${theme}`,
    {
      [`lia-dot-spacing-${spacing}`]: spacing !== LoadingSpacing.NONE
    },
    className
  );
  const finalDotClassName = cx('lia-dot', dotClassName);
  return size ? (
    <div className={loaderClassName} data-testid="Loader" ref={ref}>
      <div title={title} className={titleClassName}>
        <div className={finalDotClassName} />
        <div className={finalDotClassName} />
        <div className={finalDotClassName} />
      </div>
    </div>
  ) : null;
});

export default LoadingDot;
