import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import type {
  LocalizedCategoriesFeatureEnabledQuery,
  LocalizedCategoriesFeatureEnabledQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import useQueryWithTracing from '../useQueryWithTracing';
import query from './LocalizedCategoriesFeatureEnabled.query.graphql';

const log = getLog(module);

/**
 * Gets node-specific policies for a node.
 *
 * @param module The module
 * @param skip whether to skip the graphql call.
 * @param useCache whether to use the 'cache-first' fetch policy.  If false, 'cache-and-network' will be used.
 *
 * @author Dolan Halbrook
 */
export default function useLocalizedCategoriesFeatureEnabled(
  module: NodeModule | string,
  skip = false,
  useCache = true
): {
  loading: boolean;
  enabled: boolean;
} {
  const result = useQueryWithTracing<
    LocalizedCategoriesFeatureEnabledQuery,
    LocalizedCategoriesFeatureEnabledQueryVariables
  >(module, query, {
    fetchPolicy: useCache ? 'cache-first' : 'cache-and-network',
    skip: skip
  });
  if (result?.error) {
    log.error(result.error, 'Error getting useLocalizedCategoriesFeatureEnabled');
  }
  return {
    loading: result?.loading,
    enabled: checkPolicy(
      result?.data?.community?.communityPolicies?.localizedCategoriesFeatureEnabled
    )
  };
}
