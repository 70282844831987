import { AppType } from '@aurora/shared-types/app';
import type {
  BaseRouteAndParams,
  ParsedUrlQuery
} from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import type Routes from '@aurora/shared-utils/helpers/urls/NextRoutes/Routes';
import { canUseDOM } from 'exenv';
import type { LinkProps } from 'next/dist/client/link';
import Link from 'next/link';
import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import AppTypeContext from '../components/context/AppTypeContext';
import {
  createFullyQualifiedUrl,
  isHostLocal
} from '../helpers/router/CrossApplicationRouterHelper';

export interface CustomLinkProps<RouteType, Route extends BaseRouteAndParams<RouteType>>
  extends Omit<LinkProps, 'href' | 'as'> {
  /**
   * The route name.
   */
  route: RouteType;
  /**
   * The route path params.
   */
  params?: Route['params'];
  /**
   * The route query params.
   */
  query?: ParsedUrlQuery;
}

/**
 * Creates a wrapper around the next/link that works with our custom routing solution.
 *
 * @param routes the routes
 * @param targetApp the target app type
 *
 * @author Adam Ayres
 */
function buildCustomLink<RouteType extends string>(
  routes: Routes<RouteType>,
  targetApp: AppType = AppType.END_USER
) {
  return function CustomLink<Route extends BaseRouteAndParams<RouteType>>({
    route,
    params = {},
    query,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    locale,
    children
  }: CustomLinkProps<RouteType, Route> & { children?: ReactNode }) {
    const { externalRelativeUrl, internalRelativeUrl } = routes.findAndGetUrls(
      route,
      params,
      query
    );

    const currentApp = useContext(AppTypeContext);

    if (
      currentApp !== targetApp &&
      typeof window !== 'undefined' &&
      isHostLocal(window.location.host)
    ) {
      // Copied from: https://github.com/vercel/next.js/blob/canary/packages/next/client/link.tsx#L232-L251
      // This will return the first child, if multiple are provided it will throw an error
      let child;
      if (process.env.NODE_ENV === 'development') {
        try {
          child = React.Children.only(children);
        } catch {
          if (!children) {
            throw new Error(
              `No children were passed to <Link> with \`href\` of \`${externalRelativeUrl}\` but one child is required https://nextjs.org/docs/messages/link-no-children`
            );
          }
          throw new Error(
            `Multiple children were passed to <Link> with \`href\` of \`${externalRelativeUrl}\` but only one child is supported https://nextjs.org/docs/messages/link-multiple-children` +
              (typeof window !== 'undefined'
                ? " \nOpen your browser's console to view the Component stack trace."
                : '')
          );
        }
      } else {
        child = React.Children.only(children);
      }

      const childProps: {
        href?: string;
        suppressHydrationWarning?: boolean;
      } = {};

      // Copied from: https://github.com/vercel/next.js/blob/canary/packages/next/client/link.tsx#L325-L346
      if (passHref || (child.type === 'a' && !('href' in child.props))) {
        childProps.href = canUseDOM
          ? createFullyQualifiedUrl(window.location.host, externalRelativeUrl, targetApp)
          : externalRelativeUrl;
        childProps.suppressHydrationWarning = childProps.href !== externalRelativeUrl;
      }
      return React.cloneElement(child, childProps);
    }

    return (
      <Link
        legacyBehavior={true}
        as={externalRelativeUrl}
        href={internalRelativeUrl}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        prefetch={prefetch}
        locale={locale}
      >
        {children}
      </Link>
    );
  };
}

export default buildCustomLink;
