import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

export class MessagesFeature implements CommunityFeature<Feature.MESSAGES> {
  getFeature(): Feature.MESSAGES {
    return Feature.MESSAGES;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.MESSAGE_EDITOR_WIDGET,
        component: dynamic(
          () => import('../components/messages/MessageEditorWidget/MessageEditorWidget')
        )
      },
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_NODE_BY_RECENT_ACTIVITY_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/MessageListForNodeByRecentActivityWidget/MessageListForNodeByRecentActivityWidget'
            )
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/messages/MessageListForNodeByRecentActivityWidgetEditor/MessageListForNodeByRecentActivityWidgetEditor'
              )
          )
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.BlogBoardPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.TkbBoardPage,
          EndUserPages.UserPage,
          EndUserPages.ForumMessagePage,
          EndUserPages.TagPage
        ]
      },
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_VIEW_ALL_WIDGET,
        component: dynamic(
          () =>
            import('../components/messages/MessageListForViewAllWidget/MessageListForViewAllWidget')
        ),
        allowedPages: [
          EndUserPages.ForumViewAllTopicsPage,
          EndUserPages.TkbViewAllArticlesPage,
          EndUserPages.BlogViewAllPostsPage,
          EndUserPages.IdeaViewAllIdeasPage,
          EndUserPages.AllOccasionsPage
        ]
      },
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_NODE_BY_TOP_CONTENT_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/MessageListForNodeByTopContentWidget/MessageListForNodeByTopContentWidget'
            )
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/messages/MessageListForNodeByTopContentWidgetEditor/MessageListForNodeByTopContentWidgetEditor'
              )
          )
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.BlogBoardPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.TkbBoardPage,
          EndUserPages.UserPage
        ]
      },
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_NODE_TABBABLE_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/MessageListForNodeTabbableWidget/MessageListForNodeTabbableWidget'
            )
        )
      },
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_NODE_WIDGET,
        component: dynamic(
          () => import('../components/messages/MessageListForNodeWidget/MessageListForNodeWidget')
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/messages/MessageListForNodeWidgetEditor/MessageListForNodeWidgetEditor'
              )
          )
        }
      },
      {
        id: EndUserComponent.TOPIC_LIST_FOR_NODE_BY_DATE_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/TopicListForNodeByDateWidget/TopicListForNodeByDateWidget'
            )
        )
      },
      {
        id: EndUserComponent.TOPIC_LIST_FOR_USER_BY_DATE_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/TopicListForUserByDateWidget/TopicListForUserByDateWidget'
            )
        )
      },
      {
        id: EndUserComponent.TOPIC_WITH_THREADED_REPLY_LIST_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/TopicWithThreadedReplyListWidget/TopicWithThreadedReplyListWidget'
            )
        )
      },
      {
        id: EndUserComponent.USER_LIST_BY_MESSAGES_COUNT_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/users/UserListByMessagesCountWidget/UserListByMessagesCountWidget'
            )
        )
      },
      {
        id: EndUserComponent.MESSAGE_LIST_FOR_USER_BY_DATE_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/messages/MessageListForUserByDateWidget/MessageListForUserByDateWidget'
            )
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/messages/MessageListForUserByDateWidgetEditor/MessageListForUserByDateWidgetEditor'
              )
          )
        }
      },
      {
        id: EndUserComponent.MESSAGE_ACTION_TOOLBAR,
        component: dynamic(
          () => import('../components/messages/MessageActionToolbar/MessageActionToolbar')
        )
      },
      {
        id: EndUserComponent.MESSAGE_BANNER_WIDGET,
        component: dynamic(() => import('../components/messages/MessageBanner/MessageBanner'))
      },
      {
        id: EndUserComponent.RELATED_CONTENT_WIDGET,
        component: dynamic(
          () => import('../components/messages/RelatedContentWidget/RelatedContentWidget')
        ),
        editor: {
          form: dynamic(
            () =>
              import('../components/messages/RelatedContentWidgetEditor/RelatedContentWidgetEditor')
          )
        },
        allowedPages: [
          EndUserPages.ForumMessagePage,
          EndUserPages.BlogMessagePage,
          EndUserPages.TkbMessagePage,
          EndUserPages.IdeaMessagePage
        ]
      }
    ];
  }
}
