import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class ExtraLightItalicStyle implements FontStyleDefinition {
  name = FontStyleName.EXTRALIGHTITALIC;

  style = FontStyle.Italic;

  weight = 200;
}
