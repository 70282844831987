import type { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import type { Error } from '@aurora/shared-generated/types/graphql-schema-types';

type MutateCallback<TData> = (
  options?: MutationFunctionOptions<TData>
) => Promise<FetchResult<TData>>;

/**
 * Fix a bug in Apollo Client where errors from a mutation callback
 * were resulting in an unhandled exception.
 *
 * https://github.com/apollographql/apollo-client/issues/7433
 * https://github.com/apollographql/apollo-client/issues/6790
 * https://github.com/apollographql/apollo-client/issues/7167
 *
 * @param mutateCallback the mutation handler
 *
 * @author Adam Ayres
 */
function fixMutationCallbackError<TData>(
  mutateCallback?: MutateCallback<TData>
): MutateCallback<TData> {
  return async (options?: MutationFunctionOptions<TData>): Promise<FetchResult<TData>> => {
    try {
      return await mutateCallback(options);
    } catch (error) {
      if (error instanceof ApolloError) {
        return { errors: error.graphQLErrors, data: null };
      }
      return { errors: error, data: null };
    }
  };
}

export type ObjectWithTypename = { __typename?: string };

/**
 * Remove's `__typename` as a top-level property for the specified object
 *
 * @param object the object
 */
function removeTypename<ObjectType extends ObjectWithTypename>(
  object: ObjectType
): Omit<ObjectType, '__typename'> {
  if (object) {
    const { __typename, ...everythingElse } = object;
    return everythingElse;
  }
  return object;
}

/**
 * Does the response have associated errors?
 * @param response
 */
function hasErrors(response: { errors?: Error[] }) {
  return !!response.errors && response.errors.length > 0;
}

export { fixMutationCallbackError, removeTypename, hasErrors };
