import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import React from 'react';
import type { RouteWithOptions } from '../../../routes/useCustomRouter';
import type { AuthFlow } from '../../../types/enums';
import type { AuthProviderInfo } from '../../useAuthFlow';
import type { BannerFormAlertProps } from '../../common/BannerFormAlert/BannerFormAlert';

/**
 * Auth flow context for the page
 */
export interface AuthFlowContextInterface {
  /**
   * Callback function to show the authentication modal for a specified auth flow
   *
   * @callback
   * @param type Type of authentication flow to show modal for
   * @param routeAndParams Specify the route navigation if any post auth success
   * @param authProviderInfo Auth provider info for a community
   * @param bannerFormAlertProps Props to render Banner form alerts in the form.
   */
  showAuthenticationModal?: (
    type: AuthFlow,
    routeAndParams?: RouteWithOptions<EndUserPages, EndUserQueryParams>,
    authProviderInfo?: AuthProviderInfo,
    bannerFormAlertProps?: BannerFormAlertProps
  ) => void;
  /**
   * Hide the authentication modal
   */
  hideAuthenticationModal?: () => void;
}

/**
 * Context that stores the state of the authentication flow modals
 *
 * @author Rishabh Modi
 */
const AuthFlowContext = React.createContext<AuthFlowContextInterface>({
  showAuthenticationModal: (): void => null,
  hideAuthenticationModal: (): void => null
});

export default AuthFlowContext;
