import type { ColorCssVariableOrValue, LegacyColorKey } from '@aurora/shared-types/styles';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import { getLog } from '@aurora/shared-utils/log';

const log = getLog(module);

/**
 * whether the color is a legacy color key
 *
 * @param color the color
 */
export function isLegacyCustomKey(color: ColorCssVariableOrValue): boolean {
  return color.startsWith('custom-');
}

/**
 * Mapping of legacy colors to var() wrapped CSS variable equivalent.
 */
const legacyColorMap = {
  'custom-1': CommonColorCssVariables.WHITE,
  'custom-2': CommonColorCssVariables.BLACK,
  'custom-3': CommonColorCssVariables.BODY_BG,
  'custom-4': CommonColorCssVariables.GRAY_800,
  'custom-5': CommonColorCssVariables.GRAY_400,
  'custom-6': CommonColorCssVariables.BODY_TEXT,
  'custom-7': null,
  'custom-8': null,
  'custom-9': null,
  'custom-10': null
};

/**
 * Converts a legacy color key into a valid CSS color. This was only added to support a format
 * or color keys that was initially used in Aurora that has since been replace with CSS variables
 * and that should ultimately be removed once all references to these keys are removed from any quilt overrides.
 *
 * @param theme the theme
 * @param colorKey the color key
 *
 * @author Adam Ayres
 */
export function getLegacyColor(colorKey: LegacyColorKey): string {
  const newColor = legacyColorMap[colorKey];
  log.warn(
    'A legacy color value has been used: %s. This should be replaced with: %s',
    colorKey,
    newColor ?? 'a common or custom color value.'
  );
  return newColor ?? CommonColorCssVariables.GRAY_500;
}
