import type { PageContext } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  AuthUserFragment,
  ContextCommunityFragment,
  ContextMessageFragment,
  ContextNodeFragment,
  ContextUserFragment
} from '@aurora/shared-generated/types/graphql-types';
import { createContext } from 'react';

export interface ThemeInfo {
  /**
   * ID of the configured theme in the community
   */
  id: string;
  /**
   * Last modified date of theme overrides.
   */
  lastModified?: string;
}

export interface AppContextInterface {
  /**
   * The currently authenticated user, or the anonymous user if no user is currently authenticated.
   */
  authUser?: AuthUserFragment;
  /**
   * The current community.
   */
  community?: ContextCommunityFragment;
  /**
   * The node the principal user is viewing, or the current community if no node is being viewed.
   */
  contextNode?: ContextNodeFragment;
  /**
   * The message the principal user is viewing, or null if no message is being viewed.
   */
  contextMessage?: ContextMessageFragment;
  /**
   * The user the principal user is viewing, or null if no user is being viewed.
   */
  contextUser?: ContextUserFragment;
  /**
   * Last modified date of custom components.
   */
  componentsLastModified?: string;
  /**
   * Last modified date of quilt overrides.
   */
  quiltLastModified?: string;
  /**
   * Last modified date of quilt wrapper overrides.
   */
  quiltWrapperLastModified?: string;
  /**
   * Last modified date of text overrides.
   */
  textLastModified?: string;
  /**
   * Last modified date of page overrides.
   */
  pagesLastModified?: string;

  /**
   * Last modified date of page scripts.
   */
  pageScriptsLastModified?: string;
  /**
   * Last modified date of entity definitions.
   */
  entityDefinitionsLastModified?: string;
  /**
   * Last modified date of field definitions.
   */
  fieldDefinitionsLastModified?: string;
  /**
   * Information about the configured theme.
   */
  themeInfo?: ThemeInfo;
  /**
   * Whether anonymous access to the Community is allowed
   */
  canAccess?: boolean;
  /**
   * Whether the registration feature is available  in the Community
   */
  canRegister?: boolean;
  /**
   * Is the request from a search bot?
   */
  isCrawler?: boolean;
  /**
   * The current page template context.
   */
  pageTemplateContext?: PageContext;
}

export default createContext<AppContextInterface>({});
