import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Guide class for the guide widget
 *
 * @author Amit Agrawal
 */
export class GuidesFeature implements CommunityFeature<Feature.GUIDES> {
  getFeature(): Feature.GUIDES {
    return Feature.GUIDES;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.GUIDES_WIDGET,
        component: dynamic(() => import('../components/guides/GuidesWidget/GuidesWidget')),
        editor: {
          form: dynamic(() => import('../components/guides/GuidesWidgetEditor/GuidesWidgetEditor'))
        },
        allowedPages: [EndUserPages.TkbBoardPage]
      }
    ];
  }
}
