import React, { useCallback, useContext, useEffect } from 'react';
import Head from 'next/head';
import { getStyleContentFromTheme } from '../../../helpers/styles/ThemeRulesHelper';
import ThemeContext from '../../context/ThemeContext/ThemeContext';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import ThemeFontLoader from '../FontLoader/ThemeFontLoader';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';
import { EndUserPages } from '@aurora/shared-types/pages/enums';

interface Props {
  /**
   * The theme to load, if rules are specified this will be ignored. If no rules and no
   * theme is specified, then it will fall back to the theme on the `AppContext`.
   */
  theme?: ThemeResultFragment;

  /**
   * Turns off the font loader. On by default.
   */
  loadFont?: boolean;

  /**
   * Whether to apply the theme rules to the `:root`
   */
  applyToRoot?: boolean;

  /**
   * Whether to apply the theme rules to a section. When applying rules to a section
   * the rules will be scoped under a selector that uses the pattern:
   *
   * `[data-lia-styles="${themeId}"]`
   *
   * Where the `${themeId}` will be the ID of the theme. Components that want the theme applied
   * should use the `ThemeOverride` component which wraps other components in an element that has a
   * matching data attribute which applies the theme to containing component.
   */
  applyToSection?: boolean;

  /**
   * The section id when applying the theme to a section, if none specified it will use the theme id
   */
  sectionId?: string;
}

/**
 * A wrapper component that scopes supplied css variable rules to its contents
 *
 * @author Rosalyn Rowe
 */
const ThemeApplicator: React.FC<React.PropsWithChildren<Props>> = ({
  theme,
  loadFont = true,
  applyToRoot = true,
  applyToSection = false,
  sectionId
}) => {
  const { theme: contextTheme } = useContext(ThemeContext);
  const finalTheme = theme ?? contextTheme;
  const { id } = finalTheme;
  const { router } = useEndUserRoutes();

  const getCssRulesContent = useCallback(
    () => getStyleContentFromTheme(finalTheme, applyToRoot, applyToSection, sectionId),
    [applyToRoot, applyToSection, finalTheme, sectionId]
  );

  /**
   * LIA-87547 Prevent screen using default background color from being used
   * during navigation.
   */
  useEffect(() => {
    if (
      router.getCurrentPageName() !== EndUserPages.PageEditorPage &&
      router.getCurrentPageName() !== EndUserPages.ThemeEditorPage &&
      finalTheme?.colors?.bodyBg
    ) {
      document.body.style.backgroundColor = finalTheme.colors.bodyBg;
    }
  }, [finalTheme, finalTheme?.colors?.bodyBg, router]);

  return (
    <>
      {loadFont && <ThemeFontLoader theme={finalTheme} />}
      <Head key={id}>
        <style type="text/css">{getCssRulesContent()}</style>
      </Head>
    </>
  );
};

export default ThemeApplicator;
