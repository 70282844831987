import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  createGroupHubRedirectBehavior,
  editGroupHubRedirectBehavior,
  manageMembersPageRedirectBehaviour,
  nodePageRedirectBehavior,
  groupsPageRedirectBehavior,
  postGroupHubRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for blogs that holds the node type specific attributes
 * @author Manish Shrestha
 */
export class GroupHubsFeature implements CommunityFeature<Feature.GROUP_HUBS> {
  getFeature(): Feature.GROUP_HUBS {
    return Feature.GROUP_HUBS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.GroupHubsPage,
        endUserModuleRegistry[EndUserComponent.GROUP_HUBS_PAGE],
        pageDescriptorUrlPath[EndUserPages.GroupHubsPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        groupsPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.GroupHubPage,
        endUserModuleRegistry[EndUserComponent.GROUP_HUB_PAGE],
        pageDescriptorUrlPath[EndUserPages.GroupHubPage],
        getEntityScopeForPage(EntityType.GROUP_HUB),
        nodePageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      ),
      new PageDescriptor(
        EndUserPages.GroupHubPostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.GroupHubPostPage],
        getEntityScopeForPage(EntityType.GROUP_HUB),
        postGroupHubRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.ClosedMembershipNodeNonMembersPage,
        endUserModuleRegistry[EndUserComponent.CLOSED_MEMBERSHIP_NODE_NON_MEMBERS_PAGE],
        pageDescriptorUrlPath[EndUserPages.ClosedMembershipNodeNonMembersPage],
        getEntityScopeForPage(EntityType.GROUP_HUB),
        nodePageRedirectBehavior,
        false,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      ),
      new PageDescriptor(
        EndUserPages.ManageMembersPage,
        endUserModuleRegistry[EndUserComponent.MANAGE_MEMBERS_PAGE],
        pageDescriptorUrlPath[EndUserPages.ManageMembersPage],
        getEntityScopeForPage(EntityType.GROUP_HUB),
        manageMembersPageRedirectBehaviour
      ),
      new PageDescriptor(
        EndUserPages.CreateGroupHubPage,
        endUserModuleRegistry[EndUserComponent.CREATE_GROUP_HUB_PAGE],
        pageDescriptorUrlPath[EndUserPages.CreateGroupHubPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        createGroupHubRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.EditGroupHubPage,
        endUserModuleRegistry[EndUserComponent.EDIT_GROUP_HUB_PAGE],
        pageDescriptorUrlPath[EndUserPages.EditGroupHubPage],
        getEntityScopeForPage(EntityType.GROUP_HUB),
        editGroupHubRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.GroupHubMembershipAction,
        '',
        pageDescriptorUrlPath[EndUserPages.GroupHubMembershipAction],
        getEntityScopeForPage(EntityType.GROUP_HUB)
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.GROUP_HUBS_HEADER_WIDGET,
        component: dynamic(
          () => import('../components/grouphubs/GroupHubsHeaderWidget/GroupHubsHeaderWidget')
        ),
        allowedPages: [EndUserPages.GroupHubsPage]
      },
      {
        id: EndUserComponent.CREATE_GROUP_HUB_WIDGET,
        component: dynamic(
          () => import('../components/grouphubs/CreateGroupHubWidget/CreateGroupHubWidget')
        )
      },
      {
        id: EndUserComponent.EDIT_GROUP_HUB_WIDGET,
        component: dynamic(
          () => import('../components/grouphubs/EditGroupHubWidget/EditGroupHubWidget')
        )
      },
      {
        id: EndUserComponent.GROUP_HUB_LIST_FOR_NODE_BY_MEMBERS_COUNT_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/grouphubs/GroupHubListForNodeByMembersCountWidget/GroupHubListForNodeByMembersCountWidget'
            )
        )
      }
    ];
  }
}
