/**
 * Currently the standard for whether or not a numerical validation
 * e.g. min, max, minLength, maxLength should be considered is
 * whether the value is not -1.
 * This centralizes that logic into one place so that it can be
 * universally updated if this standard ever changes.
 */
export function shouldUseNumericalValidation(value: number): boolean {
  return value !== -1;
}
