import type { FormatMessageValues, I18n } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import { SharedComponent } from '../../../enums';
import useTranslation from '../../useTranslation';

const log = getLog(module);

export default function useErrorMessages<T, R>(
  formId: string,
  componentI18n: I18n<T, R>
): (__typename: string, field: string, customArguments?: FormatMessageValues) => string {
  const i18n = useTranslation(SharedComponent.DEFAULT_ERROR_MESSAGES);

  /**
   * Processes mutation errors for a given form, using various methods to display feedback to the user
   * Errors may be presented as either inline errors for form field(s), or a single form feedback or alert
   * Sets focus on the first form field containing errors
   *
   * @param formId the id of the form
   * @param componentI18n the internationalization object
   * @param formLayout the form layout
   */
  return (__typename: string, field: string, customArguments?: FormatMessageValues): string => {
    const { formatMessage: componentFormatMessage, hasMessage: componentHasMessage } =
      componentI18n;
    const { formatMessage, hasMessage } = i18n;

    const defaultKey = `${__typename}.error`;
    const formOverrideKey = `${formId}.${defaultKey}`;
    const fieldOverrideKey = `${formId}.${field}.${defaultKey}`;

    if (componentHasMessage(fieldOverrideKey)) {
      return componentFormatMessage(fieldOverrideKey, customArguments);
    } else if (componentHasMessage(formOverrideKey)) {
      return componentFormatMessage(formOverrideKey, customArguments);
    } else if (hasMessage(defaultKey)) {
      return formatMessage(defaultKey, customArguments);
    } else {
      log.error(
        `No message defined for error with type ${__typename} on the ${formId} form.
      Add a message for the ${__typename} type to the mutations error handler translations, and if needed, form specific
      overrides using the keys ${formOverrideKey} (form) or ${fieldOverrideKey}`
      );

      return formatMessage('Unhandled.error');
    }
  };
}
