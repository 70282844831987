import { useContext } from 'react';
import { AppType } from '@aurora/shared-types/app';
import TenantContext from '../components/context/TenantContext';
import type { AdminPages, AdminQueryParams } from './adminRoutes';
import { getAdminRoutes } from './adminRoutes';
import buildCustomLink from './buildCustomLink';
import type { CustomRouter, RouterAndLink } from './useCustomRouter';
import useCustomRouter from './useCustomRouter';

export type AdminRouter = CustomRouter<AdminPages, AdminQueryParams>;

/**
 * Gets a router and Link contextualized to the current tenant for the Admin app.
 *
 * @author Adam Ayres
 */
export default function useAdminUserRoutes(): RouterAndLink<AdminPages, AdminQueryParams> {
  const { basePath } = useContext(TenantContext) ?? {};
  const routes = getAdminRoutes(basePath);
  const router = useCustomRouter<AdminPages, AdminQueryParams>(routes, AppType.ADMIN);
  return { router, Link: buildCustomLink<AdminPages>(routes, AppType.ADMIN) };
}
