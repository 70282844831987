import useUserManagementProperties from '@aurora/shared-client/components/users/useUserManagementProperties';
import { AccessFunctionType } from '@aurora/shared-types/redirects/pageRedirect';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';

/**
 * Determines whether the user can access the manage user page
 *
 * @param accessFunctionType the access function type
 * @param isAnonymous user access type
 */
export default function useManageUserPageAccess(
  accessFunctionType: AccessFunctionType,
  isAnonymous: boolean
): {
  isManageUsersPageAccessReady: boolean;
  canAccessManageUsersPage: boolean;
} {
  const isManageUsersPage: boolean = accessFunctionType === AccessFunctionType.MANAGE_USERS_PAGE;
  const { data: userManagementData, loading: userManagementLoading } = useUserManagementProperties(
    true,
    !isManageUsersPage || isAnonymous
  );
  const canManageUsers: boolean = checkPolicy(
    userManagementData?.community?.userManagementProperties?.canManageUsers
  );

  if (isManageUsersPage) {
    return {
      isManageUsersPageAccessReady: !userManagementLoading && !!userManagementData?.community,
      canAccessManageUsersPage: canManageUsers
    };
  }

  return {
    isManageUsersPageAccessReady: true,
    canAccessManageUsersPage: false
  };
}
