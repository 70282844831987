import React from 'react';

export interface SsoRegistrationContextInterface {
  /**
   * Toggle the display of the sso registration  modal
   * @param show
   * @param onCompleteRegistration
   */
  showSsoRegistrationModal: (show: boolean, onCompleteRegistration?: () => void) => void;
}

/**
 * Context to toggle the display of the sso registration modal
 *
 * @author Nicolas Pascual
 */
const SsoRegistrationContext = React.createContext<SsoRegistrationContextInterface>({
  showSsoRegistrationModal: () => null
});

export default SsoRegistrationContext;
