import { EndUserPages } from '@aurora/shared-types/pages/enums';
import Head from 'next/head';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import type { PageAndEmptyParams } from '../../../routes/endUserRoutes';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';
import EmptyState from '../../common/EmptyState/EmptyState';
import AppContext from '../../context/AppContext/AppContext';
import ThemeApplicator from '../../themes/ThemeApplicator/ThemeApplicator';
import useTranslation from '../../useTranslation';
import localStyles from './PageError.module.pcss';

interface Props {
  /**
   * The status code.
   */
  statusCode?: number;
  /**
   * The page text.
   */
  title?: string;
  /**
   * The tenant.
   */
}

/**
 * This "internal" component is intended to be used by the `PageError` component, and was separated out
 * to allow the `PageError` component to wrap all the logic in an `ErrorBoundary` component that can
 * fall back to a `StaticPageError` in the off chance the error that occurred is in the logic of this
 * component.
 */
const PageError: React.FC<React.PropsWithChildren<Props>> = ({
  statusCode = 404,
  title
}): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.PAGE_ERROR);
  const { Link } = useEndUserRoutes();
  const { community } = useContext(AppContext);

  if (textLoading) {
    return null;
  }

  const headTitleKey = community?.title ? 'headTitle' : 'headTitleNoCommunity';
  const headTitle = formatMessage(headTitleKey, { statusCode, communityTitle: community?.title });
  const pageTitle = title ?? formatMessage('pageTitle', { statusCode });

  return (
    <>
      <Head>
        <title>{headTitle}</title>
      </Head>
      <ThemeApplicator />
      <div className={cx('lia-container')} data-testid="PageError">
        <EmptyState title={pageTitle} description={formatMessage('description', { statusCode })}>
          <Link<PageAndEmptyParams> route={EndUserPages.CommunityPage}>
            <a className={cx('d-block small')}>{formatMessage('redirect')}</a>
          </Link>
        </EmptyState>
      </div>
    </>
  );
};

export default PageError;
