import { useApolloClient } from '@apollo/client';
import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { DEFAULT_LANGUAGE } from '@aurora/shared-utils/helpers/i18n/defaultLanguage';
import IntlHelper from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import type { BaseRouteAndParams } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import usePageLoadingGlobalState from '../../helpers/ui/PageLoadingGlobalState';
import type { RouteWithOptions } from '../../routes/useCustomRouter';
import useEndUserRoutes from '../../routes/useEndUserRoutes';
import { AppType } from '@aurora/shared-types/app';
import useLocalizedCategoriesFeatureEnabled from '../community/useLocalizedCategoriesFeatureEnabled';
import AppTypeContext from '../context/AppTypeContext';
import IntlWrapperContext from '../context/IntlContext/IntlWrapperContext';
import useToasts from '../context/ToastContext/useToasts';

const log = getLog(module);

const pageListToRetainGlobalStateLoader: Set<EndUserPages> = new Set([
  EndUserPages.GroupHubMembershipAction
]);

/**
 * A hook that provides a function to handle state changes during authentication transitions (login, logout).
 */
export default function useAuthTransition<RouteType extends EndUserPages>(
  routeWithTransition: RouteWithOptions<RouteType, EndUserQueryParams> = null
): (isLogin?: boolean) => Promise<void> {
  const client = useApolloClient();
  const currentApp = useContext(AppTypeContext);
  const { enabled: isLocalizedCategoriesFeatureEnabled } =
    useLocalizedCategoriesFeatureEnabled(module);

  const [, setPageLoading] = usePageLoadingGlobalState('pageLoading');
  const { router, loading: routesLoading } = useEndUserRoutes();
  const { clearToasts } = useToasts();
  const { setLocale } = useContext(IntlWrapperContext);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  async function handleTransition(): Promise<void> {
    try {
      setPageLoading(true);
      clearToasts();

      if (currentApp === AppType.END_USER) {
        // when logging out on the end user application manage cache as we are staying in the same application
        await client.clearStore();
        await client.reFetchObservableQueries(false);
      }

      if (!isLocalizedCategoriesFeatureEnabled) {
        //set the locale from the cookie
        const localeCookieData = IntlHelper.getLocaleFromCookie();
        //TODO: this will we handle correctly in other story.
        const isValidLocale = localeCookieData && !['null', 'undefined'].includes(localeCookieData);
        setLocale(isValidLocale ? localeCookieData : DEFAULT_LANGUAGE);
      }

      // do the redirect after the re-fetch so Aurora context is up-to-date in the next page
      if (routeWithTransition?.route) {
        const { route, params, query, options } = routeWithTransition;
        await router.pushRoute<BaseRouteAndParams<RouteType>>(route, params, query, options);
      }
    } catch (error) {
      log.error(error, 'Error in auth transition');
    } finally {
      if (
        !routesLoading &&
        currentApp === AppType.END_USER &&
        !pageListToRetainGlobalStateLoader.has(routeWithTransition?.route)
      ) {
        //if the auth changes, fire an event for any custom Javascript listeners
        global.dispatchEvent(new CustomEvent('kh-aurora:authChange'));

        // for internal routing within the enduser app disable the loader, but for external routing (from the admin app
        // to the enduser app) keep the loader
        setPageLoading(false);
      }
    }
  }

  return handleTransition;
}
