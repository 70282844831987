import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { createContext } from 'react';

export interface PageContextInterface {
  /**
   * Module of the page
   */
  pageId: EndUserPages;

  /**
   * template id of the page if the page is using a template
   */
  templateId?: string;
}

/**
 * Context specific to a given page.
 */
const PageContext = createContext<PageContextInterface>({
  pageId: null
});

export default PageContext;
