import { useContext } from 'react';
import type { ToastContextInterface } from './ToastContext';
import ToastContext from './ToastContext';

/**
 * Convenience hook that allows toast functions to be used wherever they are needed.
 *
 * @author Jonathan Bridges
 */
export default function useToasts(): ToastContextInterface {
  return useContext(ToastContext);
}
