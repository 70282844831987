import type { QueryResult } from '@apollo/client';
import containerNodeQuery from '@aurora/shared-client/components/nodes/ContainerNode.query.graphql';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { CoreNode } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ContextNodeFragment } from '@aurora/shared-generated/types/graphql-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { AccessFunctionType } from '@aurora/shared-types/redirects/pageRedirect';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getSupportedNodeTypes } from '../helpers/nodes/NodeHelper/NodeHelper';
import type {
  MembershipInformationFragment,
  ContainerNodeQuery,
  ContainerNodeQueryVariables
} from '../types/graphql-types';
import useMembershipDetails from './memberships/useMembershipDetails';

function useContainerNodeQuery(
  skip: boolean,
  nodeId: string
): QueryResult<ContainerNodeQuery, ContainerNodeQueryVariables> {
  return useQueryWithTracing<ContainerNodeQuery, ContainerNodeQueryVariables>(
    module,
    containerNodeQuery,
    {
      variables: {
        id: nodeId,
        useCanCreateNode: true
      },
      skip: skip,
      fetchPolicy: 'network-only'
    }
  );
}

/**
 * Determines whether the user can access the group hub page
 *
 * @param accessFunctionType the access function type
 * @param parentId the id of the parent node to validate weather the user can create groups
 * @param contextNode the context node query result from the AppContextProvider
 */
export default function useGroupHubPageAccess(
  accessFunctionType: AccessFunctionType,
  parentId: string,
  contextNode: ContextNodeFragment
): {
  isGroupPageAccessReady: boolean;
  canAccessGroupPage: boolean;
} {
  const isCreateGroupPage: boolean =
    accessFunctionType === AccessFunctionType.CREATE_GROUP_HUB_PAGE;
  const isEditGroupPage: boolean = accessFunctionType === AccessFunctionType.EDIT_GROUP_HUB_PAGE;
  const isPostGroupPage: boolean = accessFunctionType === AccessFunctionType.POST_GROUP_HUB_PAGE;
  const isManageMembersPage: boolean =
    accessFunctionType === AccessFunctionType.MANAGE_MEMBERS_PAGE;

  const containerNodeQueryResult = useContainerNodeQuery(!isCreateGroupPage || !parentId, parentId);

  const { data: membershipDetailsData, loading: membershipDetailsLoading } = useMembershipDetails(
    {
      id: contextNode?.id,
      useMembershipInformation: true
    },
    !isManageMembersPage || !contextNode?.id
  );

  if (isCreateGroupPage && !!parentId) {
    const { data: containerNodeQueryData, loading: containerNodeQueryLoading } =
      containerNodeQueryResult;
    let allowedNodeTypes: NodeType[] = [];
    let createGroupHubPageAccess = false;
    if (!containerNodeQueryLoading && !!containerNodeQueryData?.coreNode) {
      const { allowedNodeTypes: types } = getSupportedNodeTypes(containerNodeQueryData as CoreNode);
      allowedNodeTypes = types;
      createGroupHubPageAccess = !!allowedNodeTypes.includes(NodeType.GROUPHUB);
    }
    return {
      isGroupPageAccessReady: !containerNodeQueryLoading && !!containerNodeQueryData?.coreNode,
      canAccessGroupPage: createGroupHubPageAccess
    };
  } else if (isEditGroupPage) {
    const canEditGroupHub = contextNode ? contextNode.userContext.canUpdateNode : false;
    return {
      isGroupPageAccessReady: !!contextNode,
      canAccessGroupPage: canEditGroupHub
    };
  } else if (isPostGroupPage) {
    const { canPostMessages } = contextNode ? contextNode.userContext : { canPostMessages: false };
    return {
      isGroupPageAccessReady: !!contextNode,
      canAccessGroupPage: canPostMessages
    };
  } else if (isManageMembersPage) {
    const membershipInformation = membershipDetailsData?.coreNode as MembershipInformationFragment;
    return {
      isGroupPageAccessReady:
        !!contextNode && !membershipDetailsLoading && !!membershipDetailsData?.coreNode,
      canAccessGroupPage: contextNode
        ? checkPolicy(membershipInformation?.membershipPolicies?.canManageMembers)
        : false
    };
  }

  return {
    isGroupPageAccessReady: true,
    canAccessGroupPage: false
  };
}
