/**
 * Helper for working with Components.
 */
import type { MutableRefObject } from 'react';

export interface ElementFocusHandler {
  /**
   * Callback to focus an element.
   *
   * @callback
   * @param element the element to focus
   */
  (element: Partial<HTMLElement>): void;
}

/**
 * Focuses the element passed as an argument. Requires a timeout to circumvent focus applied by Modal.
 * See https://github.com/react-bootstrap/react-overlays/issues/289
 *
 * @param element the element to focus
 */
function defaultFocusHandler(element: HTMLElement): void {
  if (element?.focus) {
    setTimeout(() => {
      element.focus();
    }, 0);
  }
}

// eslint-disable-next-line unicorn/no-static-only-class
export default class ComponentHelper {
  /**
   * Shim to support focusing a react-bootstrap <code>Form.Control</code> or other input when
   * used inside a <code>Modal</code>.
   *
   * Focuses the input after modal opens.
   *
   * @param hasFocusedReference whether or not the element has been focused
   * @param element the element
   * @param focusHandler a function to focus the element
   */
  static focusHandler(
    hasFocusedReference: MutableRefObject<boolean>,
    element: Partial<HTMLElement>,
    focusHandler: ElementFocusHandler = defaultFocusHandler
  ) {
    if (hasFocusedReference && !hasFocusedReference.current && element) {
      // eslint-disable-next-line no-param-reassign
      hasFocusedReference.current = true;
      focusHandler(element);
    }
  }

  /**
   * Helper to set the <code>displayName</code> property on HoC.
   *
   * @param hoc - The HoC.
   * @param hocName - The name of the HoC.
   * @param Component - The wrapped component.CachingQuiltProvider.ts
   */
  static setDisplayName<T>(
    hoc: React.ComponentType<React.PropsWithChildren<T>>,
    hocName: string,
    Component: React.ComponentType<React.PropsWithChildren<unknown>> | string
  ): React.ComponentType<React.PropsWithChildren<T>> {
    if (process.env.NODE_ENV !== 'production') {
      let displayName;
      if (typeof Component === 'string') {
        displayName = Component;
      } else {
        displayName = (Component && (Component.displayName || Component.name)) || 'Component';
      }

      // eslint-disable-next-line no-param-reassign
      hoc.displayName = `${hocName}(${displayName})`;
    }
    return hoc;
  }
}
