import type { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button as BootstrapButton, useClassNameMapper } from 'react-bootstrap';
import { SwitchTransition } from 'react-transition-group';
import { getButtonVariant } from '../../../helpers/styles/ButtonStyleHelper';
import { LoadingSize, LoadingSpacing } from '../../../types/enums';
import { LoadingVariant } from '../Loading/enums';
import Loading from '../Loading/Loading';
import type { LoadingVariantTypeAndProps } from '../Loading/types';
import Transition, { TransitionVariant } from '../Transition/Transition';
import localStyles from './Button.module.pcss';
import { ButtonVariant } from './enums';
import type { LoadingButtonProps } from './types';

/**
 * A wrapper around the `react-bootstrap` `Button` component that
 * adds support for a loading icon to display when an action occurs,
 * such as a form submit.
 *
 * @author Adam Ayres, Willi Hyde
 */
const Button: ForwardRefExoticComponent<
  PropsWithoutRef<LoadingButtonProps> & RefAttributes<HTMLButtonElement>
> = forwardRef(function ButtonForward(
  {
    className,
    loading,
    variant = ButtonVariant.PRIMARY,
    onClick,
    children,
    size,
    block,
    active,
    disabled,
    href,
    type,
    title,
    as,
    name,
    testId = 'Button',
    ...props
  },
  ref
) {
  const cx = useClassNameMapper(localStyles);
  const finalVariant = getButtonVariant(variant);

  function getLoadingVariant(): LoadingVariantTypeAndProps {
    return {
      type: LoadingVariant.DOT,
      props: {
        size: LoadingSize.SM,
        spacing: LoadingSpacing.NONE,
        dotClassName: cx('lia-loading-color')
      }
    };
  }

  return (
    <SwitchTransition>
      <Transition<HTMLElement>
        key={`loading:${loading}`}
        addEndListener={(node, done): void => node.addEventListener('transitionend', done, false)}
        variant={TransitionVariant.FADE}
      >
        <BootstrapButton
          title={title}
          variant={finalVariant}
          size={size}
          block={block}
          active={active}
          disabled={loading ? true : disabled}
          href={href}
          type={type}
          as={as}
          onClick={onClick}
          className={cx(
            {
              'lia-g-unstyled-btn': variant === ButtonVariant.UNSTYLED
            },
            className
          )}
          data-testid={testId}
          ref={ref}
          name={name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {loading ? (
            <Loading
              variant={getLoadingVariant()}
              className={cx('lia-load-size', size && `lia-load-size-${size}`)}
            />
          ) : (
            children
          )}
        </BootstrapButton>
      </Transition>
    </SwitchTransition>
  );
});

export default Button;
