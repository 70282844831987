import {
  clearToggleTextKeysEnabledUserIdCookie,
  getToggleTextKeysUserIdFromCookie,
  LITHIUM_TEXT_TOGGLE_COOKIE
} from '@aurora/shared-apollo/helpers/ToggleTextKeysHelper';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import ToggleTextKeysContext from '@aurora/shared-client/components/context/ToggleTextKeysContext/ToggleTextKeysContext';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import React, { useContext, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import ToggleTextKeysFloatingBox from '../ToggleTextKeysFloatingBox/ToggleTextKeysFloatingBox';
import Cookies from 'universal-cookie';

/**
 * Set that contains the list of EndUserPages where the toggle box should NOT be displayed.
 */
const BYPASS_TOGGLE_TEXT_KEYS_PAGES = new Set([
  EndUserPages.ThemeEditorPage,
  EndUserPages.PageEditorPage
]);

const cookies = new Cookies();

/**
 * Context provider to toggle text keys.
 *
 * @author Agustin Sosa
 */
const ToggleTextKeysContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authUser } = useContext(AppContext);
  const { router } = useEndUserRoutes();
  const [showTextKeys, setShowTextKeys] = useState<boolean>(false);
  const [showToggleBox, setShowToggleBox] = useState<boolean>(false);

  useIsomorphicLayoutEffect(() => {
    // Keep track if cookie is added or deleted.
    const cookieChangeListener = ({ name }) => {
      if (name === LITHIUM_TEXT_TOGGLE_COOKIE) {
        const userId = getToggleTextKeysUserIdFromCookie();
        setShowToggleBox(userId === authUser?.uid);
        setShowTextKeys(userId === authUser?.uid);
      }
    };
    cookies.addChangeListener(cookieChangeListener);
    return () => {
      cookies.removeChangeListener(cookieChangeListener);
    };
  }, [authUser?.uid]);

  useIsomorphicLayoutEffect(() => {
    // On page navigation, check if toggle box should be displayed based on authUser and if page is bypassed.
    const userId = getToggleTextKeysUserIdFromCookie();
    const newPageId = router.getCurrentPageName();
    if (BYPASS_TOGGLE_TEXT_KEYS_PAGES.has(newPageId)) {
      setShowToggleBox(false);
      setShowTextKeys(false);
    } else if (!showToggleBox && userId !== null && authUser?.uid === userId) {
      setShowToggleBox(true);
      setShowTextKeys(true);
    }
  }, [authUser?.uid, router, router.path, showToggleBox]);

  /**
   * Hides Toggle box and removes cookie.
   */
  const onCloseToggleBox = () => {
    clearToggleTextKeysEnabledUserIdCookie();
    setShowTextKeys(false);
    setShowToggleBox(false);
  };

  return (
    <ToggleTextKeysContext.Provider value={{ showTextKeys: showTextKeys }}>
      {showToggleBox && (
        <ToggleTextKeysFloatingBox
          onCloseToggleTextKeys={onCloseToggleBox}
          onShowTextKeysToggle={() => setShowTextKeys(!showTextKeys)}
          showTextKeys={showTextKeys}
        />
      )}
      {children}
    </ToggleTextKeysContext.Provider>
  );
};

export default ToggleTextKeysContextProvider;
