import ThemeContext from '../../../components/context/ThemeContext/ThemeContext';
import React, { useContext } from 'react';
import type { FontDefinition } from '../../../helpers/fonts/FontDefinitions';
import FontDefinitions, { FontClassification } from '../../../helpers/fonts/FontDefinitions';
import FontLoader from './FontLoader';
import { parseCustomFonts, parseFontFamily } from '../../../helpers/styles/FontHelper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';

interface Props {
  /**
   * The theme to load, if no theme is specified, then it will fall
   * back to the theme on the `AppContext`.
   */
  theme?: ThemeResultFragment;
}

/**
 * A wrapper that uses the context
 * @author Rosalyn Rowe, Willi Hyde
 */
const ThemeFontLoader: React.FC<React.PropsWithChildren<Props>> = ({ theme }) => {
  const { theme: contextTheme } = useContext(ThemeContext);

  const finalTheme = theme ?? contextTheme;

  if (!finalTheme?.typography) {
    return null;
  }

  const hardcodedFonts: FontDefinition[] = FontDefinitions.fonts.filter(
    font => font.classification !== FontClassification.CUSTOM
  );

  const customFonts: FontDefinition[] = parseCustomFonts(finalTheme?.typography?.customFonts);

  const allAvailableFonts = [...hardcodedFonts, ...customFonts];
  const headingFontName = finalTheme?.heading?.fontFamily;
  const bodyFontName = finalTheme?.typography?.fontFamilyBase;
  const validFontNames = new Set([
    ...(headingFontName ? parseFontFamily(headingFontName) : []),
    ...(bodyFontName ? parseFontFamily(bodyFontName) : [])
  ]);

  const fontsToLoad = allAvailableFonts.filter(({ family }) => validFontNames.has(family));

  return fontsToLoad.length > 0 ? <FontLoader fonts={fontsToLoad} /> : null;
};

export default ThemeFontLoader;
