import type { I18n } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type { SharedComponent } from '../enums';
import { getModulePathForComponent } from '../sharedModuleRegistry';
import AppContext from './context/AppContext/AppContext';
import { useI18n } from './useI18n';
import useText from './useText';

const log = getLog(module);

export default function useTranslation<T, R>(
  componentId: SharedComponent,
  ignoreProvisionedText?: boolean
): I18n<T, R> {
  const namespace = getModulePathForComponent(componentId);
  const { textLastModified } = useContext(AppContext);

  const { loading, data, error: queryError, refetch } = useText(namespace, textLastModified);

  const i18n = useI18n(
    namespace,
    loading ? {} : data,
    null,
    ignoreProvisionedText,
    null,
    null,
    componentId
  );

  if (queryError) {
    log.error(queryError, 'Error loading text for namespaces: %s', namespace);
  }

  return {
    loading,
    refetch,
    ...i18n
  };
}
