import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  boardPageRedirectBehavior,
  editPageRedirectBehavior,
  messagePageRedirectBehavior,
  messageReplyPageRedirectBehavior,
  noRedirectBehavior,
  postPageRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for events.
 *
 * @author Aditi Agarwal
 */
export class EventsFeature implements CommunityFeature<Feature.EVENTS> {
  getFeature(): Feature.EVENTS {
    return Feature.EVENTS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.EventBoardPage,
        endUserModuleRegistry[EndUserComponent.EVENT_BOARD_PAGE],
        pageDescriptorUrlPath[EndUserPages.EventBoardPage],
        getEntityScopeForPage(EntityType.EVENT),
        boardPageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      ),
      new PageDescriptor(
        EndUserPages.OccasionMessagePage,
        endUserModuleRegistry[EndUserComponent.OCCASION_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.OccasionMessagePage],
        getEntityScopeForPage(EntityType.OCCASION_TOPIC),
        messagePageRedirectBehavior,
        true,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.OccasionReplyPage,
        endUserModuleRegistry[EndUserComponent.OCCASION_MESSAGE_PAGE],
        pageDescriptorUrlPath[EndUserPages.OccasionReplyPage],
        getEntityScopeForPage(EntityType.OCCASION_REPLY),
        messageReplyPageRedirectBehavior,
        false,
        null,
        false
      ),
      new PageDescriptor(
        EndUserPages.EventPostPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.EventPostPage],
        getEntityScopeForPage(EntityType.EVENT),
        postPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.OccasionEditPage,
        endUserModuleRegistry[EndUserComponent.POST_PAGE],
        pageDescriptorUrlPath[EndUserPages.OccasionEditPage],
        getEntityScopeForPage(EntityType.EVENT),
        editPageRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.AllOccasionsPage,
        endUserModuleRegistry[EndUserComponent.ALL_OCCASIONS_PAGE],
        pageDescriptorUrlPath[EndUserPages.AllOccasionsPage],
        getEntityScopeForPage(EntityType.EVENT),
        noRedirectBehavior,
        false,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget'))
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.OCCASION_LIST_FOR_NODE_WIDGET,
        component: dynamic(
          () =>
            import('../components/occasions/OccasionListForNodeWidget/OccasionListForNodeWidget')
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/occasions/OccasionListForNodeWidgetEditor/OccasionListForNodeWidgetEditor'
              )
          )
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.EventBoardPage
        ]
      },
      {
        id: EndUserComponent.OCCASION_MESSAGE_COVER_WIDGET,
        component: dynamic(
          () =>
            import('../components/occasions/OccasionMessageCoverWidget/OccasionMessageCoverWidget')
        )
      },
      {
        id: EndUserComponent.OCCASION_TOPIC_WIDGET,
        component: dynamic(
          () => import('../components/occasions/OccasionTopicWidget/OccasionTopicWidget')
        )
      },
      {
        id: EndUserComponent.OCCASION_LOCATION_AND_VIDEO_PREVIEW_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/occasions/OccasionLocationAndVideoPreviewWidget/OccasionLocationAndVideoPreviewWidget'
            )
        )
      }
    ];
  }
}
