import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

export class SearchFeature implements CommunityFeature<Feature.SEARCH> {
  getFeature(): Feature.SEARCH {
    return Feature.SEARCH;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.SEARCH_WIDGET,
        component: dynamic(() => import('../components/search/SearchWidget/SearchWidget'))
      }
    ];
  }
}
