import { buildPrefixedThemeBundleId } from '@aurora/shared-apollo/helpers/ApolloThemeCacheHelper';
import type { CachedAssetFragment } from '@aurora/shared-generated/types/graphql-types';
import useCachedFragment from './useCachedFragment';

/**
 * Hook for fetching a theme from the Apollo cache
 * @param themeId The ID of the theme
 * @param lastModified Last modified timestamp
 * @returns Cached theme, or null
 */
export default function useCachedThemeBundle(
  themeId: string,
  lastModified: string
): CachedAssetFragment {
  const bundleId = buildPrefixedThemeBundleId(themeId, lastModified);

  const cachedBundle = useCachedFragment(bundleId);

  return cachedBundle;
}
