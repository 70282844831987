import Cookies from 'universal-cookie';
import { canUseDOM } from 'exenv';

const cookies = new Cookies();
const LOCAL_STORAGE_SHIM_COOKIE_ID = 'kh-local-storage';

/**
 * Creates a localStorage shim that uses a cookie for storage.
 */
export default function getLocalStorageShim(): Storage {
  function setCookie(value: Record<string, string>): void {
    try {
      const finalValue = JSON.stringify(value);
      cookies.set(LOCAL_STORAGE_SHIM_COOKIE_ID, finalValue, {
        path: '/'
      });
    } catch {}
  }

  // Initialize the storage
  if (!cookies.get(LOCAL_STORAGE_SHIM_COOKIE_ID)) {
    setCookie({});
  }

  let cookie;
  try {
    cookie = JSON.parse(cookies.get(LOCAL_STORAGE_SHIM_COOKIE_ID));
  } catch {
    cookie = {};
  }

  return {
    length: Object.keys(cookie).length,
    key(index: number): string {
      return Object.keys(cookie)[index];
    },
    getItem(key: string): string | null {
      return cookie[key] ?? null;
    },
    setItem(key: string, value: string): void {
      cookie[key] = value;
      setCookie(cookie);
    },
    removeItem(key: string): void {
      delete cookie[key];
      setCookie(cookie);
    },
    clear() {
      setCookie({});
    }
  };
}

/**
 * Whether localStorage is supported. We need to check both for the existence of the object
 * and whether it is null.
 *
 * https://stackoverflow.com/questions/11214404/how-to-detect-if-browser-supports-html5-local-storage
 */
function canUseLocalStorage() {
  return 'localStorage' in window && window.localStorage != null;
}

/**
 * Polyfill localStorage if not available.
 */
if (canUseDOM && !canUseLocalStorage()) {
  Object.defineProperty(global, 'localStorage', { value: getLocalStorageShim() });
}
