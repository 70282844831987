import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import React from 'react';

export interface ThemeContextInterface {
  theme: ThemeResultFragment;
}
const ThemeContext = React.createContext<ThemeContextInterface>({
  theme: null
});

export default ThemeContext;
