import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { nodePageRedirectBehavior } from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for categories that holds the node style specific attributes
 * @author Manish Shrestha
 */
export class CategoriesFeature implements CommunityFeature<Feature.CATEGORIES> {
  getFeature(): Feature.CATEGORIES {
    return Feature.CATEGORIES;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.CategoryPage,
        endUserModuleRegistry[EndUserComponent.CATEGORY_PAGE],
        pageDescriptorUrlPath[EndUserPages.CategoryPage],
        getEntityScopeForPage(EntityType.CATEGORY),
        nodePageRedirectBehavior,
        true,
        dynamic(() => import('../components/community/NodeBannerWidget/NodeBannerWidget')),
        false
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [];
  }
}
