import type { Tenant } from '@aurora/shared-types/tenant';
import { getThemeAssetURL } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import { getFileExtension } from '../images/FileHelper';
import type { FontDefinition, FontFace } from './FontDefinitions';
import { FontClassification } from './FontDefinitions';
import type { FontStyleDefinition } from './types';

/**
 * Return the font asset name
 *
 * @param {FontFace} font - An array of FontFace objects representing the fonts to process.
 * @returns {string} The font asset name
 */
export function getFontAssetName(font: FontFace): string {
  return `${font.family.replaceAll(/\s/g, '')}-${font.style.toLowerCase()}-${font.weight}.woff2`;
}
/**
 * Generates a font file name based on the font display name and font style.
 * @param {string} fontDisplayName - The display name of the font.
 * @param {string} fontStyle - The style of the font.
 * @param {number} fontWeight - The weight of the font.
 * @param {boolean} isCustom -  if is a custom font.
 * @param {string[]} assetNames - The font asset names
 * @returns {string} Returns the generated font file name.
 */
export function generateFontFileName(
  fontDisplayName: string,
  fontStyle: string,
  fontWeight: number,
  isCustom: boolean,
  assetNames?: string[]
): string {
  const formattedFontName = fontDisplayName.replaceAll(/\s/g, '');
  const finalName = `${formattedFontName}-${fontStyle.toLowerCase()}-${fontWeight}`;
  return isCustom ? assetNames.find(asset => asset.includes(finalName)) : `${finalName}.woff2`;
}

/**
 * Generates a custom font URL based on the font display name, font style, tenant, and theme ID.
 * @param {string} fontDisplayName - The display name of the font.
 * @param {string} fontStyle - The style of the font.
 * @param {number} fontWeight - The weight of the font.
 * @param {Tenant} tenant - The tenant object representing the tenant information.
 * @param {string} themeId - The theme ID representing the current theme.
 * @param {string[]} assetNames - The font asset names
 * @param {string} branchName if not on the main branch, the branch name
 * @returns {string} Returns the URL for the custom font.
 * @returns {string[]} The font assets name.
 */
function getCustomFontUrl(
  fontDisplayName: string,
  fontStyle: string,
  fontWeight: number,
  tenant: Tenant,
  themeId: string,
  assetNames: string[],
  branchName?: string
): string {
  return getThemeAssetURL(
    tenant,
    themeId,
    generateFontFileName(fontDisplayName, fontStyle, fontWeight, true, assetNames),
    null,
    null,
    branchName
  );
}

/**
 * Generates a local URL for a Google font based on the font display name and font style.
 * @param {Tenant} tenant - The Tenant.
 * @param {string} fontDisplayName - The display name of the font.
 * @param {string} fontStyle - The style of the font.
 * @param {number} fontWeight - The style of the font.
 * @returns {string} Returns the local URL for the Google font.
 */
function getLocalGoogleFont(
  tenant: Tenant,
  fontDisplayName: string,
  fontStyle: string,
  fontWeight: number
): string {
  return UrlHelper.prefixBasePath(
    tenant,
    `/static/fonts/googleFonts/${generateFontFileName(
      fontDisplayName,
      fontStyle,
      fontWeight,
      false
    )}`
  );
}

/**
 * Generates a font URL based on the provided parameters.
 * @param {string} fontDisplayName - The display name of the font.
 * @param {string} fontStyle - The style of the font.
 * @param {number} fontWeight - The weight of the font.
 * @param {FontClassification} fontClassification - The classification of the font (CUSTOM or GOOGLE).
 * @param {string[]} assetNames - The font assets.
 * @param {Tenant} tenant - The tenant.
 * @param {string} themeId - The theme ID representing the current theme.
 * @param {string} branchName if not on the main branch, the branch name
 * @returns {string} Returns the URL for the font based on the provided parameters.
 */
function getFontUrl(
  fontDisplayName: string,
  fontStyle: string,
  fontWeight: number,
  fontClassification: FontClassification,
  assetNames: string[],
  tenant: Tenant,
  themeId: string,
  branchName?: string
): string {
  if (!fontDisplayName || !fontStyle || !tenant || !themeId) {
    throw new Error('Invalid or missing parameter(s).');
  }

  if (fontClassification === FontClassification.CUSTOM) {
    return getCustomFontUrl(
      fontDisplayName,
      fontStyle,
      fontWeight,
      tenant,
      themeId,
      assetNames,
      branchName
    );
  } else {
    return getLocalGoogleFont(tenant, fontDisplayName, fontStyle, fontWeight);
  }
}

/**
 * Generates a CSS @font-face declaration for a font based on the provided definitions.
 * @param {FontDefinition} font - The font definition containing font family and classification.
 * @param {FontStyleDefinition} fontStyle - The font style definition containing style and weight.
 * @param {Tenant} tenant - The tenant object representing the tenant information.
 * @param {string} themeId - The theme ID representing the current theme.
 * @param {string} branchName if not on the main branch, the branch name
 * @returns {string} Returns the CSS @font-face declaration as a string.
 */
function getFontFaceDeclaration(
  font: FontDefinition,
  fontStyle: FontStyleDefinition,
  tenant: Tenant,
  themeId: string,
  branchName?: string
): string {
  const fontUrl = getFontUrl(
    font.family,
    fontStyle.style,
    fontStyle.weight,
    font.classification,
    font.assetNames,
    tenant,
    themeId,
    branchName
  );
  const fontStyleLower = fontStyle.style.toLowerCase();
  const formatBlock = getFileExtension(fontUrl) === 'woff2' ? `format('woff2')` : '';

  const sourceDeclaration =
    font.classification !== FontClassification.LOCAL
      ? `src: local("${font.family}-${fontStyleLower}-${fontStyle.weight}"), url("${fontUrl}") ${formatBlock}`
      : `src: local("${font.family}-${fontStyleLower}-${fontStyle.weight}")`;

  return `@font-face {
    font-family: "${font.family}";
    font-style: ${fontStyleLower};
    font-weight: ${fontStyle.weight};
    font-display: swap;
    ${sourceDeclaration};
  }`;
}

/**
 * Generates a CSS string containing @font-face declarations for the provided fonts and styles.
 * @param {FontDefinition[]} fonts - An array of font definitions containing font families and classifications.
 * @param {Tenant} tenant - The tenant object representing the tenant information.
 * @param {string} themeId - The theme ID representing the current theme.
 * @param {string} branchName if not on the main branch, the branch name
 * @returns {string} Returns the generated CSS string with @font-face declarations.
 */
export function generateFontFaceCSS(
  fonts: FontDefinition[],
  tenant: Tenant,
  themeId: string,
  branchName?: string
): string {
  const fontFaceDeclarations = fonts.flatMap(font =>
    font.styles.map(fontStyle =>
      getFontFaceDeclaration(font, fontStyle, tenant, themeId, branchName)
    )
  );

  return fontFaceDeclarations.join('\n');
}
