import dynamic from 'next/dynamic';
import React, { useCallback, useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import { TransitionGroup } from 'react-transition-group';
import Icons from '../../../icons';
import useRoutesForCurrentApp from '../../../routes/useRoutesForCurrentApp';
import { ToastAlertVariant, ToastVariant } from '../../common/ToastAlert/enums';
import type ToastProps from '../../common/ToastAlert/ToastAlertProps';
import Transition, { TransitionVariant } from '../../common/Transition/Transition';
import ToastContext from './ToastContext';
import localStyles from './ToastContext.module.pcss';
import { useToastGlobalState } from './ToastGlobalState';

const ToastAlert = dynamic<ToastProps>(() => import('../../common/ToastAlert/ToastAlert'));

export interface IconProps {
  /**
   * className - Class name(s) to apply to the toast icon.
   */
  className: string;
}

interface Props {
  /**
   * Allows for a custom mapping of alert variant to icon
   */
  variantToIconMap?: Record<ToastAlertVariant, Icons>;
}

const defaultVariantToIconMap: Record<ToastAlertVariant, Icons> = {
  [ToastAlertVariant.INFO]: Icons.InfoIcon,
  [ToastAlertVariant.WARNING]: Icons.FlagIcon,
  [ToastAlertVariant.DANGER]: Icons.DangerIcon,
  [ToastAlertVariant.SUCCESS]: Icons.CheckmarkIcon
};

/**
 * Stores toasts across the application in a queue via the ToastContextProvider.
 *
 * @author Jonathan Bridges
 */
const ToastContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  variantToIconMap = defaultVariantToIconMap
}) => {
  const cx = useClassNameMapper(localStyles);
  const [toasts, setToasts] = useToastGlobalState();
  const { router, loading: routesLoading } = useRoutesForCurrentApp();

  /**
   * Returns whether a toast with the provided toast id already exists in the global toast state
   *
   * @param toastId the id of the toast
   */
  const containsToast = useCallback(
    (toastId: ToastProps['id']): boolean => {
      return toasts.some((toast: ToastProps) => toast.id === toastId);
    },
    [toasts]
  );

  /**
   * Adds multiple toasts to the global toast state
   *
   * @param newToasts an array of new toasts
   */
  const addToasts = useCallback(
    (newToasts: Array<ToastProps>): void => {
      const uniqueToasts: Array<ToastProps> = newToasts.filter(
        newToast => !containsToast(newToast.id)
      );
      if (uniqueToasts.length > 0) {
        setToasts([...toasts, ...uniqueToasts]);
      }
    },
    [containsToast, setToasts, toasts]
  );

  /**
   * Adds a single toast to the global toast state
   *
   * @param newToast the toast to add
   */
  const addToast = useCallback((newToast: ToastProps): void => addToasts([newToast]), [addToasts]);

  /**
   * Removes a single toast from the global toast state
   *
   * @param toastId the id of the toast to remove
   */
  const removeToast = useCallback(
    (toastId: ToastProps['id']): void => {
      if (containsToast(toastId)) {
        setToasts(toasts.filter((toast: ToastProps) => toast.id !== toastId));
      }
    },
    [containsToast, setToasts, toasts]
  );

  /**
   * Removes all toasts from the global toast state
   */
  const clearToasts = useCallback((): void => {
    if (toasts.length > 0) {
      setToasts([]);
    }
  }, [setToasts, toasts.length]);

  /**
   * Checks whether the route path has changed when routing starts and removes all non-persistent toasts from the page
   *
   */
  useEffect(() => {
    const routeChangeStart = url => {
      const { route: currentRoute, params: currentRouteParams } = router.getRouteAndParamsByPath(
        router.asPath
      );
      if (url) {
        const { route: destinationRoute, params: destinationRouteParams } =
          router.getRouteAndParamsByPath(url);

        // Destructring the route params avoids a known issue where objects with null prototype cause fast-deep-equal to throw an error
        // Reference - https://github.com/epoberezkin/fast-deep-equal/issues/49
        if (
          (currentRoute !== destinationRoute ||
            !isEqual({ ...currentRouteParams }, { ...destinationRouteParams })) &&
          toasts.length > 0
        ) {
          addToasts(toasts.filter((toast: ToastProps) => toast.persistRouteChange));
        }
      }
    };

    router.events.on('routeChangeStart', routeChangeStart);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [toasts, setToasts, router, addToasts]);

  const renderToast = (toast: ToastProps): React.ReactElement => {
    const { id, title, message, toastVariant, alertVariant, autohide, delay, icon, onClose } =
      toast;
    const finalIcon = icon ?? variantToIconMap[alertVariant] ?? Icons.InfoIcon;

    return (
      <Transition
        variant={
          toastVariant === ToastVariant.FLYOUT
            ? TransitionVariant.SLIDE_IN
            : TransitionVariant.SLIDE_UP
        }
        key={id}
      >
        <div className={cx('lia-toast')}>
          <ToastAlert
            id={id}
            title={title}
            message={message}
            toastVariant={toastVariant}
            alertVariant={alertVariant}
            icon={finalIcon}
            autohide={autohide}
            delay={delay}
            onClose={(): void => {
              removeToast(id);

              if (onClose) {
                onClose();
              }
            }}
          />
        </div>
      </Transition>
    );
  };

  const bannerToasts = toasts.filter(toast => toast.toastVariant === ToastVariant.BANNER);
  const flyoutToasts = toasts.filter(toast => toast.toastVariant === ToastVariant.FLYOUT);

  if (routesLoading) {
    return null;
  }

  return (
    <ToastContext.Provider value={{ addToast, removeToast, addToasts, clearToasts }}>
      {children}
      <Transition
        variant={TransitionVariant.SLIDE_UP}
        in={bannerToasts.length > 0}
        mountOnEnter
        unmountOnExit
      >
        <TransitionGroup
          className={cx('lia-container-banner')}
          aria-live="polite"
          aria-atomic="true"
        >
          {bannerToasts?.map(toast => renderToast(toast))}
        </TransitionGroup>
      </Transition>
      <Transition
        variant={TransitionVariant.SLIDE_IN}
        in={flyoutToasts.length > 0}
        mountOnEnter
        unmountOnExit
      >
        <TransitionGroup
          className={cx('lia-container-flyout')}
          aria-live="polite"
          aria-atomic="true"
        >
          {flyoutToasts?.map(toast => renderToast(toast))}
        </TransitionGroup>
      </Transition>
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
