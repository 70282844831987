import type { QueryResult } from '@apollo/client';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useIsomorphicRedirect, {
  RedirectStatusCode
} from '@aurora/shared-client/components/useIsomorphicRedirect';
import useLoginRedirect from '@aurora/shared-client/components/useLoginRedirect';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { canViewSalesforceCasePortal } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import type { RouteWithQuery } from '@aurora/shared-client/routes/useCustomRouter';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import {
  AccessFunctionType,
  PageAccessRequiredContextObject,
  RestrictedPageBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { useContext } from 'react';
import type {
  AppContextQuery,
  AppContextQueryVariables,
  ContextMessageQuery,
  ContextMessageQueryVariables,
  ContextNodeQuery,
  ContextNodeQueryVariables,
  ContextUserQuery,
  ContextUserQueryVariables
} from '../types/graphql-types';
import ActionFeedbacks from './common/ActionFeedback/ActionFeedbackTypes';
import useEditPageAccess from './useEditPageAccess';
import useEndUserPageDescriptor from './useEndUserPageDescriptor';
import useGroupHubPageAccess from './useGroupHubPageAccess';
import useLocalizedCategoriesAccess from './useLocalizedCategoriesAccess';
import useManageUserPageAccess from './useManageUserPageAccess';

export interface PageAccessData {
  /**
   * The app context query result from the AppContextProvider
   */
  appContextQueryResult: Pick<
    QueryResult<AppContextQuery, AppContextQueryVariables>,
    'loading' | 'data'
  >;
  /**
   * The context node query result from the AppContextProvider
   */
  contextNodeQueryResult: Pick<
    QueryResult<ContextNodeQuery, ContextNodeQueryVariables>,
    'loading' | 'data'
  >;
  /**
   * The context message query result from the AppContextProvider
   */
  contextMessageQueryResult: Pick<
    QueryResult<ContextMessageQuery, ContextMessageQueryVariables>,
    'loading' | 'data'
  >;
  /**
   * The context user query result from the AppContextProvider
   */
  contextUserQueryResult: Pick<
    QueryResult<ContextUserQuery, ContextUserQueryVariables>,
    'loading' | 'data'
  >;
}

/**
 * Redirects a user when they cannot access a given page, due to either not being logged in, or the context object
 * returning a null value.
 *
 * A null value for the given context object may indicate that the object does not exist, or, policies are in place
 * restricting the user from access
 *
 * @param pageAccessData query results made from the AppContextProvider
 * @param isAnonymous whether the user is logged in
 * @param communityId the id of the community
 */
export default function usePageAccess(
  pageAccessData: PageAccessData,
  isAnonymous: boolean,
  communityId: string
): { loading: boolean } {
  const { router } = useEndUserRoutes();
  const categoryId = router.getUnwrappedQueryParam(EndUserQueryParams.CATEGORY_ID);
  const parentId = categoryId ? `${NodeType.CATEGORY}:${categoryId}` : communityId;

  const {
    redirectBehavior: { restrictedPageBehavior, pageAccessResolver }
  } = useEndUserPageDescriptor();
  const {
    publicConfig: { salesforceIntegrationEnabled, casePortalEnabled }
  } = useContext(TenantContext);
  const { confirmEmailStatus } = useRegistrationStatus();
  const { requiredContextObject, resolverFunctionType } = pageAccessResolver;

  const { contextNodeQueryResult, contextMessageQueryResult, appContextQueryResult } =
    pageAccessData;
  const { loading: contextNodeLoading, data: contextNodeQueryData } = contextNodeQueryResult;
  const isContextNodeReady: boolean = !contextNodeLoading && !!contextNodeQueryData;
  const isContextNodeNull: boolean = isContextNodeReady && !contextNodeQueryData.coreNode;

  const { data: nodePoliciesData, loading: nodePoliciesLoading } = appContextQueryResult;
  const isNodePolicyReady: boolean = !nodePoliciesLoading && !!nodePoliciesData;

  const { loading: contextMessageLoading, data: contextMessageQueryData } =
    contextMessageQueryResult;
  const isContextMessageReady: boolean = !contextMessageLoading && !!contextMessageQueryData;
  const isContextMessageNull: boolean = isContextMessageReady && !contextMessageQueryData.message;

  const { isLocalizedCategoriesAccessReady, isLocalizedCategoriesFeatureEnabled, routeData } =
    useLocalizedCategoriesAccess(resolverFunctionType);
  const shouldLocalizedCategoriesRedirect: boolean = isLocalizedCategoriesAccessReady
    ? isLocalizedCategoriesFeatureEnabled && !!routeData
    : true;

  const { isEditPageAccessReady, canAccessEditPage } = useEditPageAccess(
    resolverFunctionType,
    contextNodeQueryData?.coreNode,
    contextMessageQueryResult
  );
  const { isGroupPageAccessReady, canAccessGroupPage } = useGroupHubPageAccess(
    resolverFunctionType,
    parentId,
    contextNodeQueryData?.coreNode
  );

  const { isManageUsersPageAccessReady, canAccessManageUsersPage } = useManageUserPageAccess(
    resolverFunctionType,
    isAnonymous
  );

  const isReady = isEditPageAccessReady && isGroupPageAccessReady && isManageUsersPageAccessReady;
  let redirectRoute: RouteWithQuery<EndUserPages, EndUserQueryParams> = {
    route: EndUserPages.CommunityPage,
    params: {}
  };
  switch (restrictedPageBehavior) {
    case RestrictedPageBehavior.COMMUNITY_PAGE_WITH_ALERT_OR_LOGIN_PAGE: {
      if (resolverFunctionType === AccessFunctionType.CREATE_GROUP_HUB_PAGE && routeData) {
        redirectRoute = {
          route: routeData.route,
          params: routeData.params,
          query: routeData.query
        };
      } else {
        redirectRoute = {
          route: EndUserPages.CommunityPage,
          params: {},
          query: {
            [EndUserQueryParams.ACTION_FEEDBACK]: ActionFeedbacks.PAGE_NOT_FOUND,
            [EndUserQueryParams.TIMESTAMP]: Date.now().toString()
          }
        };
      }
      break;
    }
    case RestrictedPageBehavior.COMMUNITY_PAGE_OR_LOGIN_PAGE: {
      redirectRoute = {
        route: EndUserPages.CommunityPage,
        params: null,
        query: null
      };
      break;
    }
    case RestrictedPageBehavior.LOCALIZED_CATEGORIES_PAGE: {
      if (routeData) {
        redirectRoute = {
          route: routeData.route,
          params: routeData.params,
          query: routeData.query
        };
      }

      break;
    }

    default:
  }

  let shouldRedirect: boolean = false;
  switch (requiredContextObject) {
    case PageAccessRequiredContextObject.NODE: {
      shouldRedirect = isContextNodeReady && isContextNodeNull;
      break;
    }
    case PageAccessRequiredContextObject.MESSAGE: {
      shouldRedirect = isContextMessageReady && isContextMessageNull;
      break;
    }
    case PageAccessRequiredContextObject.USER: {
      shouldRedirect = isAnonymous;
      break;
    }
    default: {
      break;
    }
  }

  if (!shouldRedirect && resolverFunctionType) {
    switch (resolverFunctionType) {
      case AccessFunctionType.POST_PAGE: {
        shouldRedirect =
          isContextNodeReady && !contextNodeQueryData.coreNode.userContext.canPostMessages;
        break;
      }
      case AccessFunctionType.CREATE_GROUP_HUB_PAGE: {
        shouldRedirect =
          shouldLocalizedCategoriesRedirect || (isGroupPageAccessReady && !canAccessGroupPage);
        break;
      }
      case AccessFunctionType.POST_GROUP_HUB_PAGE: {
        shouldRedirect = isGroupPageAccessReady && !canAccessGroupPage;
        break;
      }
      case AccessFunctionType.EDIT_GROUP_HUB_PAGE: {
        shouldRedirect = isContextNodeReady && isGroupPageAccessReady && !canAccessGroupPage;
        break;
      }
      case AccessFunctionType.MANAGE_MEMBERS_PAGE: {
        shouldRedirect = isContextNodeReady && isGroupPageAccessReady && !canAccessGroupPage;
        break;
      }
      case AccessFunctionType.MANAGE_USERS_PAGE: {
        shouldRedirect = isManageUsersPageAccessReady && !canAccessManageUsersPage;
        break;
      }
      case AccessFunctionType.CASE_PORTAL: {
        shouldRedirect =
          isNodePolicyReady &&
          !(
            (salesforceIntegrationEnabled && casePortalEnabled) ||
            (confirmEmailStatus && canViewSalesforceCasePortal(nodePoliciesData?.community))
          );
        break;
      }
      case AccessFunctionType.INBOX_PAGE: {
        shouldRedirect =
          isNodePolicyReady &&
          !checkPolicy(nodePoliciesData?.community?.communityPolicies?.canUsePrivateNotes);
        break;
      }
      case AccessFunctionType.NOTIFICATIONS_PAGE: {
        shouldRedirect =
          isNodePolicyReady &&
          !checkPolicy(nodePoliciesData?.community?.communityPolicies?.canUseNotifications);
        break;
      }
      case AccessFunctionType.EDIT_PAGE:
      case AccessFunctionType.CONTENT_WORKFLOW_EDIT_PAGE: {
        shouldRedirect =
          isContextNodeReady &&
          isContextMessageReady &&
          isEditPageAccessReady &&
          !canAccessEditPage;
        break;
      }
      case AccessFunctionType.LOCALIZED_CATEGORIES_PAGE:
      case AccessFunctionType.GROUPS_PAGE: {
        shouldRedirect = shouldLocalizedCategoriesRedirect;
        break;
      }
      default: {
        break;
      }
    }
  }

  // redirect the user to the proper category page if localized categories page restricted behavior is set for the page
  // and a category is mapped to the language in use
  useIsomorphicRedirect(shouldRedirect && !!routeData, redirectRoute);

  const shouldRedirectLoginPage =
    !shouldLocalizedCategoriesRedirect && shouldRedirect && isAnonymous;
  const shouldRedirectLoggedIn =
    shouldRedirect && !shouldLocalizedCategoriesRedirect && !isAnonymous;
  // redirect the user to the login page if they are anonymous and there is restricted behavior is set for the page,
  //  and no context object is present
  useLoginRedirect(shouldRedirectLoginPage);

  // redirect logged-in users to the page specified for the page, given that no context object is present
  useIsomorphicRedirect(
    shouldRedirectLoggedIn,
    redirectRoute,
    RedirectStatusCode.MOVED_TEMPORARILY
  );

  return {
    loading: !(
      isReady &&
      !shouldLocalizedCategoriesRedirect &&
      !shouldRedirectLoginPage &&
      !shouldRedirectLoggedIn
    )
  };
}
