import { createGlobalState } from 'react-hooks-global-state';
import type ToastAlertProps from '../../common/ToastAlert/ToastAlertProps';
import type { SetStateAction } from 'react';

const STATE_KEY = 'toasts';

interface ToastState {
  /**
   * Global toasts collection.
   */
  toasts: ToastAlertProps[];
}

/**
 * Toasts global state. Intended for use within ToastContextProvider storing state across context mounts.
 *
 * @author Martin Barotto
 */
const { useGlobalState, setGlobalState } = createGlobalState<ToastState>({
  toasts: []
});

export function useToastGlobalState() {
  return useGlobalState(STATE_KEY);
}

export function setToastGlobalState(state: SetStateAction<ToastAlertProps[]>): void {
  return setGlobalState(STATE_KEY, state);
}
