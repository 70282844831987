import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import localStyles from './SystemAlert.module.pcss';

interface Props {
  /**
   * An identifier for the alert.
   */
  id: string | number;
  /**
   * Text or component to display as title.
   */
  title: string | React.FC<React.PropsWithChildren<unknown>>;
  /**
   * Text or component to display as message.
   */
  message: string | React.FC<React.PropsWithChildren<unknown>>;
}

/**
 * System Alert message box.
 *
 * @author Willi Hyde
 */
const SystemAlert: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  title: TitleComponent,
  message: MessageComponent
}) => {
  const cx = useClassNameMapper(localStyles);
  const uidSeed = useUIDSeed();
  const ariaTitleId = uidSeed('alertTitle');
  const ariaBodyId = uidSeed('alertBody');

  // Title can be of type string or a react functional component.
  const renderAlertTitle = (): React.ReactNode => {
    return typeof TitleComponent === 'string' ? (
      <span className={cx('lia-title')} id={ariaTitleId} data-testid="SystemAlert.Title">
        {TitleComponent}
      </span>
    ) : (
      <span
        className={cx('lia-title lia-is-component')}
        id={ariaTitleId}
        data-testid="SystemAlert.Title"
      >
        <TitleComponent />
      </span>
    );
  };

  // Message can be of type string or a react functional component.
  const renderAlertMessage = (): React.ReactNode => {
    return typeof MessageComponent === 'string' ? (
      <span className={cx('lia-message')} id={ariaBodyId} data-testid="SystemAlert.Message">
        {MessageComponent}
      </span>
    ) : (
      <span
        className={cx('lia-message lia-is-component')}
        id={ariaBodyId}
        data-testid="SystemAlert.Message"
      >
        <MessageComponent />
      </span>
    );
  };

  return (
    <div
      className={cx('lia-box')}
      role="alertdialog"
      aria-labelledby={ariaTitleId}
      aria-describedby={ariaBodyId}
      data-testid={`SystemAlert.${id}`}
    >
      {renderAlertTitle()}
      {renderAlertMessage()}
    </div>
  );
};

export default SystemAlert;
