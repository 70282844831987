import type { Modifier, UsePopperOptions } from 'react-overlays/usePopper';

/**
 * Popper modifier for `computedStyles` and the `gpuAcceleration` option.
 *
 * https://popper.js.org/docs/v2/modifiers/compute-styles/
 */
function computeStylesGpuAccelerationModifier(gpuAcceleration: boolean) {
  return {
    name: 'computeStyles',
    options: {
      gpuAcceleration
    }
  };
}

/**
 * Popper modifier for `offset`
 *
 * https://popper.js.org/docs/v2/modifiers/offset/
 *
 * @param skidding Displaces the popper along the reference element.
 * @param distance Displaces the popper away from, or toward, the reference element in
 * the direction of its placement.
 */
function offsetModifier(skidding: number, distance: number) {
  return {
    name: 'offset',
    options: {
      offset: [skidding, distance]
    }
  };
}

/**
 * Default popper config to use with most dropdown menus. Disables the gpu acceleration modifier to
 * help avoid CSS transform conflicts between our custom animation for the Bootstrap dropdown and
 * the popperJS library.
 *
 * https://popper.js.org/docs/v2/
 */
const dropdownPopperConfig: UsePopperOptions = {
  modifiers: [computeStylesGpuAccelerationModifier(false)]
};

/**
 * Create a popper config with the `offset` and `computeStyles` modifiers.
 *
 * @param offsetSkidding the skidding to apply to the offset modifier
 * @param offsetDistance the distance to apply to the offset modifier
 * @param enableCpuAcceleration whether to disable the gpu acceleration in the `computeStyles` modifier
 */
function offsetPopperConfig(
  offsetSkidding: number,
  offsetDistance: number,
  enableCpuAcceleration = false
): UsePopperOptions {
  return {
    modifiers: [
      offsetModifier(offsetSkidding, offsetDistance),
      computeStylesGpuAccelerationModifier(enableCpuAcceleration)
    ]
  };
}

/**
 * Popper modifier for `sameWidth`
 *
 * https://popper.js.org/docs/v2/modifiers/community-modifiers/
 *
 * Ensure popper stays same width as the reference if size is changed.
 */
function sameWidthModifier() {
  return {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite' as Modifier<unknown, unknown>['phase'],
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
      state.styles.popper.left = `${state.rects.reference.x}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${
        state.elements.reference.getBoundingClientRect().width
      }px`;
      state.elements.popper.style.left = `${state.elements.reference.getBoundingClientRect().x}px`;
    }
  };
}

/**
 * Create a popper config with the `sameWidth`, `computeStyles`, `offset`, and `eventListeners` modifiers.
 *
 * @param offsetSkidding the skidding to apply to the offset modifier
 * @param offsetDistance the distance to apply to the offset modifier
 *
 */
function sameWidthPopperConfig(offsetSkidding: number, offsetDistance: number): UsePopperOptions {
  return {
    modifiers: [
      sameWidthModifier(),
      computeStylesGpuAccelerationModifier(false),
      offsetModifier(offsetSkidding, offsetDistance),
      {
        name: 'eventListeners',
        options: {
          resize: true
        }
      }
    ]
  };
}

export { dropdownPopperConfig, offsetPopperConfig, sameWidthPopperConfig };
