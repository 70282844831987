import React from 'react';

export interface WidgetLocation {
  /**
   * The id of the section where the widget is located
   */
  sectionId: string;
  /**
   * The id of the section column where the widget is located
   */
  columnId: string;
  /**
   * The index of the widget in the section column where it is located
   */
  widgetIdx: number;
}

export interface SectionWidgetContextInterface {
  /**
   * location of the widget within the section
   */
  location: WidgetLocation;

  /**
   * Whether the section is full height or not
   */
  fullHeight: boolean;
}

/**
 * Context applicable to any component in end user application within a quilt section
 * @author Manish Shrestha
 */
const SectionWidgetContext = React.createContext<SectionWidgetContextInterface>({
  location: null,
  fullHeight: false
});

export default SectionWidgetContext;
