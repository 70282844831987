import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import clientAuthenticationHelper from '../../../helpers/authentication/ClientAuthenticationHelper';
import useGlobalState, { GlobalStateType } from '../../../helpers/ui/GlobalState';
import usePageLoadingGlobalState from '../../../helpers/ui/PageLoadingGlobalState';
import type { RouteWithOptions } from '../../../routes/useCustomRouter';
import { AuthFlow } from '../../../types/enums';
import type { RegistrationFormData } from '../../authentication/RegistrationForm/RegistrationForm';
import useAuthTransition from '../../authentication/useAuthTransition';
import useCommunitySsoProperties from '../../community/useCommunitySsoProperties';
import type { AuthFlowContextInterface } from './AuthFlowContext';
import AuthFlowContext from './AuthFlowContext';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import TenantContext from '../TenantContext';
import type { AuthProviderInfo } from '../../useAuthFlow';
import type { BannerFormAlertProps } from '../../common/BannerFormAlert/BannerFormAlert';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';

const AuthenticationModal = dynamic(
  () => import('../../authentication/AuthenticationModal/AuthenticationModal'),
  { ssr: false }
);

const log = getLog(module);

/**
 * Provider control over the auth modals to the app.
 *
 * @constructor
 *
 * @author Rishabh Modi
 */
const AuthFlowContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [, setPageLoading] = usePageLoadingGlobalState('pageLoading');
  const [showAuthenticationModal, setShowAuthenticationModal] = useState<AuthFlow>();
  const [authProviderInfo, setAuthProviderInfo] = useState<AuthProviderInfo>(null);
  const [bannerFormAlertProps, setBannerFormAlertProps] = useState<BannerFormAlertProps>(null);
  const [routeParams, setRouteParams] = useState(null);
  const { data, loading } = useCommunitySsoProperties(module);
  const { router } = useEndUserRoutes();
  const nextAuthFlowHandler = router.getUnwrappedQueryParam(EndUserQueryParams.NEXT_AUTH_FLOW);
  const [, setShowUserEmailVerificationToast] = useGlobalState<GlobalStateType>(
    GlobalStateType.SHOW_USER_EMAIL_VERIFICATION_TOAST
  );

  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;

  const handleAuthTransition = useAuthTransition(routeParams);

  const context: AuthFlowContextInterface = {
    showAuthenticationModal: (
      type: AuthFlow,
      routeAndParams: RouteWithOptions<EndUserPages, EndUserQueryParams> = null,
      authProviderData = {
        localAuthProvider: null,
        enabledAuthProvider: [],
        availableAuthProvider: []
      },
      bannerFormAlertPropsData: BannerFormAlertProps = null
    ) => {
      setShowAuthenticationModal(type);
      setAuthProviderInfo(authProviderData);
      setBannerFormAlertProps(bannerFormAlertPropsData);
      if (routeAndParams) {
        setRouteParams(routeAndParams);
      }
    },
    hideAuthenticationModal: () => {
      setShowAuthenticationModal(null);
    }
  };

  log.trace('AuthFlowContext is %O', context);

  if (loading) {
    return null;
  }

  const ssoEnabled = checkPolicy(data?.community?.ssoProperties?.ssoEnabled);
  const showModal =
    (multiAuthEnabled &&
      (authProviderInfo?.localAuthProvider?.enabled ||
        authProviderInfo?.enabledAuthProvider?.length > 1)) ||
    !ssoEnabled;

  const onLoginOrForgotPassword = async (): Promise<void> => {
    setShowAuthenticationModal(null);
    setPageLoading(true);
    if (!nextAuthFlowHandler) {
      await handleAuthTransition(true);
    } else {
      // perform a transition for user authorization code flow
      window.location.href = UrlHelper.sanitize(
        UrlHelper.getFullyQualifiedUrlForPath(tenant, nextAuthFlowHandler)
      );
    }
  };

  const onRegistration = async (formData: RegistrationFormData): Promise<void> => {
    setShowAuthenticationModal(null);
    const loginResult = await clientAuthenticationHelper.loginFromRegistrationForm(formData);
    if (loginResult?.success) {
      setPageLoading(true);
      await handleAuthTransition();
    }
  };

  const onHide = (): void => {
    setShowAuthenticationModal(null);
    setShowUserEmailVerificationToast(true);
  };

  return (
    <AuthFlowContext.Provider value={context}>
      {showModal && (
        <>
          <AuthenticationModal
            show={showAuthenticationModal === AuthFlow.LOGIN}
            onHide={onHide}
            onLogin={onLoginOrForgotPassword}
            onRegistration={onRegistration}
            onForgotPass={onLoginOrForgotPassword}
            loginBannerAlertProps={bannerFormAlertProps}
            type={AuthFlow.LOGIN}
          />
          <AuthenticationModal
            show={showAuthenticationModal === AuthFlow.MULTI_AUTH_LOGIN}
            onHide={onHide}
            onLogin={onLoginOrForgotPassword}
            onRegistration={onRegistration}
            onForgotPass={onLoginOrForgotPassword}
            loginBannerAlertProps={bannerFormAlertProps}
            type={AuthFlow.MULTI_AUTH_LOGIN}
          />
          <AuthenticationModal
            show={showAuthenticationModal === AuthFlow.REGISTRATION}
            onHide={onHide}
            onLogin={onLoginOrForgotPassword}
            onRegistration={onRegistration}
            onForgotPass={onLoginOrForgotPassword}
            type={AuthFlow.REGISTRATION}
          />
          <AuthenticationModal
            show={showAuthenticationModal === AuthFlow.FORGOT_PASSWORD}
            onHide={onHide}
            onLogin={onLoginOrForgotPassword}
            onRegistration={onRegistration}
            onForgotPass={onLoginOrForgotPassword}
            type={AuthFlow.FORGOT_PASSWORD}
          />
        </>
      )}
      {children}
    </AuthFlowContext.Provider>
  );
};

export default AuthFlowContextProvider;
