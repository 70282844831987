import React from 'react';

/**
 * Context for Widget
 */
export interface WidgetContextInterface {
  /**
   * widget id
   */
  id: string;

  /**
   * instance id of the widget
   */
  instanceId?: string;
}

/**
 * Context needed to configure widget in edit mode.
 * @author Manish Shrestha
 */
const WidgetContext = React.createContext<WidgetContextInterface>({
  id: null
});

export default WidgetContext;
