import { createContext } from 'react';
import type { EmailVerificationToastType } from '../../common/EmailVerification/types';

interface AddEmailToastProps {
  /**
   * Type of toast to be shown
   */
  variant: EmailVerificationToastType;

  /**
   * optional callback when the toast is removed
   */
  onRemoveToast?: () => void;
}

export type AddUpdateEmailToastProps = Omit<AddEmailToastProps, 'variant'>;

export interface EmailVerificationContextInterface {
  /** Renders the toast that offers the user to change their email */
  addUpdateEmailToast(props?: AddUpdateEmailToastProps): void;
}

const EmailVerificationContext = createContext<EmailVerificationContextInterface>({
  addUpdateEmailToast: () => null
});

export default EmailVerificationContext;
