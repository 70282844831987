import type { I18n } from '@aurora/shared-types/texts';
import { getPropertyByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { FieldError, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { getValidationRuleValue } from '../../../helpers/form/ValidationRule/ValidationRuleHelper';
import FormFieldFeedback from '../FormFieldFeedback/FormFieldFeedback';
import type { FieldValidationRules } from '../types';
import useErrorMessages from '../useErrorMessages/useErrorMessages';
import localStyles from './FormField.module.pcss';

interface ErrorProps<NameT extends FieldPath<DataT>, DataT extends FieldValues> {
  /**
   * name of the field
   */
  fieldName: NameT;
  /**
   * validations for the field.
   */
  validations: FieldValidationRules<DataT, NameT>;
  /**
   * form methods.
   */
  formMethods: UseFormReturn<DataT>;
  /**
   * id of the form.
   */
  formId: string;
  /**
   * i18 to use for the localized text.
   */
  i18n: I18n<unknown, unknown>;
  /**
   * class name to apply to error.
   */
  errorClassName?: string;
}

/**
 * Renders error feedback for the form field.
 * @author Manish Shrestha
 */
const FormFieldErrorFeedback = <NameT extends FieldPath<DataT>, DataT extends FieldValues>({
  fieldName,
  formMethods: {
    formState: { errors }
  },
  validations,
  formId,
  i18n,
  errorClassName
}: ErrorProps<NameT, DataT>): React.ReactElement => {
  const { hasMessage, formatMessage, loading: textLoading } = i18n;
  const cx = useClassNameMapper(localStyles);
  const getFieldMessageForError = useErrorMessages(formId, i18n);

  if (textLoading) {
    return null;
  }

  const errorMessages: string[] = [];
  const fieldError: FieldError = getPropertyByPath(errors, fieldName) as FieldError;

  if (validations) {
    Object.entries(validations).forEach(([key, value]) => {
      if (key === 'validate' && value) {
        if (typeof value === 'function') {
          if (fieldError?.type === 'validate') {
            errorMessages.push(
              fieldError.message || formatMessage(`${formId}.${fieldName}.validate.error`)
            );
          }
        } else {
          Object.entries(value).forEach(([validateKey]) => {
            if (fieldError?.type === 'possibleValues') {
              errorMessages.push(getFieldMessageForError('NotValidPossibleValueError', fieldName));
            } else if (fieldError?.type === validateKey) {
              errorMessages.push(
                fieldError.message ||
                  formatMessage(`${formId}.${fieldName}.validate.${validateKey}.error`)
              );
            }
          });
        }
      } else if (fieldError?.type === key) {
        const { min, max, maxLength, minLength, required } = validations;
        // TODO update all forms to display min and max messages using the format in useErrorMessage, e.g.
        //  GreaterThanMaxValue.error, instead of maxLength.error. This allows us to combine errors from mutation
        //  responses with validation run prior to mutation calls. Afterwards, we can remove this Record, hopefully
        //  rely on defaults wherever possible (instead of form specific translations)
        const messageParameters: Record<
          keyof Omit<FieldValidationRules<DataT, NameT>, 'validate' | 'pattern'>,
          PrimitiveType
        > = {
          ...(!(min === undefined || min === null) && {
            min: getValidationRuleValue(min).toLocaleString()
          }),
          ...(!(max === undefined || max === null) && {
            max: getValidationRuleValue(max).toLocaleString()
          }),
          ...(!!minLength && {
            minLength: getValidationRuleValue(minLength).toLocaleString()
          }),
          ...(!!maxLength && {
            maxLength: getValidationRuleValue(maxLength).toLocaleString()
          }),
          ...(!!required && {
            required: getValidationRuleValue<string | boolean>(required)
          })
        };
        const validationOverrideKeyForFieldAndType = `${formId}.${fieldName}.${key}.error`;
        const validationOverrideKeyForField = `${formId}.${fieldName}.validate.error`;

        if (fieldError.message) {
          errorMessages.push(fieldError.message);
        } else if (hasMessage(validationOverrideKeyForFieldAndType)) {
          errorMessages.push(
            formatMessage(validationOverrideKeyForFieldAndType, { [key]: value as PrimitiveType })
          );
        } else if (hasMessage(validationOverrideKeyForField)) {
          errorMessages.push(formatMessage(validationOverrideKeyForField, messageParameters));
        } else {
          // convert type of react-hook-form error to one matching our server validation errors
          let derivedErrorType:
            | 'LessThanMinValueError'
            | 'GreaterThanMaxValueError'
            | 'RequiredFieldNotSetError'
            | 'LessThanMinNumberError'
            | 'GreaterThanMaxNumberError';
          switch (key) {
            case 'minLength': {
              derivedErrorType = 'LessThanMinValueError';
              break;
            }
            case 'maxLength': {
              derivedErrorType = 'GreaterThanMaxValueError';
              break;
            }
            case 'min': {
              derivedErrorType = 'LessThanMinNumberError';
              break;
            }
            case 'max': {
              derivedErrorType = 'GreaterThanMaxNumberError';
              break;
            }
            default: {
              derivedErrorType = 'RequiredFieldNotSetError';
            }
          }

          // since we do not map validation errors from the server to minLength or maxLength, but just min or max,
          //  adjust here as well to fit translation that works for both
          const adjustedMessageParams = {
            ...messageParameters
          };

          if ('minLength' in adjustedMessageParams) {
            adjustedMessageParams.min = adjustedMessageParams.minLength;
          }
          if ('maxLength' in adjustedMessageParams) {
            adjustedMessageParams.max = adjustedMessageParams.maxLength;
          }

          errorMessages.push(
            getFieldMessageForError(derivedErrorType, fieldName, adjustedMessageParams)
          );
        }
      }
    });
  }
  if (errorMessages.length === 0 && fieldError?.type === 'manual') {
    errorMessages.push(fieldError.message);
  }

  return (
    errorMessages?.length > 0 && (
      <FormFieldFeedback
        id={fieldName}
        data-testid="FormField.Feedback"
        className={cx(errorClassName, 'lia-form-error')}
        message={errorMessages[0]}
        valid={false}
      />
    )
  );
};

export default FormFieldErrorFeedback;
