import { useContext } from 'react';
import TenantContext from './context/TenantContext';

/**
 * Hook to provide function for correct cross-origin attribute value to images, etc.
 *
 * @author Dolan Halbrook
 */
export default function useCrossOriginAttribute(): (
  resource: string
) => 'anonymous' | 'use-credentials' | '' | undefined {
  const tenant = useContext(TenantContext);
  const basicAuthTenant = tenant && tenant.useBasicAuth && tenant.sameSite;
  return (resourceUrl: string) =>
    basicAuthTenant &&
    resourceUrl &&
    (resourceUrl.includes(tenant.auroraHost) || resourceUrl.startsWith('/'))
      ? 'use-credentials'
      : undefined;
}
