import { useContext } from 'react';
import AppContext from '../context/AppContext/AppContext';

interface SeoProperties {
  /**
   * Website name to display when posting a community link on Facebook.
   */
  customOGSiteName: string;
  /**
   * @param value Current path or node value
   * @returns Path based on lowercase path setting
   */
  getCaseSensitivePath: (value: string) => string;
}

/**
 * Conditionally calls the toLowerCase method on the provided string
 *
 * @param value the string
 * @param condition whether or not to call toLowerCase
 */
export function conditionalToLowerCase(value: string, condition: boolean): string {
  return condition ? value.toLowerCase() : value;
}
/**
 * Seo properties of the community
 *
 * @author Rishabh Modi
 */
export default function useSeoProperties(): SeoProperties {
  const {
    community: {
      seoProperties: { customOGSiteName, lowercasePath }
    }
  } = useContext(AppContext);

  /**
   *
   * @param value Current path or node value
   * @returns Path based on lowercase path setting
   */
  function getCaseSensitivePath(value: string): string {
    return conditionalToLowerCase(value, lowercasePath);
  }

  return {
    customOGSiteName,
    getCaseSensitivePath
  };
}
