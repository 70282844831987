/**
 * Converts a number to a string percent. Prevents values over 100%.
 *
 * @param value the value
 */
function safeFormatAsPercentage(value: number): string {
  if (value <= 1) {
    return value * 100 + '%';
  }

  return '100%';
}

/**
 * Creates a map where the keys are CSS variables and the values are the expanded parts of
 * a valid host color with differnt lightness values.
 *
 * @param varPrefix the CSS var prefix.
 * @param hostPrefix the host color var prefix.
 * @param lightnessValue the lighness value.
 *
 * @author Willi Hyde
 */
export function createLightnessVariables(
  varPrefix: string,
  hostPrefix: string,
  lightnessValue: number
): Record<string, string> | null {
  const hVar = `${varPrefix}-h`;
  const sVar = `${varPrefix}-s`;
  const lVar = `${varPrefix}-l`;

  const mapping = {
    [hVar]: `var(${hostPrefix}-h)`,
    [sVar]: `var(${hostPrefix}-s)`,
    [lVar]: safeFormatAsPercentage(lightnessValue)
  };

  return {
    ...mapping,
    [varPrefix]: `hsl(var(${hVar}), var(${sVar}), var(${lVar}))`
  };
}
