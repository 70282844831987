import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class BoldStyle implements FontStyleDefinition {
  name = FontStyleName.BOLD;

  style = FontStyle.Normal;

  weight = 700;
}
