import Color from 'color';
import type { ColorCssVariableOrValue, LegacyColorKey } from '@aurora/shared-types/styles';
import { getLog } from '@aurora/shared-utils/log';
import { getLegacyColor, isLegacyCustomKey } from './LegacyColorHelper';
import isCssVariable, { cssVar } from './CssVariableHelper';

export type SafeColorCallback = (color: ColorCssVariableOrValue, fallback?: Color) => Color;

const log = getLog(module);
const cssVarRegex = new RegExp(/(var\(([\d\s,a-z-]+)\))/g);
let safeColorCache: Record<ColorCssVariableOrValue, string> = {};

/**
 * Resolves CSS variables into valid color values. Uses recursion to resolve nested CSS variables.
 * Supports the following formats:
 *
 * --some-var
 * var(--some-var)
 * hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.2)
 *
 * @param themeToCssVariablesMap a mapping of CSS variables to values
 * @param value the value to resolve
 */
function resolveCssVarFromTheme(
  themeToCssVariablesMap: Record<string, string | null | undefined>,
  value: string
): string | null {
  if (value == null) {
    log.error('No color value specified');
    return null;
  }

  // First check if the value is an unwrapped CSS variable and that it exists in
  // the theme.
  if (isCssVariable(value)) {
    const resolvedVariable = themeToCssVariablesMap[value] ?? (cssVar(value) as string);
    if (!resolvedVariable) {
      return null;
    }
    return resolveCssVarFromTheme(themeToCssVariablesMap, resolvedVariable);
  } else if (isLegacyCustomKey(value)) {
    // Check if the color value matches one of the legacy color keys. These were only in
    // place for a short while during the development and this should ultimately be removed
    // one all the values are removed from quilts.
    return resolveCssVarFromTheme(themeToCssVariablesMap, getLegacyColor(value as LegacyColorKey));
  }

  /**
   * Lastly attempt to replace all CSS variables wrapped in a `var()` with values from the theme.
   *
   * LIA-88803 When any of the colors cannot be resolved we track that it was not matched
   * and return null for the method.
   */
  let matched = true;
  const output = value.replaceAll(cssVarRegex, (capture1, capture2, capture3) => {
    const result = resolveCssVarFromTheme(themeToCssVariablesMap, capture3);
    if (!result) {
      matched = false;
      return '';
    }
    return result;
  });

  return matched ? output : null;
}

/**
 * A wrapper around the `color` library that ensures a safe color is created. Supports
 * CSS variables either as the specific color or nested parts of a HSL or RGB color. Resolves
 * CSS variables from the specific theme. CSS variables can either be specific in the format
 * of `--some-var` or `var(--some-var)`. If a color cannot be resolved or an invalid format is used,
 * a default color of `#cccccc` will be returned.
 *
 * @param themeToCssVariablesMap
 * @param color the color
 * @param fallback the fallback color in case the color cannot be processed.
 *
 * @author Adam Ayres
 */
function safeColor(
  themeToCssVariablesMap: Record<string, string>,
  color: ColorCssVariableOrValue,
  fallback = new Color('#cccccc')
): Color {
  if (safeColorCache.hasOwnProperty(color)) {
    return new Color(safeColorCache[color]);
  }

  const updatedColor = resolveCssVarFromTheme(themeToCssVariablesMap, color);
  if (updatedColor) {
    try {
      const colorObject = new Color(updatedColor);
      safeColorCache[color] = updatedColor;
      return colorObject;
    } catch (error) {
      log.error('Error creating a safe color for %s: %s', color, error.message);
      return fallback;
    }
  }
  return fallback;
}

export function clearSafeColorCache() {
  safeColorCache = {};
}

export default safeColor;
