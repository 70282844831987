import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class BlackStyle implements FontStyleDefinition {
  name = FontStyleName.BLACK;

  style = FontStyle.Normal;

  weight = 900;
}
