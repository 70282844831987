import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import type { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { cssVar, stripUnit } from '../../../helpers/styles/CssVariableHelper';
import { createTransitionClasses } from '../../../helpers/ui/AnimationHelper/AnimationHelper';

export enum TransitionVariant {
  FADE = 'fade',
  FADE_SLOW = 'fade-slow',
  ZOOM_FADE = 'zoom-fade',
  MOVE_LEFT = 'move-left',
  MOVE_RIGHT = 'move-right',
  SLIDE_IN = 'slide-in',
  SLIDE_UP = 'slide-up',
  SQUISH = 'squish'
}

type TransitionTimeout = number | { appear?: number; enter?: number; exit?: number };

function getTransitionTimeout(timingType: string): TransitionTimeout {
  const transitionTime = +stripUnit(cssVar(timingType, '0ms'));
  return { appear: transitionTime, enter: transitionTime, exit: transitionTime };
}

const normalTransitionTimeout = getTransitionTimeout('--lia-timing-normal');
const slowTransitionTimeout = getTransitionTimeout('--lia-timing-slow');

const mapVariantToTimeout: Record<TransitionVariant, TransitionTimeout> = {
  [TransitionVariant.FADE]: normalTransitionTimeout,
  [TransitionVariant.FADE_SLOW]: slowTransitionTimeout,
  [TransitionVariant.ZOOM_FADE]: normalTransitionTimeout,
  [TransitionVariant.MOVE_LEFT]: normalTransitionTimeout,
  [TransitionVariant.MOVE_RIGHT]: normalTransitionTimeout,
  [TransitionVariant.SLIDE_IN]: normalTransitionTimeout,
  [TransitionVariant.SLIDE_UP]: slowTransitionTimeout,
  [TransitionVariant.SQUISH]: slowTransitionTimeout
};

type Props<Ref extends undefined | HTMLElement = undefined> = Omit<
  CSSTransitionProps<Ref>,
  'timeout' | 'classNames'
> & {
  /**
   * The transition variant.
   */
  variant: TransitionVariant;
};

/**
 * Wrapper around `CSSTransition` from the `react-transition-group` library that provides
 * some convenience for building the required class names and setting the transition timeouts
 * for the supported transitions.
 *
 * How to add new transitions:
 *
 * 1.) Update `TransitionVariant` with the new transition
 * 2.) Update `mapVariantToTimeout` to specify the timeouts for the new transition
 * 3.) Define the new animation CSS in `_animations.css`. The CSS classes should use the pattern:
 * `lia-g-csst-${variant}`, where the variant name is the value of the newly item in the
 * `TransitionVariant` enum.
 *
 * @author Adam Ayres
 */
const Transition = <Ref extends undefined | HTMLElement = undefined>({
  variant,
  ...props
}: Props<Ref>): React.ReactElement => {
  const cx = useClassNameMapper();
  const timeout: TransitionTimeout = mapVariantToTimeout[variant];
  const classNames = createTransitionClasses(cx, `lia-g-csst-${variant}`);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CSSTransition timeout={timeout} classNames={classNames} {...props} />;
};

export default Transition;
