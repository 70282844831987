import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { SharedComponent } from '../../../enums';
import IconTypes from '../../../public/static/graphics/processed/enums';
import useTranslation from '../../useTranslation';
import type CustomValidation from '../CustomValidation/CustomValidation';
import { IconColor, IconSize } from '../Icon/enums';
import Icon from '../Icon/Icon';
import localStyles from './CustomValidationChecklist.module.pcss';

interface Props {
  /** The component to use translations from */
  componentId: SharedComponent;

  /**
   * An array of the custom validation metadata
   */
  customValidations: CustomValidation[];
}

/**
 * A list of custom validations with checkmarks beside each one that become highlighted as the requirements are met
 * @author Rosalyn Rowe
 */
const CustomValidationChecklist: React.FC<React.PropsWithChildren<Props>> = ({
  componentId,
  customValidations
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(componentId);

  if (textLoading) {
    return null;
  }

  return (
    <>
      {customValidations.map(validation => {
        const iconColor = validation.hasError() ? IconColor.GRAY_300 : IconColor.PRIMARY;

        return (
          <div
            key={validation.errorKey}
            className={cx('lia-item')}
            data-testid={validation.errorKey}
          >
            <Icon
              className={cx('lia-g-mr-10')}
              color={iconColor}
              icon={IconTypes.CheckmarkIcon}
              size={IconSize.PX_14}
            />
            {formatMessage(validation.translationKey, validation.values)}
          </div>
        );
      })}
    </>
  );
};

export default CustomValidationChecklist;
