import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class SemiBoldItalicStyle implements FontStyleDefinition {
  name = FontStyleName.SEMIBOLDITALIC;

  style = FontStyle.Italic;

  weight = 600;
}
