import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { inboxPageRedirectBehavior } from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for notes that holds the private notes specific attributes
 * @author Manish Shrestha
 */
export class NotesFeature implements CommunityFeature<Feature.NOTES> {
  getFeature(): Feature.NOTES {
    return Feature.NOTES;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.InboxPage,
        endUserModuleRegistry[EndUserComponent.INBOX_PAGE],
        pageDescriptorUrlPath[EndUserPages.InboxPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        inboxPageRedirectBehavior
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.INBOX_VIEW_WIDGET,
        component: dynamic(() => import('../components/inbox/InboxView/InboxView'))
      }
    ];
  }
}
