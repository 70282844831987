import type { ApolloError, DefaultContext } from '@apollo/client';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import { CachedAssetType } from '@aurora/shared-types/overrides/enums';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import useCachedAsset from '../assets/useCachedAsset';
import AppContext from '../context/AppContext/AppContext';
import useCachedThemeBundle from '../useCachedThemeBundle';

const log = getLog(module);

interface ThemeResponse {
  /**
   * Theme data
   */
  data: ThemeResultFragment;

  /**
   * whether it is loading or not
   */
  loading: boolean;

  /**
   * error
   */
  error?: ApolloError;
}

/**
 * Hook that fetches a theme from a specialized route served by Aurora. This
 * route acts as a caching layer between Aurora and LIA which will use the
 * last modified date of the theme to determine when to invalidate the cache.
 *
 * @param themeId the id of the theme, if no ID is specified, it will use the theme that is configured in the community.
 * @param context additional context to be passed directly to the options of the `useQuery` hook.
 * @param skip whether to skip this query.
 *
 * @author Adam Ayres
 */
export default function useCachedTheme(
  themeId: string | null = null,
  context?: DefaultContext,
  skip = false
): ThemeResponse {
  const { themeInfo } = useContext(AppContext);
  let finalThemeId = themeId;
  let finalLastModified = null;

  if (themeId === null) {
    finalThemeId = themeInfo?.id;
    finalLastModified = themeInfo?.lastModified;
  }

  const cachedBundle = useCachedThemeBundle(finalThemeId, finalLastModified);

  const finalSkip = skip || !!cachedBundle;

  const { loading, data, error } = useCachedAsset(
    finalThemeId,
    CachedAssetType.THEME,
    finalLastModified,
    null,
    null,
    finalSkip
  );

  if (error) {
    log.error(error, 'Error loading theme with id: %s', finalThemeId);
  }

  if (cachedBundle) {
    return { loading: false, data: cachedBundle.value };
  } else {
    return { loading, error, data: data?.cachedAsset?.value };
  }
}
