import React, {
  forwardRef,
  type ForwardRefExoticComponent,
  type PropsWithoutRef,
  type RefAttributes
} from 'react';
import { LoadingVariant } from './enums';
import LoadingDot from './LoadingDot/LoadingDot';
import LoadingLinear from './LoadingLinear/LoadingLinear';
import type { LoadingCommonProps, LoadingVariantTypeAndProps } from './types';
import { LoadingSize, LoadingSpacing } from '../../../types/enums';

interface Props {
  /**
   * The size of the loading indicator.
   */
  variant?: LoadingVariantTypeAndProps;
  /**
   * Class name(s) to apply to the wrapping element.
   */
  className?: string;
}

const loadingVariantTypeToComponentMap: Record<
  LoadingVariant,
  React.FC<React.PropsWithChildren<LoadingCommonProps>>
> = {
  [LoadingVariant.DOT]: LoadingDot,
  [LoadingVariant.LINEAR]: LoadingLinear
};

export const largeDotLoadingVariant: LoadingVariantTypeAndProps = {
  type: LoadingVariant.DOT,
  props: {
    size: LoadingSize.LG,
    spacing: LoadingSpacing.XL
  }
};

/**
 * Loading indicator
 *
 * @author Dolan Halbrook
 */
const Loading: ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<HTMLDivElement>> =
  forwardRef(function LoadingForward({ variant = { type: LoadingVariant.DOT }, className }, ref) {
    const LoadingVariantComponent = loadingVariantTypeToComponentMap[variant.type];
    const { ...variantProps } = variant.props ?? {};

    return (
      <LoadingVariantComponent
        className={className}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...variantProps}
      />
    );
  });

export default Loading;
