import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type {
  MembershipDetailsQuery,
  MembershipDetailsQueryVariables
} from '../../types/graphql-types';
import membershipDetailsQuery from './MembershipDetails.query.graphql';

/**
 * This hook is used to fetch membership details for a node
 * @param variables the variables that fetch fields conditionally from the GQL call
 * @param skip A boolean to indicate whether the query should be called or not
 * @returns QueryResult of type MembershipDetailsQuery
 *
 * @author Akash Goenka
 */
export default function useMembershipDetails(
  variables: MembershipDetailsQueryVariables,
  skip: boolean
) {
  return useQueryWithTracing<MembershipDetailsQuery, MembershipDetailsQueryVariables>(
    module,
    membershipDetailsQuery,
    {
      variables,
      skip
    }
  );
}
