import {
  getFirstRevision,
  getRevisionMessage,
  useCurrentOrPreviewMessage,
  useMessageRevisionsQuery,
  useSpecificRevision
} from '../../../messages/useCurrentOrPreviewMessage';
import type { PageAccessData } from '../../../usePageAccess';
import type { ContextMessageFragment } from '@aurora/shared-generated/types/graphql-types';
import type {
  MessageRevisionsQueryVariables,
  MessageViewFragment
} from '../../../../types/graphql-types';
import type {
  Message,
  RevisionConnection,
  RevisionConstraints
} from '@aurora/shared-generated/types/graphql-schema-types';
import { PathParamValidationType } from '@aurora/shared-types/redirects/pageRedirect';

/**
 * Returns the latest revision message when in preview mode for the AppContextProvider.
 *
 * @param pageParamData the required data from the AppContextProvider
 * @param pathParamValidationType page path type
 */
export default function useAppContextProviderRevisionMessage(
  pageParamData: PageAccessData,
  pathParamValidationType: PathParamValidationType
): {
  loading: boolean;
  data: ContextMessageFragment | MessageViewFragment | Message | null;
} {
  const revisionId = useSpecificRevision();
  // Message revisions from revision ids only supported in Edit pages
  const isRestoredMessage =
    pathParamValidationType === PathParamValidationType.EDIT && revisionId && revisionId.length > 0;
  const revisionConstraints: RevisionConstraints = { isPublished: { eq: false } };

  if (isRestoredMessage) {
    revisionConstraints.revisionNum = { eq: Number.parseInt(revisionId.split('_')[1]) };
  }
  const {
    contextMessageQueryResult: { data: contextMessageData, loading: contextMessageLoading }
  } = pageParamData;

  const { message: latestRevisionMessage, loading: revisionsLoading } = useCurrentOrPreviewMessage(
    contextMessageData?.message
  );

  const restoredMessageVariables: MessageRevisionsQueryVariables = {
    id: contextMessageData?.message?.id,
    useRevisionMessage: true
  };

  const { loading: restoredRevisionLoading, data: restoredRevisionData } = useMessageRevisionsQuery(
    restoredMessageVariables,
    !isRestoredMessage,
    revisionConstraints
  );

  const revisions = restoredRevisionData?.message.revisions;
  const restoredRevisionMessage =
    revisions?.edges.length > 0
      ? getRevisionMessage(getFirstRevision(revisions as RevisionConnection))
      : restoredRevisionData?.message;

  return {
    loading: contextMessageLoading || revisionsLoading || restoredRevisionLoading,
    data: isRestoredMessage ? (restoredRevisionMessage as Message) : latestRevisionMessage
  };
}
