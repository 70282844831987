import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { useContext } from 'react';
import { AppType } from '@aurora/shared-types/app';
import AppTypeContext from '../components/context/AppTypeContext';
import useAdminUserRoutes from './useAdminRoutes';
import useEndUserRoutes from './useEndUserRoutes';
import type { RouterAndLink } from './useCustomRouter';
import type { AdminPages, AdminQueryParams } from './adminRoutes';
import useNoAppRoutes from './useNoAppRoutes';

/**
 * Get the routes for the current app, this is useful for shared components.
 *
 * @param app The app to use the router for, will be determined from the context if
 * not specified.
 *
 * @author Adam Ayres
 */
function useRoutesForCurrentApp<
  Pages extends EndUserPages | AdminPages,
  QueryParams extends EndUserQueryParams | AdminQueryParams
>(app?: AppType): (RouterAndLink<Pages, QueryParams> & { loading?: boolean }) | null {
  const appFromContext = useContext(AppTypeContext);
  const adminRoutes = useAdminUserRoutes();
  const endUserRoutes = useEndUserRoutes();
  const noAppRouter = useNoAppRoutes();
  const finalApp = app ?? appFromContext;

  if (finalApp === AppType.ADMIN) {
    return adminRoutes as unknown as RouterAndLink<Pages, QueryParams>;
  } else if (finalApp === AppType.END_USER) {
    return endUserRoutes as unknown as RouterAndLink<Pages, QueryParams>;
  }
  return noAppRouter as unknown as RouterAndLink<Pages, QueryParams>;
}

export default useRoutesForCurrentApp;
