import { useMedia } from 'react-use';

/**
 * For purposes of showing e.g. alternate component variants or popper directions
 * @param breakpoint The min width to be considered wide
 * @returns Whether the screen is considered wide
 */
export default function useIsWide(breakpoint = '991px'): boolean {
  return useMedia(`(min-width: ${breakpoint})`, true);
}
