/**
 * Animation Helper. Provides static properties and methods
 * used by the `react-transition-group` library.
 *
 * @author Adam Ayres
 */

/**
 * Creates classname map used by React Transition Group components
 *
 * @param cx - classname mapper function (see `useClassNameMapper` in `react-bootstrap`)
 * @param className - the base classname which will be used when creating all of the animation classes.
 */
function createTransitionClasses(
  cx: (string: string) => string,
  className: string
): Record<string, string> {
  return {
    appear: cx(`${className}-appear`),
    appearActive: cx(`${className}-appear-active`),
    appearDone: cx(`${className}-appear-done`),
    enter: cx(`${className}-enter`),
    enterActive: cx(`${className}-enter-active`),
    enterDone: cx(`${className}-enter-done`),
    exit: cx(`${className}-exit`),
    exitActive: cx(`${className}-exit-active`),
    exitDone: cx(`${className}-exit-done`)
  };
}

export { createTransitionClasses };
