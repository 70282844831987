import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { FloatingControlDropdownItems } from '../../common/FloatingControlBox/FloatingControlBox';
import FloatingControlBox from '../../common/FloatingControlBox/FloatingControlBox';
import StrongTag from '@aurora/shared-client/components/common/StrongTag/StrongTag';
import useTranslation from '../../useTranslation';
import localStyles from './ToggleTextKeysFloatingBox.module.pcss';

interface Props {
  /**
   * Callback to trigger on toggle close.
   */
  onCloseToggleTextKeys;
  /**
   * Callback to update showTextKeys state.
   */
  onShowTextKeysToggle;
  /**
   * Whether show text keys is on or off.
   */
  showTextKeys: boolean;
}

/**
 * A floating button for toggling text keys.
 *
 * @author Agustin Sosa
 */
const ToggleTextKeysFloatingBox: React.FC<Props> = ({
  onCloseToggleTextKeys,
  onShowTextKeysToggle,
  showTextKeys
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    FormattedMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.TOGGLE_TEXT_KEYS_FLOATING_BOX);

  const toggleTextKeysMenuItems: Array<FloatingControlDropdownItems> = [
    {
      onClick: onShowTextKeysToggle,
      title: (
        <FormattedMessage
          id={'showTextKeys'}
          values={{
            state: showTextKeys
          }}
        />
      ),
      id: 'on'
    },
    {
      onClick: onCloseToggleTextKeys,
      title: formatMessage('closeToggleTextKeys'),
      id: 'off'
    }
  ];

  if (textLoading) {
    return null;
  }

  return (
    <FloatingControlBox
      testId={'ToggleTextKeysFloatingBox'}
      dropdownId={'toggle-text-keys-menu'}
      dropdownMenuItems={toggleTextKeysMenuItems}
      dropdownToggleLabel={formatMessage('toggleButtonLabel')}
    >
      <div className={cx('lia-description')}>
        <span>
          <FormattedMessage
            id={'toggleButton.description'}
            values={{
              strong: StrongTag,
              state: showTextKeys
            }}
          />
        </span>
      </div>
    </FloatingControlBox>
  );
};

export default ToggleTextKeysFloatingBox;
