import { FontSource, FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import FontFamilies from '../styles/FontFamilies';
import BlackItalicStyle from './BlackItalicStyle';
import BoldItalicStyle from './BoldItalicStyle';
import ExtraBoldItalicStyle from './ExtraBoldItalicStyle';
import ExtraLightItalicStyle from './ExtraLightItalicStyle';
import LightItalicStyle from './LightItalicStyle';
import MediumItalicStyle from './MediumItalicStyle';
import SemiBoldItalicStyle from './SemiBoldItalicStyle';
import ThinItalicStyle from './ThinItalicStyle';
import ThinStyle from './ThinStyle';
import ExtraLightStyle from './ExtraLightStyle';
import LightStyle from './LightStyle';
import RegularItalicStyle from './RegularItalicStyle';
import RegularStyle from './RegularStyle';
import MediumStyle from './MediumStyle';
import SemiBoldStyle from './SemiBoldStyle';
import ExtraBoldStyle from './ExtraBoldStyle';
import BoldStyle from './BoldStyle';
import BlackStyle from './BlackStyle';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';
import { extractCssVariable, isWrappedCssVariable } from '../styles/CssVariableHelper';
import NullStyle from './NullStyle';

export enum FontClassification {
  LOCAL = 'local',
  SERIF = 'serif',
  SANS_SERIF = 'sansSerif',
  CUSTOM = 'custom'
}

export interface FontDefinition {
  /**
   * The font family as it would be used in a CSS rule, e.g. 'Montserrat'
   * This will act as the display name for the font unless a more specific display name is set.
   */
  family: string;

  /**
   * A family that will override the display name while not overriding the actual family
   * Using this you could e.g. make 'Comic Sans MS' display as only 'Comic Sans'
   */
  displayFamily?: string;

  /**
   * The available font weights for the font family.
   */
  styles: FontStyleDefinition[];

  /**
   * The group the font should be sorted into, e.g. 'Serif'
   */
  classification: FontClassification;

  /**
   * Where the font comes from, e.g. 'Google'
   */
  source: FontSource;

  /**
   * Some fonts seem to be wider than their bounding box -- we'll handle those specially where necessary
   */
  previewScalingAdjust?: number;
  /**
   * The name for the font file
   */
  assetNames?: string[];
}

export interface FontFace {
  /**
   * The font family name of the font face.
   */
  family: string;
  /**
   * The font style of the font face.
   */
  style: FontStyle;
  /**
   * The font weight of the font face.
   */
  weight: number;
  /**
   * The source URL of the font face file.
   */
  src: string;
  /**
   * The Unicode range for which the font face provides glyphs.
   * It defines the characters supported by the font.
   */
  unicodeRange: string;
}

interface FontDefinitions {
  fonts: FontDefinition[];
}

const thin = new ThinStyle();
const thinItalic = new ThinItalicStyle();
const extraLight = new ExtraLightStyle();
const extraLightItalic = new ExtraLightItalicStyle();
const light = new LightStyle();
const lightItalic = new LightItalicStyle();
const regular = new RegularStyle();
const regularItalic = new RegularItalicStyle();
const medium = new MediumStyle();
const mediumItalic = new MediumItalicStyle();
const semiBold = new SemiBoldStyle();
const semiBoldItalic = new SemiBoldItalicStyle();
const bold = new BoldStyle();
const boldItalic = new BoldItalicStyle();
const extraBold = new ExtraBoldStyle();
const extraBoldItalic = new ExtraBoldItalicStyle();
const black = new BlackStyle();
const blackItalic = new BlackItalicStyle();

const localFonts: FontDefinition[] = [
  {
    family: FontFamilies.HELVETICA,
    displayFamily: 'Helvetica, Arial, sans serif',
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.LOCAL,
    source: FontSource.Local
  },
  {
    family: FontFamilies.TIMES_NEW_ROMAN,
    displayFamily: 'Times New Roman, serif',
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.LOCAL,
    source: FontSource.Local
  }
];

const sansSerifFonts: FontDefinition[] = [
  {
    family: FontFamilies.ATKINSON,
    styles: [regular, bold],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Server
  },
  {
    family: FontFamilies.INTER,
    styles: [light, regular, medium, semiBold],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.KARLA,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.KHAND,
    styles: [light, regular, medium, bold],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.KRUB,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.MONTSERRAT,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google,
    previewScalingAdjust: -0.03
  },
  {
    family: FontFamilies.MONTSERRAT_ALTERNATES,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google,
    previewScalingAdjust: -0.04
  },
  {
    family: FontFamilies.NOTO_SANS,
    styles: [regular, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.OPEN_SANS,
    styles: [light, regular, semiBold, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.OSWALD,
    styles: [light, regular, medium, bold],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.PT_SANS,
    styles: [regular, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.QUICKSAND,
    styles: [light, regular, medium, bold],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.RALEWAY,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.ROBOTO,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.ROSARIO,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.RUBIK,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.WORK_SANS,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SANS_SERIF,
    source: FontSource.Google
  }
];

const serifFonts: FontDefinition[] = [
  {
    family: FontFamilies.BASKERVILLE,
    styles: [regular, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.BITTER,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.CORMORANT,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.CRIMSON_PRO,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.GENTIUM_BOOK_BASIC,
    styles: [regular, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.NOTO_SERIF,
    styles: [regular, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.PLAYFAIR_DISPLAY,
    styles: [regular, medium, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.QUATTROCENTO,
    styles: [regular, bold],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.ROBOTO_SLAB,
    styles: [light, regular, medium, bold],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.VOLLKORN,
    styles: [regular, medium, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  },
  {
    family: FontFamilies.ZILLA_SLAB,
    styles: [light, regular, medium, bold, regularItalic],
    classification: FontClassification.SERIF,
    source: FontSource.Google
  }
];

/**
 * Filters out invalid fonts.
 *
 * @param source the font source
 */

const fonts = Object.freeze([...localFonts, ...sansSerifFonts, ...serifFonts]);

export default {
  fonts
} as FontDefinitions;

function getStyleMap(): Record<string, FontStyleDefinition> {
  return {
    [thin.weight]: thin,
    [extraLight.weight]: extraLight,
    [light.weight]: light,
    [regular.weight]: regular,
    [medium.weight]: medium,
    [semiBold.weight]: semiBold,
    [bold.weight]: bold,
    [extraBold.weight]: extraBold,
    [black.weight]: black
  };
}

function getItalicStyleMap(): Record<string, FontStyleDefinition> {
  return {
    [thinItalic.weight]: thinItalic,
    [extraLightItalic.weight]: extraLightItalic,
    [lightItalic.weight]: lightItalic,
    [regularItalic.weight]: regularItalic,
    [mediumItalic.weight]: mediumItalic,
    [semiBoldItalic.weight]: semiBoldItalic,
    [boldItalic.weight]: boldItalic,
    [extraBoldItalic.weight]: extraBoldItalic,
    [blackItalic.weight]: blackItalic
  };
}

export function getStyle(weight: string, fontStyle: FontStyle): FontStyleDefinition {
  let map: Record<string, FontStyleDefinition>;

  switch (fontStyle) {
    case FontStyle.Italic: {
      map = getItalicStyleMap();
      break;
    }

    default: {
      map = getStyleMap();
    }
  }

  const weightToUse = isWrappedCssVariable(weight) ? extractCssVariable(weight, '400') : weight;
  const mappedValue = map[weightToUse];

  if (!mappedValue) {
    switch (weightToUse) {
      case 'bold': {
        return new BoldStyle();
      }

      case 'normal': {
        return fontStyle === FontStyle.Italic ? new RegularItalicStyle() : new RegularStyle();
      }

      case 'lighter': {
        return new LightStyle();
      }
      case 'bolder': {
        return new ExtraBoldStyle();
      }

      default: {
        return new NullStyle();
      }
    }
  }

  return mappedValue;
}

export function getStyleByName(fontStyleName: FontStyleName) {
  const map: Record<FontStyleName, FontStyleDefinition> = {
    [FontStyleName.THIN]: thin,
    [FontStyleName.THINITALIC]: thinItalic,
    [FontStyleName.EXTRALIGHT]: extraLight,
    [FontStyleName.EXTRALIGHTITALIC]: extraLightItalic,
    [FontStyleName.LIGHT]: light,
    [FontStyleName.LIGHTITALIC]: lightItalic,
    [FontStyleName.MEDIUM]: medium,
    [FontStyleName.MEDIUMITALIC]: mediumItalic,
    [FontStyleName.REGULAR]: regular,
    [FontStyleName.ITALIC]: regularItalic,
    [FontStyleName.SEMIBOLD]: semiBold,
    [FontStyleName.SEMIBOLDITALIC]: semiBoldItalic,
    [FontStyleName.BOLD]: bold,
    [FontStyleName.BOLDITALIC]: boldItalic,
    [FontStyleName.EXTRABOLD]: extraBold,
    [FontStyleName.EXTRABOLDITALIC]: extraBoldItalic,
    [FontStyleName.BLACK]: black,
    [FontStyleName.BLACKITALIC]: blackItalic
  };

  return map[fontStyleName];
}
