import type { QueryResult } from '@apollo/client/react/types/types';
import type {
  UserManagementPropertiesQuery,
  UserManagementPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import useQueryWithTracing from '../useQueryWithTracing';
import useRegistrationStatus from './useRegistrationStatus';
import query from './UserManagementProperties.query.graphql';

/**
 * Hook to fetch the user management properties.
 *
 * @author Adam Ayres
 */
export default function useUserManagementProperties(
  ssr = true,
  skip: boolean | null = null
): QueryResult<UserManagementPropertiesQuery, UserManagementPropertiesQueryVariables> {
  const { isAnonymous } = useRegistrationStatus();
  return useQueryWithTracing<UserManagementPropertiesQuery, UserManagementPropertiesQueryVariables>(
    module,
    query,
    {
      skip: skip ?? isAnonymous,
      ssr
    }
  );
}
