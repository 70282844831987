import React from 'react';

export interface SwitchBranchContextInterface {
  /**
   * The id of the branch
   */
  branchName: string | undefined;
}

/**
 * Switch branch context provides the name of the branch that is switched to.
 *
 * @author Dolan Halbrook
 */
const SwitchBranchContext = React.createContext<SwitchBranchContextInterface>({
  branchName: undefined
});

export default SwitchBranchContext;
