import { canUseDOM } from 'exenv';
import type { Result } from 'web-vitals-reporter';
import { createApiReporter } from 'web-vitals-reporter';
import getPageContext from '../helpers/NextContextHelper';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import type { NextWebVitalsMetric } from 'next/app';

/**
 * Reports web vitals to lia backend
 * @param url
 * @param metric
 */
function onSendReportWebVitals(url: string, metric: Record<string, string>) {
  if (process.env.NEXT_PUBLIC_REPORT_WEB_VITALS === 'true') {
    const apiOriginRequest = {
      path: '/to/get/webVitals',
      pageName: metric.pageName ?? 'unknown',
      quiltId: 'null',
      fullUrl: metric.route,
      operations: [{ name: 'WebVitals' }]
    };

    const { authToken } = getPageContext();

    fetch(url, {
      method: 'POST',
      keepalive: true,
      headers: {
        [authToken?.headerName]: authToken?.token,
        'Content-Type': 'application/json',
        'kh-api-request-origin-info': JSON.stringify(apiOriginRequest),
        'kh-api-web-vitals': JSON.stringify(metric)
      },
      body: JSON.stringify({
        query: 'query WebVitals { webVitals }',
        operationName: 'WebVitals'
      })
    });
  }
}

// From web-vitals-reporter source
function round(value, precision = 0) {
  return +(Math.round(Number(`${value}e+${precision}`)) + `e-${precision}`);
}

// From web-vitals-reporter source map function plus route and pageName
function mapWebVitalMetric(metricToMap: Record<string, string>, result: Result) {
  const isWebVital = ['FCP', 'TTFB', 'LCP', 'CLS', 'FID'].includes(metricToMap.name);
  const metric = {
    [metricToMap.name]: isWebVital
      ? round(metricToMap.value, metricToMap.name === 'CLS' ? 4 : 0)
      : metricToMap.value
  };
  if (
    result.hasOwnProperty('route') &&
    result.hasOwnProperty('pageName') &&
    result.hasOwnProperty('source')
  ) {
    return metric;
  } else {
    const { route, source, pageName } = metricToMap;
    return {
      ...metric,
      route,
      source,
      pageName
    };
  }
}

let metricsReporter = null;
if (canUseDOM) {
  const { tenant } = getPageContext();
  metricsReporter = createApiReporter(
    UrlHelper.getGraphQlApiUrl(tenant).addQuery({ opname: 'WebVitals' }).build(),
    {
      onSend: onSendReportWebVitals,
      mapMetric: mapWebVitalMetric
    }
  );
}

function reportMetric(metric: NextWebVitalsMetric) {
  if (canUseDOM) {
    metricsReporter(metric);
  }
}

export default reportMetric;
