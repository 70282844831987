import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { FontStyleDefinition } from './types';
import { FontStyleName } from './types';

export default class LightStyle implements FontStyleDefinition {
  name = FontStyleName.LIGHT;

  style = FontStyle.Normal;

  weight = 300;
}
