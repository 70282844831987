export function validateHasNoRepeatingSequence(value: string, sequenceLength: number) {
  if (!value) {
    return false;
  }

  // Slice the value up into discrete substrings of length sequenceLength...
  const substrings = [];
  const loopLength = value.length - sequenceLength + 1;

  for (let i = 0; i < loopLength; i++) {
    const substring = value.slice(i, i + sequenceLength);

    // If all characters in the substring are the same, we can go ahead and dump out, as the entire value is already invalid
    const firstChar = substring.charAt(0);

    if ([...substring].every(char => char === firstChar)) {
      return false;
    }

    substrings.push(substring);
  }

  // Determine if multiple of the same substring exists
  return substrings.length === new Set(substrings).size;
}
