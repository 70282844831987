import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { getFaviconUrl } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';
import { createManifestRoute } from '@aurora/shared-utils/manifest/manifestRoute';
import Head from 'next/head';
import React, { useContext } from 'react';
import AppContext from '../context/AppContext/AppContext';
import AppTypeContext from '../context/AppTypeContext';
import SwitchBranchContext from '../context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '../context/TenantContext';
import ThemeContext from '../context/ThemeContext/ThemeContext';
import useCrossOriginAttribute from '../useCrossOriginAttribute';

interface Props {
  version: string;
}

/**
 * Displays Favicons
 *
 * @author Willi Hyde, Nico Pascual
 */
const Favicon: React.FC<React.PropsWithChildren<Props>> = ({ version }) => {
  const { theme } = useContext(ThemeContext);
  const tenant = useContext(TenantContext);
  const crossOrigin = useCrossOriginAttribute();
  const { themeInfo } = useContext(AppContext);
  const appType = useContext(AppTypeContext);
  const { branchName } = useContext(SwitchBranchContext);
  const { id: themeId, lastModified: themeLastModified = LastModifiedKeys.DEFAULTS_ONLY } =
    themeInfo;
  const faviconUrl = getFaviconUrl(theme, tenant, version, { height: 32, width: 32 }, branchName);
  const faviconUrlTouch = getFaviconUrl(
    theme,
    tenant,
    version,
    { height: 180, width: 180 },
    branchName
  );
  const manifestRoute = createManifestRoute(appType, tenant, { themeId, themeLastModified });
  return (
    <Head>
      <link rel="icon" href={faviconUrl} type="image/png" crossOrigin={crossOrigin(faviconUrl)} />
      <link
        rel="apple-touch-icon"
        href={faviconUrlTouch}
        type="image/png"
        crossOrigin={crossOrigin(faviconUrlTouch)}
      />
      <link rel="manifest" href={manifestRoute} crossOrigin={crossOrigin(manifestRoute)} />
    </Head>
  );
};

export default Favicon;
