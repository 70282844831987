import { getFromInternalApi } from '@aurora/shared-client/helpers/ApiHelper';
import type { NextRouter } from 'next/dist/shared/lib/router/router';
import { useCallback, useEffect, useRef } from 'react';
import { getLog } from '@aurora/shared-utils/log';

const log = getLog(module);

/**
 *  Redirections for client side routes based on the specified redirect rules.
 */
export default function useRuleRedirect(router: NextRouter) {
  const redirectMap = useRef(null);

  /**
   * Sets the redirect url if a redirection rule exists
   */
  const handleRedirect = useCallback(
    path => {
      if (redirectMap.current && redirectMap.current[path]) {
        const pathName = new URL(redirectMap.current[path].destUrl).pathname;
        router.push(pathName);
      }
    },
    [router, redirectMap]
  );

  /**
   * Fetches the redirection rule map
   */
  async function fetchRedirectRuleMap(): Promise<void> {
    if (redirectMap.current) {
      return;
    }
    const response = await getFromInternalApi('/api/redirect');
    if (response.ok) {
      redirectMap.current = await response.json();
    }
  }

  useEffect(() => {
    fetchRedirectRuleMap()
      .then(() => {
        router.events.on('routeChangeStart', handleRedirect);
        return null;
      })
      .catch(error => {
        log.error(error, 'Unable to fetch rules');
      });
    return () => {
      router.events.off('routeChangeStart', handleRedirect);
    };
  }, [router, handleRedirect]);
}
