import { createGlobalState } from 'react-hooks-global-state';

interface PageLoadingGlobalState {
  /**
   * Whether the page is currently loading.
   */
  pageLoading: boolean;
}

const { useGlobalState: usePageLoadingGlobalState } = createGlobalState<PageLoadingGlobalState>({
  pageLoading: false
});

export default usePageLoadingGlobalState;
