import React from 'react';

type IntlContext = {
  /**
   * The current locale
   */
  locale: string;
  /**
   * The preferences locales from browser
   */
  acceptLanguage: Intl.Locale[];
  /**
   * A callback to update the locale
   * @param locale the new locale
   */
  setLocale: (locale: string) => void;
  /**
   * A callback to update the timezone
   * @param timezone the new timezone
   */
  setTimezone: (timezone: string) => void;
  /**
   * List of allowed languages by the community
   */
  allowedLanguages: string[];
};

export type { IntlContext };

const IntlWrapperContext = React.createContext<IntlContext>(null);

export default IntlWrapperContext;
